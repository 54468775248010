/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';
import Select from 'react-select';
import _ from 'underscore';
// NOTE: Views

import { Paper, Button, Box, Divider, TextField, Grid } from '@material-ui/core';

import Alert from '../../../../shared/Alert';
import Post from './component/post';
import Gallery from './component/galleryMain';
import Job from './component/jobv';

import { insert, insertGallery, insertJob, getFiles } from '../service';

export default function TagIndex(props) {
  const [pageData, setPageData] = useState({
    name: '',
  });
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const types = [
    {
      value: 'content',
      label: 'Content',
    },
    {
      value: 'gallery',
      label: 'Gallery',
    },
    {
      value: 'job',
      label: 'Job Vacancy',
    },
  ];
  const [perms, setPerms] = useState({});

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-page')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-page')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-page')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-page')),
    };
    // Register the page metadata
    document.title = 'Create Page';
    await setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Create Page',
        hasMenu: false,
      })
    );

    await props
      .dispatch(getFiles())
      .then(data => setFiles(data))
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    switch (pageData.type) {
      case 'content':
        form.append('content', pageData.content);
        form.append('css', pageData.css);
        await props
          .dispatch(insert(form))
          .then(data => setNotification({ type: 'success', redirect: true, ...data }))
          .catch(error =>
            setNotification({ type: 'error', redirect: false, ...error.response.data })
          );
        break;
      case 'job':
        // form.append('content', pageData.content);
        form.append('content', pageData.content);
        form.append('css', pageData.css);
        await props
          .dispatch(insert(form))
          .then(data => setNotification({ type: 'success', redirect: true, ...data }))
          .catch(error =>
            setNotification({ type: 'error', redirect: false, ...error.response.data })
          );
        break;
      case 'gallery':
        await props
          .dispatch(insertGallery(form))
          .then(data => setNotification({ type: 'success', redirect: true, ...data }))
          .catch(error =>
            setNotification({ type: 'error', redirect: false, ...error.response.data })
          );
        break;

      default:
        break;
    }

    await setSubmitLoading(false);
  }

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'page', notification.redirect);
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.create) {
    return (
      <>
        <form autoComplete="off" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading}>
            <Paper>
              <Box px={5} pt={5}>
                <h4>PAGE INFORMATION</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Page Title"
                  defaultValue={pageData.title}
                  name="name"
                  required
                  onChange={e => setPageData({ ...pageData, name: e.target.value })}
                />

                <TextField type="hidden" name="parent" value={props.match.params.id ?? null} />

                <div className="mb-3">
                  <label for="content">Select the Type of content</label>
                  <Select
                    options={types}
                    name="type"
                    onChange={e => setPageData({ ...pageData, type: e.value })}
                  />
                </div>
                <br />
                {pageData.type === 'content' ? (
                  <div className="mb-3">
                    <Post
                      pageData={pageData}
                      files={files}
                      setPageData={setPageData}
                      submit={submitLoading}
                    />
                  </div>
                ) : null}
                {pageData.type === 'gallery' ? <Gallery submit={submitLoading} /> : null}
                {pageData.type === 'job' ? (
                  <div className="mb-3">
                    <Job
                      pageData={pageData}
                      files={files}
                      setPageData={setPageData}
                      submit={submitLoading}
                    />
                  </div>
                ) : null}
              </Box>
            </Paper>
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
