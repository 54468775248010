import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import _ from 'underscore';
export default function Alert(data, stop, props, destination, redirect = true) {
  const MySwal = withReactContent(Swal);
  function parseDataHTML(arr) {
    return (
      <>
        {_.map(arr, x => {
          for (let [key, value] of Object.entries(x.constraints)) {
            return <p>{value}</p>;
          }
        })}
      </>
    );
  }
  MySwal.fire({
    title: <p>{data.type.toUpperCase()}</p>,
    text: data.message,
    icon: data.type,
    confirmButtonColor: '#ea6e00',
    confirmButtonText: 'OK',
    html: data.status == 'Validation' ? parseDataHTML(data.data) : null,
    // footer: `Code : ${data.name}`,
    onOpen: () => {},
    onAfterClose: () => {
      if (props && redirect) {
        props.history.replace({
          pathname: `/dashboard/${destination || 'page'}`,
        });
      }
    },
  });
  return null;
}
