import HTTP from '../../../crud/HTTP';

export function index(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/page${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getCategories() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/getCategories`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getFeatured() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/getFeatured`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getmenubar() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/menubar_data`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getmenubarparsed() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/menubar_parsed`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getconfig() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/config`)
        .then(data => {
          console.log(data, 'isi config ya')
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getBanner() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/getBanners`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getNewsFlash() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/news_flash`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getPopup() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/popup`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION get Post by Slug
 * RETURN @Object
 ************************/
export function getPost(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/post/${slug}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION get Post by ID
 * RETURN @Object
 ************************/
export function getPostById(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/post/byId/${id}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION get Post Index
 * RETURN @Object
 ************************/
export function getPosts(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/post${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION get Page by SLUG
 * RETURN @Object
 ************************/
export function getPage(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/page/${slug}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function checkPage(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/utility/check_page`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getGalleries(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/page/gallery_index${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getJobs(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/page/job_index${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getEmployeeByTitle(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/employee/by_title${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getEmployeeByDivision(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/employee/by_division${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getEmployeeByDepartment(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/employee/by_department${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getEmployeeWithCo(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/employee/${query}/with_co`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function requestStat(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/utility/request_stationery`, query)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION EMpmployeeUpdate
 * RETURN @Object
 ************************/
export function EmpUpdate(id, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.put(`/employee/${id}`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function removeProfile(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.put(`/employee/${id}/remove_profile`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function applyJob(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/utility/job_apply`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function ISOSubmit(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/utility/iso_submit`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION EMpmployeeDetail
 * RETURN @Object
 ************************/
export function EmpDetail(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/employee/${data}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

// search action reducer

export const setFilesSearch = (payload) => {
  return {
      type: 'SETFILES',
      payload
  }
}

export const setKeyword = (payload) => {
  return {
    type: 'SETKEYWORD',
    payload
  }
}

// get files
export function getFiles(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/file?keyword=${query}`)
        .then(async data => {
          await dispatch(setKeyword(query))
          await dispatch(setFilesSearch(data.data))
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}
