import React, { useEffect, useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';

import { Link } from 'react-router-dom';
import _ from 'underscore';

export default function SelfRow({ data }) {
  return (
    <>
      <input
        type="text"
        className="no-vision"
        id={`url_${data.id}`}
        value={`${window.location.origin}/post/${data.slug}`}
      />
      <TableRow>
        <TableCell>{data.id}</TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/employee/${data.employee.id}`}>{data.employee.fullname}</Link>
        </TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/departement/${data.departement.id}`}>{data.departement.name}</Link>
        </TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/division/${data.division.id}`}>{data.division.name}</Link>
        </TableCell>
        <TableCell align="center">{moment(data.division.createdAt).format('llll')}</TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/requests/${data.id}`}>Detail</Link>
        </TableCell>
      </TableRow>
    </>
  );
}
