import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import SweetAlert from 'sweetalert2-react';

import { TextField, TextareaAutosize, Button } from '@material-ui/core';
import _ from 'underscore';
import { applyJob } from '../../service';

import { Helmet } from 'react-helmet';
import Alert from '../../../../shared/Alert';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noReason, setNoReason] = useState(false);
  const [notification, setNotification] = useState({});
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    setData(props.data);
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.data]);

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);
    if (form.get('reason')) {
      await props
        .dispatch(applyJob(form))
        .then(data => setNotification({ type: 'success', ...data }))
        .catch(error => setNotification({ type: 'error', ...error.response.data }));
    } else {
      setNoReason(true);
    }
  };

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  if (loading) {
    return null;
  }
  console.log(data);
  return (
    <>
      <Helmet>
        <style type="text/css">{data.css}</style>
      </Helmet>
      <div className="job_detail">
        {/* CONTENT */}
        <div className="wrapper">
          <div className="job_content">
            <h1 className="title">{data.name}</h1>
            <div className="backnav">
              <Link to="/jobs"> {'<'} BACK </Link>
            </div>
            <h5 className="date">{data.created_at}</h5>
            <div className="my-5" dangerouslySetInnerHTML={{ __html: data.content.content }} />

            {/* Prev/Next Button */}
            <div className="job_navigation">
              {data.previous_page ? (
                <Link to={`/${data.previous_page.slug}`} className="left">
                  <img alt="logo" src={toAbsoluteUrl('/media/nec/icons/arrow_left_b.png')} />
                  <p>Previous</p>
                </Link>
              ) : null}

              {data.next_page ? (
                <Link to={`/${data.next_page.slug}`} className="right">
                  <img alt="logo" src={toAbsoluteUrl('/media/nec/icons/arrow_right_b.png')} />
                  <p>Next</p>
                </Link>
              ) : null}
            </div>
          </div>
        </div>

        {props.userData.type == 'employee' ? (
          <div className="wrapper">
            <div className="job_content">
              <div className="employee_main">
                <div className="header">{data.name}</div>
                <form onSubmit={e => submit(e)}>
                  <div className="employee_form">
                    <div className="input_title">Applying for </div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={data.name}
                        className="input_box"
                      />
                    </div>
                    <TextField type="hidden" className="novision" name="job_id" value={data.id} />
                    <TextField
                      type="hidden"
                      className="novision"
                      name="job_title"
                      value={data.name}
                    />
                  </div>
                  <div className="employee_form">
                    <div className="input_title">Name</div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.fullname}
                        className="input_box"
                      />
                    </div>
                    <TextField
                      type="hidden"
                      className="novision"
                      name="employee"
                      value={props.userData.id}
                    />
                  </div>
                  <div className="employee_form ">
                    <div className="input_title">ID</div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.employee_number}
                        className="input_box"
                      />
                    </div>
                  </div>
                  <div className="header">Current Position</div>
                  <div className="employee_form novision">
                    <div className="input_title">Current Position</div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.title ? props.userData.title.title_name : ''}
                        className="input_box"
                      />
                    </div>
                    <TextField
                      type="hidden"
                      className="novision"
                      name="current_position"
                      value={props.userData.title ? props.userData.title.title_name : ''}
                    />
                  </div>
                  <div className="employee_form">
                    <div className="input_title">Title</div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.title ? props.userData.title.title_name : ''}
                        className="input_box"
                      />
                    </div>
                    <TextField
                      type="hidden"
                      className="novision"
                      name="title"
                      value={props.userData.title ? props.userData.title.id : ''}
                    />
                  </div>
                  <div className="employee_form">
                    <div className="input_title">Division</div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.division ? props.userData.division.name : ''}
                        className="input_box"
                      />
                    </div>
                    <TextField
                      type="hidden"
                      className="novision"
                      name="division"
                      value={props.userData.division ? props.userData.division.id : ''}
                    />
                  </div>

                  <div className="employee_form">
                    <div className="input_title">Department</div>
                    <div className="input_title">:</div>
                    <div className="input_div">
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.departement ? props.userData.departement.name : ''}
                        className="input_box"
                      />
                    </div>
                    <TextField
                      type="hidden"
                      className="novision"
                      name="departement"
                      value={props.userData.departement ? props.userData.departement.id : ''}
                    />
                  </div>

                  <div className="employee_form">
                    <div className="input_titleNO staytop">
                      Reason to Apply <span style={{ color: 'red' }}>*</span>{' '}
                    </div>
                    <div className="input_titleNO staytop">:</div>
                    <TextareaAutosize
                      rowsMin={4}
                      name="reason"
                      variant="outlined"
                      className="input_box"
                    />
                  </div>

                  <div className="employee_form">
                    <div />
                    <div />
                    <Button type="submit" className="saveButtonNO">
                      Apply
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <SweetAlert
              type="warning"
              show={noReason}
              title="Error"
              confirmButtonColor="#ea6e00"
              text="Please fill the required field."
              onConfirm={() => setNoReason(false)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
