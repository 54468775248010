import React, { useState, useEffect } from 'react';
import { getEmployeeByDepartment, getEmployeeByTitle, getEmployeeByDivision } from '../service';
import { Link } from 'react-router-dom';
import _ from 'underscore';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [groupBy, setGroupBy] = useState('title');
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(true);

  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    console.log(groupBy);

    switch (groupBy) {
      case 'title':
        await props
          .dispatch(getEmployeeByTitle(`?keyword=${keyword}`))
          .then(data => {
            setData(data);
          })
          .catch(error => console.log(error));
        break;
      case 'department':
        await props
          .dispatch(getEmployeeByDepartment(`?keyword=${keyword}`))
          .then(data => {
            setData(data);
          })
          .catch(error => console.log(error));
        break;
      case 'division':
        await props
          .dispatch(getEmployeeByDivision(`?keyword=${keyword}`))
          .then(data => {
            setData(data);
          })
          .catch(error => console.log(error));
        break;

      default:
        break;
    }
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [groupBy, keyword]);

  function changeGroup(group) {
    setData([]);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setGroupBy(group);
  }

  if (loading) {
    return null;
  }

  return (
    <div className="employee_list">
      <div className="employee_header">
        <input
          placeholder="search by name"
          type="text"
          className="by_name"
          onChange={e => setKeyword(e.target.value)}
        />
        <div
          className={`by_title ${groupBy == 'title' ? 'active' : null}`}
          onClick={() => changeGroup('title')}
        >
          BOARD OF EXECUTIVES
        </div>
        <div
          className={`by_division ${groupBy == 'division' ? 'active' : null}`}
          onClick={() => changeGroup('division')}
        >
          DIVISION
        </div>
        <div
          className={`by_department ${groupBy == 'department' ? 'active' : null}`}
          onClick={() => changeGroup('department')}
        >
          DEPARTMENT
        </div>
      </div>

      {!data.length ? (
        <h3 className="text-center text-muted">
          {' '}
          <i> Please Wait </i>
        </h3>
      ) : null}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {keyword.length > 0 ? (
        groupBy == 'title' ? (
          <div className="sections">
            <div className="employee_blocks">
              {data.length
                ? data.map((emp, key) => (
                    <Link to={`/employee/${emp.id}`} className="employee_item">
                      {/* <pre> {JSON.stringify(emp, null, 2)} </pre> */}
                      <div className="employee_profile">
                        <img src={emp.thumbnail_url} />
                      </div>
                      <div className={`employee_name first_title`}>{emp.fullname}</div>
                      <p className="attribute bold ">{emp.title ? emp.title.title_name : ''}</p>
                      <p className="attribute ">{emp.division ? emp.division.name : ''}</p>
                      <p className="attribute gray ">
                        {emp.departement ? emp.departement.name : ''}
                      </p>
                    </Link>
                  ))
                : null}
            </div>
            <div className="employee_blocks">
              {data.length
                ? data.map((emp, key) =>
                    emp.title.order_number > 1 && emp.title.order_number < 5 ? (
                      <Link to={`/employee/${emp.id}`} className="employee_item">
                        {/* <pre> {JSON.stringify(emp, null, 2)} </pre> */}
                        <div className="employee_profile">
                          <img src={emp.thumbnail_url} />
                        </div>
                        <div className={`employee_name first_title`}>{emp.fullname}</div>
                        <p className="attribute bold ">{emp.title ? emp.title.title_name : ''}</p>
                        <p className="attribute ">{emp.division ? emp.division.name : ''}</p>
                        <p className="attribute gray ">
                          {emp.departement ? emp.departement.name : ''}
                        </p>
                      </Link>
                    ) : null
                  )
                : null}
            </div>
          </div>
        ) : (
          data.map((item, key) =>
            item.employee.length ? (
              <div className="sections">
                <div
                  className={`section_title ${key == 0 ? 'first' : key == 1 ? 'second' : 'third'}`}
                >
                  {' '}
                  <span>{item.name}</span>
                </div>
                {item.employee
                  ? _.map(item.employee, (emp, k) => (
                      <div className="employee_blocks">
                        {_.map(emp, tmp =>
                          tmp ? (
                            <Link to={`/employee/${tmp.id}`} className="employee_item">
                              <div className="employee_profile">
                                <img src={tmp.thumbnail_url} />
                              </div>
                              <div
                                className={`employee_name ${
                                  key == 0
                                    ? 'first_title'
                                    : key == 1
                                    ? 'second_title'
                                    : 'third_title'
                                }`}
                              >
                                {tmp.fullname}
                              </div>
                              <p className="attribute bold ">
                                {tmp.title ? tmp.title.title_name : ''}
                              </p>
                              <p className="attribute ">{tmp.division ? tmp.division.name : ''}</p>
                              <p className="attribute gray ">
                                {tmp.departement ? tmp.departement.name : ''}
                              </p>
                            </Link>
                          ) : null
                        )}
                      </div>
                    ))
                  : null}
              </div>
            ) : null
          )
        )
      ) : groupBy == 'title' ? (
        <div className="sections">
          <div className="employee_blocks">
            {data.length
              ? data.map((emp, key) =>
                  key == 0 ? (
                    <Link to={`/employee/${emp.id}`} className="employee_item">
                      {/* <pre> {JSON.stringify(emp, null, 2)} </pre> */}
                      <div className="employee_profile">
                        <img src={emp.thumbnail_url} />
                      </div>
                      <div className={`employee_name first_title`}>{emp.fullname}</div>
                      <p className="attribute bold ">{emp.title ? emp.title.title_name : ''}</p>
                      <p className="attribute ">{emp.division ? emp.division.name : ''}</p>
                      <p className="attribute gray ">
                        {emp.departement ? emp.departement.name : ''}
                      </p>
                    </Link>
                  ) : null
                )
              : null}
          </div>
          <div className="employee_blocks">
            {data.length
              ? data.map((emp, key) =>
                  emp.title.order_number > 1 && emp.title.order_number < 5 ? (
                    <Link to={`/employee/${emp.id}`} className="employee_item">
                      {/* <pre> {JSON.stringify(emp, null, 2)} </pre> */}
                      <div className="employee_profile">
                        <img src={emp.thumbnail_url} />
                      </div>
                      <div className={`employee_name first_title`}>{emp.fullname}</div>
                      <p className="attribute bold ">{emp.title ? emp.title.title_name : ''}</p>
                      <p className="attribute ">{emp.division ? emp.division.name : ''}</p>
                      <p className="attribute gray ">
                        {emp.departement ? emp.departement.name : ''}
                      </p>
                    </Link>
                  ) : null
                )
              : null}
          </div>
        </div>
      ) : (
        data.map((item, key) =>
          item.employee.length ? (
            <div className="sections">
              <div
                className={`section_title ${key == 0 ? 'first' : key == 1 ? 'second' : 'third'}`}
              >
                {' '}
                <span>{item.name}</span>
              </div>
              {item.employee
                ? _.map(item.employee, (emp, k) => (
                    <div className="employee_blocks">
                      {_.map(emp, tmp =>
                        tmp ? (
                          <Link to={`/employee/${tmp.id}`} className="employee_item">
                            <div className="employee_profile">
                              <img src={tmp.thumbnail_url} />
                            </div>
                            <div
                              className={`employee_name ${
                                key == 0 ? 'first_title' : key == 1 ? 'second_title' : 'third_title'
                              }`}
                            >
                              {tmp.fullname}
                            </div>
                            <p className="attribute bold ">
                              {tmp.title ? tmp.title.title_name : ''}
                            </p>
                            <p className="attribute ">{tmp.division ? tmp.division.name : ''}</p>
                            <p className="attribute gray ">
                              {tmp.departement ? tmp.departement.name : ''}
                            </p>
                          </Link>
                        ) : null
                      )}
                    </div>
                  ))
                : null}
            </div>
          ) : null
        )
      )}
    </div>
  );
}
