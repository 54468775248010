import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { getPost } from '../service';

import { Helmet } from 'react-helmet';
export default function Page(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [featureLoading, setFeaturedLoading] = useState(false);

  async function initializeData() {
    await setFeaturedLoading(true);
    await props
      .dispatch(getPost(props.match.params.slug))
      .then(data => setData(data))
      .catch(error => console.log(error));
    await setFeaturedLoading(false);
  }
  useEffect(() => {
    async function deploy() {
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.match.params]);

  if (loading) {
    return null;
  }

  return (
    <div className="page">
      <Helmet>
        <style type="text/css">{data.css}</style>
      </Helmet>
      {data ? (
        <div className="page-content">
          {/* CONTENT */}
          <div className="post-content">
            <h1 className="title">{data.name}</h1>

            <div className="backnav">
              <Link to="/post"> {'<'} BACK </Link>
            </div>
            <h5 className="date">{data.created_at}</h5>
            <img className="thumbnail" src={data.thumbnail_url} />
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>

          {/* Prev/Next Button */}
          <div className="post-navigation">
            {data.previous_page ? (
              <Link
                to={`/post/${data.previous_page.slug}`}
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                }
                className="left"
              >
                <img alt="logo" src={toAbsoluteUrl('/media/nec/icons/left.png')} />
                <p>Previous</p>
              </Link>
            ) : null}

            {data.next_page ? (
              <Link
                to={`/post/${data.next_page.slug}`}
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                }
                className="right"
              >
                <img alt="logo" src={toAbsoluteUrl('/media/nec/icons/right.png')} />
                <p>Next</p>
              </Link>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="no_post">No Post Found</div>
      )}
    </div>
  );
}
