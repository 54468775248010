import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import 'react-quill/dist/quill.snow.css'; // ES6

// NOTE: Views
import ReactQuill from 'react-quill'; // ES6
import { Paper, Button, Box, Divider, TextField, Grid, Switch } from '@material-ui/core';

export default function Post() {
  const [thumbnail, setThumbnail] = useState(null);

  return (
    <Grid item container xs={3} direction="column" justify="center">
      <Grid item className="mb-3">
        {thumbnail ? (
          <img src={thumbnail} className="thumbnailGallery d-block img-thumbnail" />
        ) : (
          <img
            src={
              'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-stock-vector-no-image-available-icon-flat-vector.jpg?ver=6'
            }
            className="thumbnailGallery d-block img-thumbnail"
          />
        )}
      </Grid>
      <Grid item className="mb-3">
        <TextField type="text" className="w-100 mb-3" name="altText" variant="outlined" />
        <input
          type="file"
          name="images"
          className=" w-100 btn btn-info"
          onChange={x => {
            if (x.target.files) {
              setThumbnail(URL.createObjectURL(x.target.files[0]));
            } else {
              setThumbnail(null);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
