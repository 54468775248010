/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import Select from 'react-select';
import { Box, Paper, Divider, Button, TextField, Grid } from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import {
  getTitle,
  getDivision,
  detail,
  update,
  getDepartement,
  getEmployee,
  deactivate,
  activate,
  destroy,
  removeProfile,
} from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [pageData, setPageData] = useState({
    fullname: '',
    blocks: [],
  });
  const [title, setTitle] = useState([]);
  const [division, setDivision] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [departement, setDepartement] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-employee')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-employee')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-employee')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-employee')),
    };
    // Register the page metadata
    document.title = 'Edit Employee';
    setLoading(true);
    await props
      .dispatch(detail(props.match.params.id))
      .then(async data => {
        await setPageData({
          ...data,
          empSuper: _.pluck(data.superOrdinateDetails, 'value'),
          empSub: _.pluck(data.subOrdinateDetails, 'value'),
        });

        await props.dispatch(
          auth.actions.registerPage({
            title: 'Edit Employee',
            deleteLink: permData.delete ? () => destroyDispatch(data.id) : null,
            activateLink: permData.update ? () => activateDispatch(data.id) : null,
            deactivateLink: permData.update ? () => deactivateDispatch(data.id) : null,
            hasMenu: permData.update || permData.delete ? true : false,
          })
        );
      })
      .catch(error => setError(error));

    await props
      .dispatch(getDivision())
      .then(data => setDivision(data))
      .catch(error => setError(error));

    await props
      .dispatch(getEmployee())
      .then(data => setEmployee(data))
      .catch(error => setError(error));

    await props
      .dispatch(getDepartement())
      .then(data => setDepartement(data))
      .catch(error => setError(error));

    await props
      .dispatch(getTitle())
      .then(data => setTitle(data))
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);
    form.set('empSuper', pageData.empSuper || []);
    form.set('empSub', pageData.empSub || []);
    await setSubmitLoading(true);
    await props
      .dispatch(update(pageData.id, form))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  };

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  var removeProfileOnClick = async e => {
    e.preventDefault();

    await props
      .dispatch(removeProfile(pageData.id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  };

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'employee');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.read) {
    // Final Display
    return (
      <>
        <form autoComplete="off" encType="multipart/form-data" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New Employee</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Employee number"
                  defaultValue={pageData.employee_number}
                  name="employee_number"
                  type="number"
                  required
                  onChange={e => setPageData({ ...pageData, employee_number: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Fullname"
                  defaultValue={pageData.fullname}
                  name="fullname"
                  required
                  onChange={e => setPageData({ ...pageData, fullname: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Display name"
                  defaultValue={pageData.display_name}
                  name="display_name"
                  required
                  onChange={e => setPageData({ ...pageData, display_name: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="email"
                  defaultValue={pageData.email}
                  name="email"
                  required
                  onChange={e => setPageData({ ...pageData, email: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="phone number"
                  defaultValue={pageData.phone_number}
                  name="phone_number"
                  // required
                  onChange={e => setPageData({ ...pageData, phone_number: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Password"
                  type="password"
                  defaultValue=""
                  name="password"
                  onChange={e => setPageData({ ...pageData, password: e.target.value })}
                />
                <div className="mb-3">
                  <label for="titile">Select title</label>
                  <Select
                    options={title}
                    name="title"
                    defaultValue={{
                      value: pageData.title ? pageData.title.id : null,
                      label: pageData.title
                        ? _.findWhere(title, { id: pageData.title.id }).title_name
                        : null,
                    }}
                    onChange={e =>
                      setPageData({
                        ...pageData,
                        title: { title_name: e.label, id: e.value },
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label for="division">Select Division</label>
                  <Select
                    options={division}
                    name="division"
                    defaultValue={{
                      value: pageData.division ? pageData.division.id : null,
                      label: pageData.division
                        ? _.findWhere(division, { id: pageData.division.id }).name
                        : null,
                    }}
                    onChange={e =>
                      setPageData({
                        ...pageData,
                        division: { name: e.label, id: e.value },
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label for="division">Select Departement</label>
                  <Select
                    options={departement}
                    name="departement"
                    defaultValue={{
                      value: pageData.departement ? pageData.departement.id : null,
                      label: pageData.departement
                        ? _.findWhere(departement, { id: pageData.departement.id }).name
                        : null,
                    }}
                    onChange={e =>
                      setPageData({
                        ...pageData,
                        departement: { name: e.label, id: e.value },
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label for="titile">Select Superordinate</label>
                  <Select
                    options={employee}
                    name="empSuper"
                    isMulti
                    defaultValue={pageData.superOrdinateDetails}
                    onChange={e => {
                      setPageData({
                        ...pageData,
                        empSuper: _.pluck(e, 'value'),
                      });
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="titile">Select Subordinate</label>
                  <Select
                    options={employee}
                    name="empSub"
                    isMulti
                    defaultValue={pageData.subOrdinateDetails}
                    onChange={e => {
                      setPageData({
                        ...pageData,
                        empSub: _.pluck(e, 'value'),
                      });
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Linkedln"
                  defaultValue={pageData.linkedln}
                  name="linkedln"
                  onChange={e => setPageData({ ...pageData, linkedln: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Facebook"
                  defaultValue={pageData.facebook}
                  name="facebook"
                  onChange={e => setPageData({ ...pageData, facebook: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Instagram"
                  defaultValue={pageData.instagram}
                  name="instagram"
                  onChange={e => setPageData({ ...pageData, instagram: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Point"
                  type="number"
                  defaultValue={pageData.point}
                  name="point"
                  required
                  onInvalid={e => e.target.setCustomValidity('Please Input Employee Point.')}
                  onInput={e => e.target.setCustomValidity('')}
                  onChange={e => setPageData({ ...pageData, point: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Office address"
                  defaultValue={pageData.office_address}
                  name="office_address"
                  onChange={e => setPageData({ ...pageData, office_address: e.target.value })}
                />
                <Grid container spacing={1} className="px-auto w-100" justify="center">
                  <Grid item xs={4}>
                    <label for="titile">Profile Picture</label>
                    {thumbnail || pageData.thumbnail ? (
                      <img
                        src={thumbnail || pageData.thumbnail}
                        className="w-100 img-thumbnail m-1 d-block mx-auto"
                      />
                    ) : null}
                    {pageData.thumbnail ? (
                      <a
                        className="text-center text-muted d-block mx-auto"
                        href="#"
                        onClick={removeProfileOnClick}
                      >
                        <i>remove profile picture</i>
                      </a>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} justify="center">
                    <input
                      type="file"
                      name="thumbnail"
                      className="w-25 text-center d-block mx-auto btn btn-info"
                      onChange={x => {
                        if (x.target.files) {
                          setThumbnail(URL.createObjectURL(x.target.files[0]));
                        } else {
                          setThumbnail(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                {perms.update ? (
                  <Button
                    type="submit"
                    className="ml-auto d-block my-3"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Submit
                  </Button>
                ) : null}
                <small>
                  {' '}
                  <i className="text-muted">* required</i>{' '}
                </small>
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
