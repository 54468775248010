import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormControl, Input, InputLabel, FormHelperText, Button } from '@material-ui/core';
import { ArrowBack, Send } from '@material-ui/icons';
import { forgotPasswordEmployee as forgotPassword } from './services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toAbsoluteUrl } from '../../../_metronic';

function ForgotPassword(props) {
  const [email, setEmail] = useState(null);
  const [data, setData] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  async function sendReset(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setLoading(true);
    await setData(null);
    await setError(null);
    await props
      .dispatch(forgotPassword(form))
      .then(data => setData(data))
      .catch(error => setError(error.response.data));
    await setLoading(false);
  }
  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver"></div>

            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title"></h3>
                <h4 className="kt-login__subtitle"></h4>
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info"></div>
            </div>
          </div>

          <div
            className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/kanan.jpg')})`,
              backgroundSize: 'contain',
            }}
          >
            <div className="kt-login__body loginform">
              <div className="kt-login__form ">
                <Box mt={5}>
                  <h3>Forgot Password</h3>
                  {loading ? (
                    <CircularProgress
                      className="position-absolute"
                      style={{
                        top: '50%',
                        right: '50%',
                      }}
                      color="secondary"
                    />
                  ) : null}
                  {data ? (
                    <div class="alert alert-success" role="alert">
                      {data.message}
                    </div>
                  ) : null}
                  {error ? (
                    <div class="alert alert-danger" role="alert">
                      {error.message}
                    </div>
                  ) : null}
                  <form onSubmit={e => sendReset(e)}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="my-input">Email address</InputLabel>
                      <Input
                        id="my-input"
                        type="email"
                        name="email"
                        aria-describedby="my-helper-text"
                        value={email}
                        disabled={loading}
                        onChange={e => setEmail(e.target.value)}
                      />
                      <FormHelperText id="my-helper-text">
                        We'll never share your email.
                      </FormHelperText>
                    </FormControl>

                    <div className="mt-3">
                      <Button
                        type="button"
                        component={Link}
                        to="/auth/employee"
                        size="medium"
                        className="my-2 mr-2"
                        variant="outlined"
                        disabled={loading}
                        startIcon={<ArrowBack />}
                      >
                        Back to Login
                      </Button>
                      <Button
                        type="submit"
                        size="medium"
                        className="my-2 mr-2"
                        variant="outlined"
                        disabled={loading}
                        startIcon={<Send />}
                      >
                        Request Password Reset
                      </Button>
                    </div>
                  </form>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null)(ForgotPassword);
