import HTTP from '../../../../crud/HTTP';

export function index(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/role${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function insert(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post('/role', data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getMenuPerms() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get('/utility/getMenus')
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function update(id, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.put(`/role/${id}`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function detail(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/role/${data}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function destroy(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/role/${data}`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function deactivate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/role/${data}/deactivate`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function activate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/role/${data}/activate`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function setFeatured(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/role/set_featured`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}
