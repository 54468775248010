/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { getJobs } from '../service';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 4,
    keyword: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [featureLoading, setFeaturedLoading] = useState(false);

  /************************
   * SECTION Data get
   * RETURN @Object
   ************************/
  async function initializeData() {
    var query = `?page=${meta.page}&per_page=${meta.per_page}&keyword=${meta.keyword}`;
    await props
      .dispatch(getJobs(query))
      .then(data => {
        setData(data.data);
        setTotalPage(data.meta.total_page);
      })
      .catch(error => console.log(error));
  }

  /************************
   * SECTION Initial Loading
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, []);

  /************************
   * SECTION Filter Loading
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await initializeData();
      await window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    deploy();
  }, [meta]);

  function pagination(limit) {
    var items = [];
    for (let index = 1; index <= limit; index++) {
      items.push(
        <span
          onClick={() => setMeta({ ...meta, page: index })}
          className={`pagination_item ${index == meta.page ? 'active' : ''}`}
        >
          {index}
        </span>
      );
    }
    return items;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="job_page">
      <div className="job_header_page">
        <h1 className="job_title">INTERNAL JOB POSTING</h1>
        <p className="job_subtitle">
          Welcome to our internal job posting, please download the file below to meet our
          requirement, Goodluck!
        </p>
      </div>
      <hr />
      <p className="job_file">
        Policy of Employee Job Transfer <Link className="job_file_link">Download</Link>
      </p>
      <p className="job_file">
        NEC-I Job Posting & Internal Transfer Guidelines-E{' '}
        <Link className="job_file_link">Download</Link>
      </p>
      <hr />
      <div className="job_header">Jobs Available</div>
      <div className="job_block">
        {data.map(e => (
          <div className="block_item">
            <div className="block_title">{e.name}</div>
            <p className="block_excerpt">{e.content ? e.content.excerpt : ''}</p>
            <Link to={`${e.slug}`} className="block_link">
              VIEW POST
            </Link>
          </div>
        ))}
      </div>
      <div className="job_pagination">
        {meta.page > 1 ? (
          <span
            onClick={() => setMeta({ ...meta, page: meta.page - 1 })}
            className={`pagination_item`}
          >
            {'<'}
          </span>
        ) : null}
        {pagination(totalPage)}
        {totalPage > meta.page ? (
          <span
            onClick={() => setMeta({ ...meta, page: meta.page + 1 })}
            className={`pagination_item`}
          >
            {'>'}
          </span>
        ) : null}
      </div>
    </div>
  );
}
