export default {
  header: {
    self: {},
    items: [
      {
        title: 'Dashboardery',
        root: true,
        alignment: 'left',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
      },
      {
        title: 'Material UI',
        root: true,
        alignment: 'left',
        toggle: 'click',
        submenu: [
          {
            title: 'Layout',
            bullet: 'dot',
            submenu: [
              {
                title: 'Box',
                bullet: 'line',
                page: 'google-material/layout/box',
              },
              {
                title: 'Container',
                bullet: 'line',
                page: 'google-material/layout/container',
              },
              {
                title: 'Grid',
                bullet: 'line',
                page: 'google-material/layout/grid',
              },
              {
                title: 'Grid list',
                bullet: 'line',
                page: 'google-material/layout/grid-list',
              },
              {
                title: 'Hidden',
                bullet: 'line',
                page: 'google-material/layout/hidden',
              },
            ],
          },
          {
            title: 'Inputs',
            bullet: 'dot',
            submenu: [
              {
                title: 'Autocomplete',
                page: 'google-material/inputs/autocomplete',
                bullet: 'line',
                permission: 'accessToECommerceModule',
              },
              {
                title: 'Buttons',
                page: 'google-material/inputs/buttons',
                bullet: 'line',
              },
              {
                title: 'Checkboxes',
                page: 'google-material/inputs/checkboxes',
                bullet: 'line',
              },
              {
                title: 'Pickers',
                page: 'google-material/inputs/pickers',
                bullet: 'line',
              },
              {
                title: 'Radio Buttons',
                page: 'google-material/inputs/radio-buttons',
                bullet: 'line',
              },
              {
                title: 'Selects',
                page: 'google-material/inputs/selects',
                bullet: 'line',
              },
              {
                title: 'Switches',
                page: 'google-material/inputs/switches',
                bullet: 'line',
              },
              {
                title: 'Text Fields',
                page: 'google-material/inputs/text-fields',
                bullet: 'line',
              },
              {
                title: 'Transfer List',
                page: 'google-material/inputs/transfer-list',
                bullet: 'line',
              },
            ],
          },
          {
            title: 'Navigation',
            bullet: 'dot',
            submenu: [
              {
                title: 'Bottom Navigation',
                bullet: 'line',
                page: 'google-material/navigation/bottom-navigation',
              },
              {
                title: 'Breadcrumbs',
                bullet: 'line',
                page: 'google-material/navigation/breadcrumbs',
              },
              {
                title: 'Drawers',
                bullet: 'line',
                page: 'google-material/navigation/drawer',
              },
              {
                title: 'Links',
                bullet: 'line',
                page: 'google-material/navigation/links',
              },
              {
                title: 'Menus',
                bullet: 'line',
                page: 'google-material/navigation/menus',
              },
              {
                title: 'Steppers',
                bullet: 'line',
                page: 'google-material/navigation/steppers',
              },
              {
                title: 'Tabs',
                bullet: 'line',
                page: 'google-material/navigation/tabs',
              },
            ],
          },
          {
            title: 'Surfaces',
            bullet: 'dot',
            submenu: [
              {
                title: 'App Bar',
                bullet: 'line',
                page: 'google-material/surfaces/app-bar',
              },
              {
                title: 'Paper',
                bullet: 'line',
                page: 'google-material/surfaces/paper',
              },
              {
                title: 'Cards',
                bullet: 'line',
                page: 'google-material/surfaces/cards',
              },
              {
                title: 'Expansion Panels',
                bullet: 'line',
                page: 'google-material/surfaces/expansion-panels',
              },
            ],
          },
          {
            title: 'Feedback',
            bullet: 'dot',
            submenu: [
              {
                title: 'Progress',
                bullet: 'line',
                page: 'google-material/feedback/progress',
              },
              {
                title: 'Dialogs',
                bullet: 'line',
                page: 'google-material/feedback/dialogs',
              },
              {
                title: 'Snackbars',
                bullet: 'line',
                page: 'google-material/feedback/snackbars',
              },
            ],
          },
          {
            title: 'Data Display',
            bullet: 'dot',
            submenu: [
              {
                title: 'Avatars',
                bullet: 'line',
                page: 'google-material/data-displays/avatars',
              },
              {
                title: 'Badges',
                bullet: 'line',
                page: 'google-material/data-displays/badges',
              },
              {
                title: 'Chips',
                bullet: 'line',
                page: 'google-material/data-displays/chips',
              },
              {
                title: 'Dividers',
                bullet: 'line',
                page: 'google-material/data-displays/dividers',
              },
              {
                title: 'Icons',
                bullet: 'line',
                page: 'google-material/data-displays/icons',
              },
              {
                title: 'Lists',
                bullet: 'line',
                page: 'google-material/data-displays/lists',
              },
              {
                title: 'Tables',
                bullet: 'line',
                page: 'google-material/data-displays/tables',
              },
              {
                title: 'Tooltips',
                bullet: 'line',
                page: 'google-material/data-displays/tooltips',
              },
              {
                title: 'Typography',
                bullet: 'line',
                page: 'google-material/data-displays/typography',
              },
            ],
          },
          {
            title: 'Utils',
            bullet: 'dot',
            submenu: [
              {
                title: 'Click Away Listener',
                bullet: 'line',
                page: 'google-material/utils/click-away-listener',
              },
              {
                title: 'Modal',
                bullet: 'line',
                page: 'google-material/utils/modal',
              },
              {
                title: 'No SSR',
                bullet: 'line',
                page: 'google-material/utils/no-ssr',
              },
              {
                title: 'Popover',
                bullet: 'line',
                page: 'google-material/utils/popover',
              },
              {
                title: 'Popper',
                bullet: 'line',
                page: 'google-material/utils/popper',
              },
              {
                title: 'Portal',
                bullet: 'line',
                page: 'google-material/utils/portal',
              },
              {
                title: 'Transitions',
                bullet: 'line',
                page: 'google-material/utils/transitions',
              },
              {
                title: 'useMediaQuery',
                bullet: 'line',
                page: 'google-material/utils/use-media-query',
              },
            ],
          },
        ],
      },
      {
        title: 'Bootstrap',
        root: true,
        alignment: 'left',
        toggle: 'click',
        submenu: {
          type: 'mega',
          width: '800px',
          alignment: 'left',
          columns: [
            {
              items: [
                {
                  title: 'Alerts',
                  page: 'react-bootstrap/alert',
                },
                {
                  title: 'Accordion',
                  page: 'react-bootstrap/accordion',
                },
                {
                  title: 'Badge',
                  page: 'react-bootstrap/badge',
                },
                {
                  title: 'Breadcrumb',
                  page: 'react-bootstrap/breadcrumb',
                },
                {
                  title: 'Buttons',
                  page: 'react-bootstrap/buttons',
                },
                {
                  title: 'Button Group',
                  page: 'react-bootstrap/button-group',
                },
                {
                  title: 'Cards',
                  page: 'react-bootstrap/cards',
                },
              ],
            },
            {
              items: [
                {
                  title: 'Carousel',
                  page: 'react-bootstrap/carousel',
                  bullet: 'line',
                },
                {
                  title: 'Dropdowns',
                  page: 'react-bootstrap/dropdowns',
                  bullet: 'line',
                },
                {
                  title: 'Forms',
                  page: 'react-bootstrap/forms',
                  bullet: 'line',
                },
                {
                  title: 'Input Group',
                  page: 'react-bootstrap/input-group',
                  bullet: 'line',
                },
                {
                  title: 'Images',
                  page: 'react-bootstrap/images',
                  bullet: 'line',
                },
                {
                  title: 'Figures',
                  page: 'react-bootstrap/figures',
                  bullet: 'line',
                },
                {
                  title: 'Jumbotron',
                  page: 'react-bootstrap/jumbotron',
                  bullet: 'line',
                },
              ],
            },
            {
              items: [
                {
                  title: 'List group',
                  page: 'react-bootstrap/list-group',
                  bullet: 'dot',
                },
                {
                  title: 'Modal',
                  page: 'react-bootstrap/modal',
                  bullet: 'dot',
                },
                {
                  title: 'Navs',
                  page: 'react-bootstrap/navs',
                  bullet: 'dot',
                },
                {
                  title: 'Navbar',
                  page: 'react-bootstrap/navbar',
                  bullet: 'dot',
                },
                {
                  title: 'Overlays',
                  page: 'react-bootstrap/overlays',
                  bullet: 'dot',
                },
                {
                  title: 'Pagination',
                  page: 'react-bootstrap/pagination',
                  bullet: 'dot',
                },
                {
                  title: 'Popovers',
                  page: 'react-bootstrap/popovers',
                  bullet: 'dot',
                },
              ],
            },
            {
              items: [
                {
                  title: 'Progress',
                  page: 'react-bootstrap/progress',
                },
                {
                  title: 'Spinners',
                  page: 'react-bootstrap/spinners',
                },
                {
                  title: 'Table',
                  page: 'react-bootstrap/table',
                },
                {
                  title: 'Tabs',
                  page: 'react-bootstrap/tabs',
                },
                {
                  title: 'Tooltips',
                  page: 'react-bootstrap/tooltips',
                },
                {
                  title: 'Toasts',
                  page: 'react-bootstrap/toasts',
                },
              ],
            },
          ],
        },
      },
      // {
      //   title: "Components",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   submenu: [
      //     {
      //       title: "React Bootstrap",
      //       bullet: "dot",
      //       icon: "flaticon-web",
      //       submenu: [
      //
      //
      //       ]
      //     }
      //   ]
      // },
      {
        title: 'Custom',
        root: true,
        alignment: 'left',
        toggle: 'click',
        submenu: [
          {
            title: 'Layout Builder',
            icon: 'flaticon2-expand',
            page: 'builder',
          },
          {
            title: 'Error Pages',
            bullet: 'dot',
            icon: 'flaticon2-warning',
            submenu: [
              {
                title: 'Error 1',
                page: 'error/error-v1',
              },
              {
                title: 'Error 2',
                page: 'error/error-v2',
              },
              {
                title: 'Error 3',
                page: 'error/error-v3',
              },
              {
                title: 'Error 4',
                page: 'error/error-v4',
              },
              {
                title: 'Error 5',
                page: 'error/error-v5',
              },
              {
                title: 'Error 6',
                page: 'error/error-v6',
              },
            ],
          },
        ],
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'Dashboard',
        // root: true,
        icon: 'flaticon2-browser',
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
        bullet: 'dot',
      },

      {
        title: 'User Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        submenu: [
          {
            title: 'Admin',
            desc: 'Some my description goes here',
            page: 'dashboard/user',
            icon: 'flaticon2-files-and-folders',
            perm: 'user',
          },
          {
            title: 'Role',
            desc: 'Some my description goes here',
            page: 'dashboard/role',
            icon: 'flaticon2-files-and-folders',
            // perm: 'role',
          },
        ],
      },

      {
        title: 'Homepage Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/homepage',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Menubar Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/menubar',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Banner Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/banner',
        bullet: 'dot',
        // perm: "ads"
      },
      // {
      //   title: "Profile",
      //   root: true,
      //   icon: "flaticon2-user",
      //   page: "dashboard/profile",
      //   bullet: "dot"
      //   // perm: "ads"
      // },
      {
        title: 'Post Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        submenu: [
          {
            title: 'Post Management',
            desc: 'Some my description goes here',
            page: 'dashboard/post',
            icon: 'flaticon2-files-and-folders',
            // perm: "post"
          },
          {
            title: 'Post Category Management',
            desc: 'Some my description goes here',
            page: 'dashboard/category',
            icon: 'flaticon2-files-and-folders',
            // perm: "category"
          },
        ],
      },
      {
        title: 'Page Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/page',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Employee',
        root: true,
        icon: 'flaticon2-files-and-folders',
        submenu: [
          {
            title: 'Employee Management',
            desc: 'Some my description goes here',
            page: 'dashboard/employee',
            icon: 'flaticon2-files-and-folders',
            // perm: "post"
          },
          {
            title: 'Title Management',
            desc: 'Some my description goes here',
            page: 'dashboard/title',
            icon: 'flaticon2-files-and-folders',
            // perm: "post"
          },
          {
            title: 'Division Management',
            desc: 'Some my description goes here',
            page: 'dashboard/division',
            icon: 'flaticon2-files-and-folders',
            // perm: "category"
          },
          {
            title: 'Departement Management',
            desc: 'Some my description goes here',
            page: 'dashboard/departement',
            icon: 'flaticon2-files-and-folders',
            // perm: "category"
          },
        ],
      },

      {
        title: 'Popup Management',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/popup',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Flash News',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/flash',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Media & Files',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/file',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Site Configuration',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/configuration',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Activity Trail',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/activity_trail',
        bullet: 'dot',
        // perm: "ads"
      },
      {
        title: 'Debug Log',
        root: true,
        icon: 'flaticon2-files-and-folders',
        page: 'dashboard/debug_log',
        bullet: 'dot',
        // perm: "ads"
      },
      // {
      //   title: 'Stationary Requests',
      //   root: true,
      //   icon: 'flaticon2-files-and-folders',
      //   page: 'dashboard/requests',
      //   bullet: 'dot',
      //   // perm: "ads"
      // },
      // {
      //   title: 'Job Applications',
      //   root: true,
      //   icon: 'flaticon2-image-file',
      //   page: 'dashboard/job_application',
      //   bullet: 'dot',
      //   // perm: "ads"
      // },
    ],
  },
};
