import { connect } from 'react-redux';
import Page from './page';

var mapStateToProps = store => {
  return {
    data: store.auth.user,
  };
};

export default connect(mapStateToProps)(Page);
