import React, { useState } from 'react';
import DropdownMenu, { NestedDropdownMenu } from 'react-dd-menu';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowDropDown } from '@material-ui/icons';
import DDC from './ddChild';
export default function DDM({ item, align }) {
  const [open, setOpen] = useState(false);

  function toggle(event) {
    event.preventDefault();
    setOpen(!open);
  }

  function close() {
    setOpen(false);
  }

  function click() {
    console.log('You clicked an item');
  }

  var opts = {
    isOpen: open,
    close: close,
    toggle: (
      <>
        {item.url.includes('http') ? (
          <NavItem
            className={`m-auto ${window.location.pathname == item.url ? 'active_mb' : null}`}
          >
            <NavLink
              href={item.url}
              target={item.outbound ? '_blank' : '_self'}
              onClick={item.children.length ? toggle : null}
            >
              {item.name}
              {item.children.length ? <ArrowDropDown /> : null}
            </NavLink>
          </NavItem>
        ) : (
          <NavItem
            className={`m-auto ${window.location.pathname == item.url ? 'active_mb' : null}`}
          >
            <NavLink
              tag={Link}
              to={item.url}
              target={item.outbound ? '_blank' : '_self'}
              onClick={item.children.length ? toggle : null}
            >
              {item.name}
              {item.children.length ? <ArrowDropDown /> : null}
            </NavLink>
          </NavItem>
        )}
      </>
    ),
    align: align,
    textAlign: 'left',
  };

  return (
    <DropdownMenu className="mx-auto" {...opts}>
      {item.children ? item.children.map(menu => <DDC item={menu} align={align} />) : null}
    </DropdownMenu>
  );
}
