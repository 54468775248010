import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserByToken, getEmployeeByToken } from '../../crud/auth.crud';
import * as routerHelpers from '../../router/RouterHelpers';

export const actionTypes = {
  Login: '[Login] Action',
  LoginEmp: '[Login Employee] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  RegisterEmp: '[RegisterEmployee] Action',
  UserRequested: '[Request User] Action',
  EmployeeRequested: '[Request Employee] Action',
  UserLoaded: '[Load User] Auth API',
  EmployeeLoaded: '[Load Employee] Auth API',
  PageRegistered: '[Page Register] Action',
  HitCourier: '[Hit List] Action',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  isAuthenticated: false,
  role: null,
  perms: null,
  authUser: null,
  pageData: null,
  hitList: [],
};

export const reducer = persistReducer(
  { storage, key: 'demo1-auth', whitelist: ['user', 'authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        console.log('actiondata', action);

        window.localStorage.setItem('auth_token', action.payload.authToken.token);
        window.sessionStorage.setItem('user_type', 'user');
        return {
          ...state,
          authToken: action.payload.authToken.token,
        };
      }

      case actionTypes.LoginEmp: {
        console.log(action, 'di action')
        window.localStorage.setItem('auth_token', action.payload.authToken.token);
        window.sessionStorage.setItem('user_type', 'employee');
        return {
          ...state,
          authToken: action.payload.authToken.token,
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.RegisterEmp: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        document.cookie = 'popup_read' + '=' + 'false' + ';' + ';path=/';
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const user = action.payload.user.data;
        console.log('test', user);
        return { ...state, user };
      }

      case actionTypes.EmployeeLoaded: {
        const user = action.payload.user.data;
        console.log('test', user);
        return { ...state, user };
      }

      case actionTypes.PageRegistered: {
        const data = action.payload;

        return {
          ...state,
          pageData: data,
        };
      }

      case actionTypes.HitCourier: {
        const data = action.payload;

        return {
          ...state,
          hitList: data,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  loginEmp: authToken => ({ type: actionTypes.LoginEmp, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  registerEmp: authToken => ({
    type: actionTypes.RegisterEmp,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  requestEmployee: user => ({ type: actionTypes.EmployeeRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillEmployee: user => ({ type: actionTypes.EmployeeLoaded, payload: { user } }),
  registerPage: user => ({ type: actionTypes.PageRegistered, payload: user }),
  hitCouriers: user => ({ type: actionTypes.HitCourier, payload: user }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.LoginEmp, function* loginEmp() {
    yield put(actions.requestEmployee());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.RegisterEmp, function* registerEmp() {
    yield put(actions.requestEmployee());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.EmployeeRequested, function* EmployeeRequested() {
    const { data: user } = yield getEmployeeByToken();

    yield put(actions.fulfillEmployee(user));
  });
}
