import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import SweetAlert from 'sweetalert2-react';

import { TextField, TextareaAutosize, Button, Grid } from '@material-ui/core';
import _ from 'underscore';
import { ISOSubmit } from '../../service';

import Alert from '../../../../shared/Alert';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noReason, setNoReason] = useState(false);
  const [notification, setNotification] = useState({});
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    setData(props.data);
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.data]);

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);
    if (form.get('message') && form.get('subject')) {
      await props
        .dispatch(ISOSubmit(form))
        .then(data => setNotification({ type: 'success', ...data }))
        .catch(error => setNotification({ type: 'error', ...error.response.data }));
    } else {
      setNoReason(true);
    }
  };

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="job_detail">
        {props.userData.type == 'employee' ? (
          <div className="wrapper">
            <div className="job_content">
              <div className="employee_main">
                <div className="header">ISO Center</div>
                <form onSubmit={e => submit(e)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className="text-left mt-3 d-block">Your name (required)</label>
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.fullname}
                        className="input_box"
                      />
                      <TextField
                        required
                        className="novision"
                        name="employee"
                        value={props.userData.id}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className="text-left mt-3 d-block">Your Division (required)</label>
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        required
                        value={props.userData.division ? props.userData.division.name : ''}
                        className="input_box"
                      />
                      <TextField
                        required
                        className="novision"
                        name="division"
                        value={props.userData.division ? props.userData.division.id : null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className="text-left mt-3 d-block">Your Email (required)</label>
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        value={props.userData.email}
                        className="input_box"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className="text-left mt-3 d-block">Subject</label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="subject"
                        className="input_box"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <label className="text-left mt-3 d-block">Message</label>
                      <TextareaAutosize
                        rowsMin={4}
                        fullWidth
                        name="message"
                        variant="outlined"
                        className="input_box w-100"
                      />
                    </Grid>
                  </Grid>

                  <div className="employee_form">
                    <div />
                    <div />
                    <Button type="submit" className="saveButtonNO">
                      Apply
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <SweetAlert
              type="warning"
              show={noReason}
              title="Error"
              confirmButtonColor="#ea6e00"
              text="Please fill the required field."
              onConfirm={() => setNoReason(false)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
