/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { LastLocationProvider } from 'react-router-last-location';
import { Routes } from './app/router/Routes';
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from './_metronic';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login, getEmployeeByToken, getUserByToken } from './app/crud/auth.crud';
import * as auth from './app/store/ducks/auth.duck';
// crud/auth.crud
import { connect } from 'react-redux';

var mapStateToProps = store => {
  return {
    userData: store.auth.user,
  };
};

function App({ store, persistor, basename, userData, dispatch }) {
  // getUserByToken().then(data => {
  // console.log('testuserdata', userData);
  // });
  console.log(userData);
  useEffect(() => {
    if (userData) {
      getUserByToken().then(e => {
        var ndata = {
          token: window.localStorage.getItem('auth_token'),
          email: e.data.data.email,
          fullname: e.data.data.fullname,
          perms: e.data.data.perms,
          pic: null,
          role: e.data.data.role,
          type: e.data.data.type,
        };
        console.log('yeet', ndata);
        // store.dispatch(auth.actions.login(ndata));
      });
    }
  });
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                  <ToastContainer />
                </I18nProvider>
              </ThemeProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default connect(mapStateToProps)(App);
