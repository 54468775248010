import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { TableCell, TableRow, Checkbox, Chip, IconButton } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import _ from 'underscore';

export default function SelfRow({ data, selected, setSelected }) {
  var statusProc = active => {
    if (!active) {
      return <Chip label="Active" className="bg-info text-white" />;
    } else {
      return <Chip label="Inactive" className="bg-warning" />;
    }
  };

  function copy() {
    var copyText = document.getElementById(`url_${data.id}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    toast.success(`Link copied to clipboard : ${data.link}`);
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Checkbox
            value={data.id}
            checked={_.contains(selected, data.id)}
            onChange={async e => {
              if (!e.target.checked) {
                var finder = await _.without(selected, data.id);
                await setSelected(finder);
              } else {
                await setSelected([...selected, data.id]);
              }
            }}
          />
        </TableCell>
        <TableCell>{data.id}</TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/file/${data.id}`}>{data.name}</Link>
        </TableCell>

        <TableCell align="center">
          {data.type}/{data.extension}
        </TableCell>

        <TableCell align="center" onClick={copy} style={{ cursor: 'pointer' }}>
          <input
            type="text"
            className="no-vision"
            style={{ cursor: 'pointer' }}
            id={`url_${data.id}`}
            value={data.link}
          />
          <FileCopy style={{ cursor: 'pointer' }} />
        </TableCell>
        <TableCell align="center">
          {data.type == 'image' ? (
            <img src={data.link} style={{ width: '150px' }} className="d-block mx-auto" />
          ) : (
            <img
              src={`${process.env.REACT_APP_ORIGIN}image/asset/file.png`}
              className="d-block mx-auto"
              style={{ width: '70px' }}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
