import React, { useState, useEffect } from 'react';
import { EmpDetail, EmpUpdate, removeProfile } from '../service';
import { Box, Paper, Divider, Button, TextField, Grid } from '@material-ui/core';
import _ from 'underscore';

import Alert from '../../../shared/Alert';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    await props
      .dispatch(EmpDetail(props.data.id))
      .then(item => setData(item))
      .catch(error => console.log(error));
  }

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);

    await props
      .dispatch(EmpUpdate(props.data.id, form))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  };

  var removeProfileOnClick = async e => {
    e.preventDefault();

    await setThumbnail(null);
    await props
      .dispatch(removeProfile(data.id))
      .then(data => {
        setNotification({ type: 'success', ...data });
        initializeData();
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  };

  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  if (loading) {
    return null;
  }

  if (props.data.type != 'employee') {
    return null;
  }

  return (
    <form className="employee_profile" onSubmit={e => submit(e)}>
      <div className="employee_main">
        <div className="header">EDIT PROFILE</div>
        <Grid container spacing={1} className="px-auto w-100" justify="center">
          <Grid item xs={4}>
            {thumbnail || data.thumbnail ? (
              <img
                src={thumbnail || data.thumbnail}
                style={{ width: '250px' }}
                className="img-thumbnail m-1 d-block mx-auto"
              />
            ) : null}

            {data.thumbnail || thumbnail ? (
              <a
                className="text-center text-muted d-block mx-auto"
                href="#"
                onClick={removeProfileOnClick}
              >
                <i>remove profile picture</i>
              </a>
            ) : null}
          </Grid>
          <Grid item xs={12} justify="center">
            <input
              type="file"
              name="thumbnail"
              className="w-25 text-center h-100 d-block mx-auto btn"
              onChange={x => {
                if (x.target.files[0]) {
                  setThumbnail(URL.createObjectURL(x.target.files[0]));
                } else {
                  setThumbnail(null);
                }
              }}
            />
          </Grid>
        </Grid>
        <div className="employee_form">
          <div className="input_title">Employee Number</div>
          <div className="input_title">:</div>
          <div className="input_div">
            <TextField
              variant="outlined"
              disabled
              fullWidth
              value={data.employee_number}
              className="input_box"
            />
            {/* <small className="helper_text ml-2">
              <i>Employee Number cannot be changed</i>
            </small> */}
          </div>
          <TextField
            type="hidden"
            className="novision"
            name="employee_number"
            value={data.employee_number}
          />
        </div>
        <div className="employee_form">
          <div className="input_title">Email</div>
          <div className="input_title">:</div>
          <div className="input_div">
            <TextField
              variant="outlined"
              fullWidth
              disabled
              value={data.email}
              className="input_box"
            />

            {/* <small className="helper_text ml-2">
              <i>Email cannot be changed</i>
            </small> */}
          </div>
          <TextField type="hidden" className="novision" name="email" value={data.email} />
        </div>
        <div className="employee_form">
          <div className="input_title">Title</div>
          <div className="input_title">:</div>
          <div className="input_div">
            <TextField
              variant="outlined"
              disabled
              fullWidth
              value={data.title ? data.title.title_name : ''}
              className="input_box"
            />
            {/* <small className="helper_text ml-2">
              <i>Title cannot be changed</i>
            </small> */}
          </div>
          <TextField
            type="hidden"
            className="novision"
            name="title"
            value={data.title ? data.title.id : ''}
          />
        </div>
        <div className="employee_form">
          <div className="input_title">Division</div>
          <div className="input_title">:</div>
          <div className="input_div">
            <TextField
              variant="outlined"
              disabled
              fullWidth
              value={data.division ? data.division.name : ''}
              className="input_box"
            />

            {/* <small className="helper_text ml-2">
              <i>Division cannot be changed</i>
            </small> */}
          </div>
          <TextField
            type="hidden"
            className="novision"
            name="division"
            value={data.division ? data.division.id : ''}
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Department</div>
          <div className="input_title">:</div>
          <div className="input_div">
            <TextField
              variant="outlined"
              disabled
              fullWidth
              value={data.departement ? data.departement.name : ''}
              className="input_box"
            />

            {/* <small className="helper_text ml-2">
              <i>Department cannot be changed</i>
            </small> */}
          </div>
          <TextField
            type="hidden"
            className="novision"
            name="departement"
            value={data.departement ? data.departement.id : ''}
          />
        </div>
        <div className="employee_form">
          <div className="input_title">Points</div>
          <div className="input_title">:</div>
          <div className="input_div">
            <TextField
              variant="outlined"
              fullWidth
              disabled
              value={data.point}
              className="input_box"
            />

            {/* <small className="helper_text ml-2">
              <i>Point cannot be changed</i>
            </small> */}
          </div>
          <TextField type="hidden" className="novision" name="point" value={data.point} />
        </div>
        <div className="employee_form">
          <div className="input_title">
            Fullname{' '}
            <small>
              {' '}
              <i className="text-muted">*</i>{' '}
            </small>
          </div>
          <div className="input_title">:</div>
          <TextField
            required
            name="fullname"
            defaultValue={data.fullname}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">
            Display Name{' '}
            <small>
              <i className="text-muted">*</i>
            </small>
          </div>
          <div className="input_title">:</div>
          <TextField
            required
            name="display_name"
            defaultValue={data.display_name}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Phone number</div>
          <div className="input_title">:</div>
          <TextField
            name="phone_number"
            defaultValue={data.phone_number}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Password</div>
          <div className="input_title">:</div>
          <TextField name="password" variant="outlined" className="input_box" />
        </div>

        <div className="employee_form">
          <div className="input_title">LinkedIn</div>
          <div className="input_title">:</div>
          <TextField
            name="linkedln"
            defaultValue={data.linkedln}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Facebook</div>
          <div className="input_title">:</div>
          <TextField
            name="facebook"
            defaultValue={data.facebook}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Instagram</div>
          <div className="input_title">:</div>
          <TextField
            name="instagram"
            defaultValue={data.instagram}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Office Address</div>
          <div className="input_title">:</div>
          <TextField
            name="office_address"
            defaultValue={data.office_address}
            variant="outlined"
            className="input_box"
          />
        </div>

        <div className="employee_form">
          <div className="input_title">Office Phone Number</div>
          <div className="input_title">:</div>
          <TextField
            name="office_phone"
            defaultValue={data.office_phone}
            variant="outlined"
            className="input_box"
          />
        </div>
        <div className="employee_form">
          <div />
          <div />

          <Button type="submit" className="saveButtonNO">
            SAVE
          </Button>
        </div>

        <small>
          {' '}
          <i className="text-muted">* required</i>{' '}
        </small>
      </div>
    </form>
  );
}
