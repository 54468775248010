import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';
import Select from 'react-select';
import _ from 'underscore';
// NOTE: Views

import { Paper, Button, Box, Divider, TextField, Grid } from '@material-ui/core';

import Alert from '../../../../shared/Alert';
import Post from './component/post';
import Gallery from './component/galleryMain';
import Job from './component/jobv';
import {
  detail,
  update,
  updateJob,
  updateGallery,
  deactivate,
  activate,
  destroy,
  getFiles,
} from '../service';

export default function TagIndex(props) {
  const [pageData, setPageData] = useState({
    name: '',
  });
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [removedGallery, setRemovedGallery] = useState([]);

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const types = [
    {
      value: 'content',
      label: 'Content',
    },
    {
      value: 'gallery',
      label: 'Gallery',
    },
    {
      value: 'job',
      label: 'Job Vacancy',
    },
  ];
  const [perms, setPerms] = useState({});

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-page')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-page')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-page')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-page')),
    };
    document.title = 'Create Page';
    await setLoading(true);

    await props
      .dispatch(detail(props.match.params.slug))
      .then(async data => {
        await setPageData(data);
        console.log(data);
        await props.dispatch(
          auth.actions.registerPage({
            title: 'Create Page',
            deleteLink: permData.delete ? () => destroyDispatch(data.id) : null,
            activateLink: permData.update ? () => activateDispatch(data.id) : null,
            deactivateLink: permData.update ? () => deactivateDispatch(data.id) : null,
            hasMenu: permData.update || permData.delete ? true : false,
          })
        );
      })
      .catch(error => setError(error));
    await props
      .dispatch(getFiles())
      .then(data => setFiles(data))
      .catch(error => setError(error));
    await setPerms(permData);
    await setLoading(false);
  }

  async function submit(e) {
    console.log('disini cok')
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    switch (pageData.type) {
      case 'content':
        form.append('content', pageData.content.content);
        form.append('css', pageData.content.css);
        await props
          .dispatch(update(pageData.id, form))
          .then(data => setNotification({ type: 'success', redirect: true, ...data }))
          .catch(error =>
            setNotification({ type: 'error', redirect: false, ...error.response.data })
          );
        break;
      case 'job':
        form.append('content', pageData.content.content);
        form.append('css', pageData.content.css);
        // form.append('content', pageData.content.content);
        await props
          .dispatch(update(pageData.id, form))
          .then(data => setNotification({ type: 'success', redirect: true, ...data }))
          .catch(error =>
            setNotification({ type: 'error', redirect: false, ...error.response.data })
          );
        break;
      case 'gallery':
        form.append('content', pageData.content.content);
        form.append('removed', removedGallery.join(','));
        await props
          .dispatch(updateGallery(pageData.id, form))
          .then(data => setNotification({ type: 'success', redirect: true, ...data }))
          .catch(error =>
            setNotification({ type: 'error', redirect: false, ...error.response.data })
          );
        break;
    }
    await setSubmitLoading(false);
  }

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'page', notification.redirect);
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.read) {
    return (
      <>
        <form autoComplete="off" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper>
              <Box p={5}>
                <h4>PAGE INFORMATION</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Page Title"
                  defaultValue={pageData.name}
                  name="name"
                  required
                  onChange={e => setPageData({ ...pageData, name: e.target.value })}
                />

                <div className="mb-3">
                  <TextField type="hidden" name="type" value={pageData.type} />
                  <TextField
                    variant="outlined"
                    className="my-3"
                    fullWidth
                    label="Template"
                    name="temp"
                    value={_.findWhere(types, { value: pageData.type }).label}
                  />
                </div>
                {pageData.type == 'content' ? (
                  <div className="mb-3">
                    <Post
                      pageData={pageData}
                      setPageData={setPageData}
                      files={files}
                      submit={submitLoading}
                      perms={perms}
                    />
                  </div>
                ) : null}
                {pageData.type == 'gallery' ? (
                  <Gallery
                    selfContent={pageData.content.images}
                    removedGallery={removedGallery}
                    setRemovedGallery={setRemovedGallery}
                    submit={submitLoading}
                    perms={perms}
                  />
                ) : null}

                {pageData.type == 'job' ? (
                  <Job
                    pageData={pageData}
                    setPageData={setPageData}
                    files={files}
                    submit={submitLoading}
                    perms={perms}
                  />
                ) : null}
              </Box>
            </Paper>
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
