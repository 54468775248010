/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { getJobs } from '../service';

export default function Page(props) {
console.log(props, 'di document an ya?')
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 4,
    keyword: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [featureLoading, setFeaturedLoading] = useState(false);
  const filteredData = useSelector((state) => state.search.files)
  const filteredKeyword = useSelector((state) => state.search.keyword)
  /************************
   * SECTION Data get
   * RETURN @Object
   ************************/
  async function initializeData() {
    var query = `?page=${meta.page}&per_page=${meta.per_page}&keyword=${meta.keyword}`;
    await props
      .dispatch(getJobs(query))
      .then(data => {
        setData(data.data);
        setTotalPage(data.meta.total_page);
      })
      .catch(error => console.log(error));
  }

  /************************
   * SECTION Initial Loading
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, []);

  /************************
   * SECTION Filter Loading
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await initializeData();
      await window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    deploy();
  }, [meta]);

  function pagination(limit) {
    var items = [];
    for (let index = 1; index <= limit; index++) {
      items.push(
        <span
          onClick={() => setMeta({ ...meta, page: index })}
          className={`pagination_item ${index == meta.page ? 'active' : ''}`}
        >
          {index}
        </span>
      );
    }
    return items;
  }

  if (loading) {
    return null;
  }

  return (
    <div className='bg-white mt-3 p-3 rounded-lg'>
        {
          filteredKeyword && filteredData.data.data.length > 0 ?
          filteredData.data.data.map(item => (
              <p onClick={()=> window.open(item.link, "_blank")}>{item.name}</p>
          ))
          :
          <p className="text-center">Document not found</p>
        }
    </div>
  );
}
