import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import 'react-quill/dist/quill.snow.css'; // ES6

// NOTE: Views
import { TextField, Grid } from '@material-ui/core';

export default function Post({ data, total, setTotal, removedGallery, setRemovedGallery }) {
  const [thumbnail, setThumbnail] = useState(data.thumbnail_url);
  const [curData, setCurData] = useState(data);

  useEffect(() => {
    setCurData(data);
    setThumbnail(data.thumbnail_url);
  }, [total, data]);

  return (
    <Grid item container xs={3} direction="column" justify="center">
      {/* <pre>{JSON.stringify(data)}</pre> */}
      <Grid item className="mb-3">
        {thumbnail ? (
          <img src={thumbnail} className="thumbnailGallery d-block img-thumbnail" />
        ) : (
          <img
            src={
              'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-stock-vector-no-image-available-icon-flat-vector.jpg?ver=6'
            }
            className="thumbnailGallery d-block img-thumbnail"
          />
        )}
      </Grid>
      <Grid item className="mb-3">
        <TextField
          type="text"
          className="w-100 mb-3"
          name={`altText_${curData.id}`}
          variant="outlined"
          value={curData.altText}
          onChange={e => {
            var newTotal = _.filter(total, item => item.id != data.id);
            setCurData({ ...curData, altText: e.target.value });
            setTotal(
              _.sortBy(
                [
                  ...newTotal,
                  {
                    ...curData,
                    altText: e.target.value,
                  },
                ],
                'ordering'
              )
            );
          }}
        />
        <input
          type="file"
          name={`images_${curData.id}`}
          className=" w-100 btn btn-info"
          onChange={x => {
            if (x.target.files) {
              var tb = URL.createObjectURL(x.target.files[0]);
              setThumbnail(tb);
              var newTotal = _.filter(total, item => item.id != data.id);
              setTotal(
                _.sortBy(
                  [
                    ...newTotal,
                    {
                      ...curData,
                      thumbnail_url: tb,
                    },
                  ],
                  'ordering'
                )
              );
            } else {
              setThumbnail(null);
            }
          }}
        />
        <button
          onClick={e => {
            e.preventDefault();
            setRemovedGallery([...removedGallery, data.id]);
            var newTotal = _.sortBy(
              _.filter(total, item => item.id != data.id),
              'ordering'
            );
            // console.log(newTotal);
            setTotal(newTotal);
          }}
          className=" w-100 btn btn-danger btn-sm mt-3"
        >
          {' '}
          Remove{' '}
        </button>
      </Grid>
    </Grid>
  );
}
