import React from "react";
import TextField from "@material-ui/core/TextField";

export default function text({
  label,
  value,
  error,
  disabled,
  pass,
  req,
  name
}) {
  return (
    <TextField
      type={pass ? "password" : "text"}
      variant="outlined"
      className="my-3"
      fullWidth
      label={label}
      defaultValue={value}
      error={error}
      disabled={disabled}
      name={name}
      required={req}
    />
  );
}
