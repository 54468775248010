/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import { Box, Paper, Divider, Button, TextField, Grid } from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import { update, detail, destroy, activate, deactivate } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-popup')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-popup')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-popup')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-popup')),
    };
    // Register the page metadata
    document.title = 'Edit Popup';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Edit Popup',
        hasMenu: false,
      })
    );

    await props
      .dispatch(detail(props.match.params.id))
      .then(async data => {
        await setData(data);
        await props.dispatch(
          auth.actions.registerPage({
            title: 'Edit Popup',
            deleteLink: permData.delete ? () => destroyDispatch(data.id) : null,
            activateLink: permData.update ? () => activateDispatch(data.id) : null,
            deactivateLink: permData.update ? () => deactivateDispatch(data.id) : null,
            hasMenu: permData.update || permData.delete ? true : false,
          })
        );
      })
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    await props
      .dispatch(update(data.id, form))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'popup');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.read) {
    // Final Display
    return (
      <>
        <form autoComplete="off" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>Edit Banner</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Division name"
                  defaultValue={data.name}
                  name="name"
                  required
                  onChange={e => setData({ ...data, name: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Link"
                  defaultValue={data.link}
                  name="link"
                  required
                  onChange={e => setData({ ...data, link: e.target.value })}
                />
                <Grid container spacing={1} className="px-auto w-100" justify="center">
                  <Grid item xs={12}>
                    {thumbnail || data.thumbnail ? (
                      <img
                        src={thumbnail || data.thumbnail}
                        className="w-25 img-thumbnail m-1 d-block mx-auto"
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      type="file"
                      name="thumbnail"
                      className="w-100 h-100 btn btn-info"
                      onChange={x => {
                        if (x.target.files.length) {
                          setThumbnail(URL.createObjectURL(x.target.files[0]));
                        } else {
                          setThumbnail(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                {perms.update ? (
                  <Button
                    type="submit"
                    className="ml-auto d-block my-3"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Submit
                  </Button>
                ) : null}
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
