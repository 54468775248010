/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import Select from '@appgeist/react-select-material-ui';

import { Box, Paper, Divider, Button, TextField, Grid } from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import { detail, update, getRoles, deactivate, activate, destroy } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [pageData, setPageData] = useState({
    fullname: '',
    blocks: [],
  });
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-user')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-user')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-user')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-user')),
    };

    document.title = 'Edit User';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Edit User',
        hasMenu: false,
      })
    );

    await props
      .dispatch(detail(props.match.params.id))
      .then(async data => {
        await setPageData(data);
        await props.dispatch(
          auth.actions.registerPage({
            title: 'Edit User',
            deleteLink: permData.delete ? () => destroyDispatch(data.id) : null,
            activateLink: permData.update ? () => activateDispatch(data.id) : null,
            deactivateLink: permData.update ? () => deactivateDispatch(data.id) : null,
            hasMenu: permData.update || permData.create ? true : false,
          })
        );
      })
      .catch(error => setError(error));
    await props.dispatch(getRoles()).then(item => setRoles(item));
    await setPerms(permData);
  }

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);
    form.append('role', pageData.role);
    await setSubmitLoading(true);
    await props
      .dispatch(update(props.match.params.id, form))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  };

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'user');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  // Final Display
  if (perms.read) {
    return (
      <>
        <form autoComplete="off" encType="multipart/form-data" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New Employee</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Username"
                  defaultValue={pageData.userName}
                  name="userName"
                  required
                  onChange={e => setPageData({ ...pageData, userName: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="First name"
                  defaultValue={pageData.firstName}
                  name="firstName"
                  required
                  onChange={e => setPageData({ ...pageData, firstName: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Last name"
                  defaultValue={pageData.lastName}
                  name="lastName"
                  required
                  onChange={e => setPageData({ ...pageData, lastName: e.target.value })}
                />

                <Select
                  label="Role"
                  options={roles}
                  defaultValue={{
                    value: pageData.role,
                    label: pageData.role
                      ? _.find(roles, item => item.value == pageData.role).label
                      : '',
                  }}
                  onChange={e => setPageData({ ...data, role: e.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="email"
                  defaultValue={pageData.email}
                  name="email"
                  required
                  onChange={e => setPageData({ ...pageData, email: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="phone number"
                  defaultValue={pageData.phone}
                  name="phone"
                  required
                  onChange={e => setPageData({ ...pageData, phone: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Password"
                  type="password"
                  defaultValue=""
                  name="password"
                  onChange={e => setPageData({ ...pageData, password: e.target.value })}
                />
                {perms.update ? (
                  <Button
                    type="submit"
                    className="ml-auto d-block my-3"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Submit
                  </Button>
                ) : null}
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
