/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';
import { QuickActions } from './components/QuickActions';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import Divider from '@material-ui/core/Divider';

import { FilterList, Add, ArrowBack } from '@material-ui/icons';

import Alerter from '../../../app/pages/shared/AlertOnUpdate';

import { Button, Menu, MenuItem, IconButton } from '@material-ui/core';

function SubHeader({
  subheaderCssClasses,
  subheaderContainerCssClasses,
  subheaderMobileToggle,
  pageData,
  hitList,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="kt-subheader__main">
          {subheaderMobileToggle && (
            <button
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          <LayoutContextConsumer>
            {({ subheader: { title } }) => (
              <>
                <h3 className="kt-subheader__title">{pageData ? pageData.title : 'Untitled'}</h3>
              </>
            )}
          </LayoutContextConsumer>
          {pageData ? (
            pageData.newLink ? (
              !pageData.newLinkExternal ? (
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={pageData.newLink}
                  startIcon={<Add />}
                >
                  Add
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  href={pageData.newLink}
                  startIcon={<Add />}
                >
                  Add
                </Button>
              )
            ) : null
          ) : null}
        </div>
        {pageData ? (
          <>
            <div className="kt-subheader__toolbar">
              <div className="kt-subheader__wrapper">
                {pageData.backLink ? (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<ArrowBack />}
                      component={Link}
                      to={pageData.backLink}
                    >
                      Back
                    </Button>

                    <span className="kt-subheader__separator kt-subheader__separator--v" />
                  </>
                ) : null}
                {pageData.hasMenu ? (
                  <>
                    <Button
                      className="ml-3"
                      variant="outlined"
                      color="primary"
                      endIcon={<FilterList />}
                      onClick={handleClick}
                    >
                      Actions
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {pageData.editLink ? (
                        !pageData.editLinkExternal ? (
                          <div>
                            <MenuItem
                              onClick={() => handleClose()}
                              component={Link}
                              to={pageData.editLink}
                            >
                              {' '}
                              Edit{' '}
                            </MenuItem>
                            <Divider />
                          </div>
                        ) : (
                          <div>
                            <MenuItem onClick={() => handleClose()} href={pageData.editLink}>
                              {' '}
                              Edit{' '}
                            </MenuItem>
                            <Divider />
                          </div>
                        )
                      ) : null}
                      {pageData.activateLink ? (
                        <Alerter
                          close={() => handleClose()}
                          message="Activate Item?"
                          title="Activate"
                          action={hl => pageData.activateLink(hl)}
                          hitList={hitList}
                        />
                      ) : null}
                      {pageData.deactivateLink ? (
                        <Alerter
                          close={() => handleClose()}
                          message="Deactivate Item?"
                          title="Deactivate"
                          action={hl => pageData.deactivateLink(hl)}
                          hitList={hitList}
                        />
                      ) : null}
                      {pageData.deleteLink ? (
                        <Alerter
                          close={() => handleClose()}
                          message="Delete Item? this action cannot be undone"
                          title="Delete"
                          action={hl => pageData.deleteLink(hl)}
                          hitList={hitList}
                        />
                      ) : null}
                      {pageData.export ? (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            pageData.export();
                          }}
                        >
                          Export/Import
                        </MenuItem>
                      ) : null}
                    </Menu>
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

// class SubHeader extends React.Component {

//   render() {
//     const {
//       subheaderCssClasses,
//       subheaderContainerCssClasses,
//       subheaderMobileToggle,
//       pageData,
//     } = this.props;

//     return (

//     );
//   }
// }

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
  pageData: store.auth.pageData,
  hitList: store.auth.hitList,
});

export default withRouter(connect(mapStateToProps)(SubHeader));
