/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import Select from '@appgeist/react-select-material-ui';
import { Box, Paper, Divider, Button, TextField, Grid } from '@material-ui/core';
import Alert from '../../../../shared/Alert';
import { insert, getRoles } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [dataRole, setRoleData] = useState({
    role: '',
    blocks: [],
  });
  const [data, setPageData] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState({});
  const [roles, setRoles] = useState();
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function initializePage() {
    // Register the page metadata

    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-user')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-user')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-user')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-user')),
    };

    document.title = 'Create User';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Create User',
        hasMenu: false,
      })
    );
    await props.dispatch(getRoles()).then(item => setRoles(item));
    await setPerms(permData);
  }

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    await props
      .dispatch(insert(form))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  };

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'user');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.create) {
    // Final Display
    return (
      <>
        <form autoComplete="off" encType="multipart/form-data" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New User</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Username"
                  defaultValue={data.userName}
                  name="userName"
                  required
                  onChange={e => setPageData({ ...data, userName: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="First name"
                  defaultValue={data.firstName}
                  name="firstName"
                  required
                  onChange={e => setPageData({ ...data, firstName: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Last name"
                  defaultValue={data.lastName}
                  name="lastName"
                  required
                  onChange={e => setPageData({ ...data, lastName: e.target.value })}
                />
                <Select
                  label="Role"
                  options={roles}
                  name="role"
                  defaultValue={{
                    value: data.role,
                    label: data.role ? _.find(roles, item => item.value === data.role).label : '',
                  }}
                  onChange={e => setPageData({ ...data, role: e.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="email"
                  defaultValue={data.email}
                  name="email"
                  required
                  onChange={e => setPageData({ ...data, email: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="phone number"
                  defaultValue={data.phone_number}
                  name="phone_number"
                  required
                  onChange={e => setPageData({ ...data, phone_number: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Password"
                  type="password"
                  defaultValue=""
                  name="password"
                  onChange={e => setPageData({ ...data, password: e.target.value })}
                />
                <Button
                  type="submit"
                  className="ml-auto d-block my-3"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
