/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Container,
  Menu,
  MenuItem,
} from 'reactstrap';
import { Redirect, useHistory } from 'react-router-dom';

import DD from './dd';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Comment, Close } from '@material-ui/icons';
import { Link, NavLink as NLink } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic';

import { index, getmenubarparsed, getBanner, getconfig, getNewsFlash, getPopup, getFiles } from '../service';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    outline: 'none',
    transform: `translate(-${top}%, -${left}%)`,
    'box-shadow': 'black 7px 7px 8px 3px',
    boxShadow: 'black 7px 7px 8px 3px',
    width: 'fit-content',
    height: 'fit-content',
  };
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      // backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
    },
  })
);

export default function Landing(props) {
  const history = useHistory()
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [config, setConfig] = useState([]);

  const [flash, setFlash] = useState(null);
  const [popup, setPopup] = useState(null);
  const [menubar, setMenubar] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(getCookie('popup_read') == 'false' ? true : false);
  const [showFlash, setShowFlash] = React.useState(true);
  const [documentSearch, setDocSearch] = useState('');
  //

  // For Modal closing
  const handleClose = () => {
    setCookie('popup_read', 'true');
    setOpen(false);
  };

  const closeAuto = timer => {
    if (timer > 0) {
      setTimeout(() => {
        handleClose();
      }, timer * 1000);
    }
  };


  function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  function setCookie(cname, cvalue) {
    document.cookie = cname + '=' + cvalue + ';' + ';path=/';
  }

  var initializeData = async () => {
    // document.title = "NECI - NEC Indonesia";
    await props
      .dispatch(index(''))
      .then(data => setData(data))
      .catch(error => console.log(error));

    await props
      .dispatch(getmenubarparsed())
      .then(data => setMenubar(data))
      .catch(error => console.log(error));

    await props
      .dispatch(getNewsFlash())
      .then(data => setFlash(data))
      .catch(error => console.log(error));

    await props
      .dispatch(getPopup())
      .then(data => setPopup(data))
      .catch(error => console.log(error));

    await props
      .dispatch(getconfig())
      .then(data => {
        setConfig({ ...data, blocks: JSON.parse(data.menuBlocks) });
        closeAuto(parseInt(data.popup_timer || 5));
      })
      .catch(error => console.log(error));
    await setLoading(false);
  };
  const searchDocument = async (e) => {
    e.preventDefault()
    await props
      .dispatch(getFiles(documentSearch))
      .then(data => {
        history.push('/document')
        console.log(data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    initializeData();
  }, []);

  const toggle = () => setIsOpen(!isOpen);

  if (loading) {
    return null;
  }

  console.log(props);

  return (
    <div className="landing">
      {props.data.type == 'user' ? <Redirect to="dashboard" /> : null}
      <Container fluid>
        <Navbar style={{ margin: '0 2%' }} light expand="md" className="my-3">
          <NavbarBrand tag={NLink} to="/">
            <img
              alt="logo"
              className="logo"
              src={config.logo_url ? config.logo_url : toAbsoluteUrl('/media/nec/logo-nec.png')}
            />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <div className="mt-3">
              <form onSubmit={searchDocument}>
                <input type="text" name="docSearch" onChange={e => setDocSearch(e.target.value)} />
                <button type="submit" className="ml-2 btn btn-primary btn-sm" >Search</button>

              </form>
            </div>
            <div>
            </div>
            <NavItem>
              <UncontrolledDropdown>
                <NavLink tag={Link}>
                  <DropdownToggle tag={NavLink} caret>
                    Hello, {props.data.fullname}
                  </DropdownToggle>
                </NavLink>
                <DropdownMenu>
                  {props.data.type != 'employee' ? (
                    <DropdownItem tag={Link} to="/dashboard">
                      Dashboard
                    </DropdownItem>
                  ) : null}
                  <DropdownItem
                    tag={Link}
                    to={props.data.type == 'employee' ? '/profile' : '/dashboard/profile'}
                  >
                    Profile
                  </DropdownItem>
                  {/* {props.data.type == 'employee' ? (
                    <DropdownItem tag={Link} to="/request">
                      Request Stationery
                    </DropdownItem>
                  ) : null} */}
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to="/logout">
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Navbar>
        {/* MENU BAR */}
        <Navbar color="light" className="nec-navbar nav-fill" light expand="md">
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mx-auto" navbar>
              <DD item={menubar} />
            </Nav>
          </Collapse>
        </Navbar>
        <div className="content">
          <div className="main">{props.children}</div>
        </div>
      </Container>

      {popup ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper} style={modalStyle}>
            <Close onClick={handleClose} className="popx" />
            <a href={popup.link} target="_blank">
              <img className="w-100" src={popup.thumbnail_url} />
            </a>
          </div>
        </Modal>
      ) : null}

      {showFlash && flash ? (
        <div className="flash_news fixed-bottom position-sticky">
          <marquee>
            <a href={flash.link} target="_blank">
              <div className="warning mx-1">
                {' '}
                <Comment /> URGENT MESSAGE :{' '}
              </div>
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={{ __html: flash.news_text }}
              />
            </a>
          </marquee>

          <div className="hide float-right" onClick={() => setShowFlash(false)}>
            Close
          </div>
        </div>
      ) : null}
      <div className="footer">
        <div className="textfoot">{config.footer}</div>
        <div className="mediafoot">
          {' '}
          <ul>
            <li>
              <a href="https://www.facebook.com/PTNECINDONESIA/" target="_blank">
                <img alt="logo" className="soclogo" src={toAbsoluteUrl('/media/fb.png')} />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/nec_indonesia?igshid=1p84one9w8mt5" target="_blank">
                <img alt="logo" className="soclogo" src={toAbsoluteUrl('/media/ig.png')} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/nec-indonesia-pt" target="_blank">
                <img alt="logo" className="soclogo" src={toAbsoluteUrl('/media/lin.png')} />
              </a>
            </li>
          </ul>{' '}
        </div>
      </div>
    </div>
  );
}
