import HTTP from '../../../crud/HTTP';

/************************
 * SECTION ENDPOINT for profile fetch
 * RETURN @Object
 ************************/
export function profile() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get('/me')
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT for profile Update
 * RETURN @Object
 ************************/
export function profileUpdate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.patch('/me', data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT for profile fetch
 * RETURN @Object
 ************************/
export function getMenubar() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get('/utility/menubar_data')
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT for profile fetch
 * RETURN @Object
 ************************/
export function postMenubar(treeData) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post('/utility/menubar_data', treeData)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT for profile fetch
 * RETURN @Object
 ************************/
export function config() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get('/utility/config')
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT Reqs
 * RETURN @Object
 ************************/
export function getStationeryRequests(q) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/stationery_index${q}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT JOBS
 * RETURN @Object
 ************************/
export function getJobApplies(q) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/job_apply${q}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

/************************
 * SECTION ENDPOINT for profile Update
 * RETURN @Object
 ************************/
export function configUpdate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post('/utility/config', data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function deleteMenuBar(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/utility/menubar_data/${data}`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function activityIndex(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/activity_log${data}`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}
