/**
 * PART: Imports
 *
 */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';
import _ from 'underscore';

import Alert from '../../../../shared/Alert';
import STable from './Component/SelfTable';
import { index, deactivate, activate, destroy } from '../service';

import { Paper, Box } from '@material-ui/core';

export default function CategoryIndex(props) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 20,
    keyword: '',
    type: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [error, setError] = useState({});
  const [selected, setSelected] = useState([]);
  const [notification, setNotification] = useState({});
  const [perms, setPerms] = useState({});
  const [loading, setLoading] = useState(true);

  async function deactivateDispatch(id) {
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  async function activateDispatch(id) {
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  async function destroyDispatch(id) {
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  var initializeData = async () => {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-flash')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-flash')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-flash')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-flash')),
    };
    document.title = 'Flash News';
    var query = `?page=${meta.page}&per_page=${meta.per_page}&type=${meta.type}&keyword=${meta.keyword}`;
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Flash News',
        newLink: permData.create ? '/dashboard/flash/create' : false,
        deleteLink: permData.delete ? hl => destroyDispatch(hl) : null,
        activateLink: permData.update ? hl => activateDispatch(hl) : null,
        deactivateLink: permData.update ? hl => deactivateDispatch(hl) : null,
        hasMenu: permData.update || permData.delete ? true : false,
      })
    );
    await props
      .dispatch(index(query))
      .then(data => {
        setData(data.data);
        setTotalPage(data.meta.total_page);
        setTotalItem(data.meta.total_item);
      })
      .catch(error => console.log(error));

    await setPerms(permData);
    await setLoading(false);
  };

  useEffect(() => {
    async function callInitiator() {
      await initializeData();
    }
    callInitiator();
  }, [meta, notification]);

  /************************
   * SECTION Call the SweetAlert everytime the notification appears
   * RETURN @Object
   ************************/
  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
    setSelected([]);
  }, [notification]);

  /************************
   * SECTION HitList
   * RETURN @Object
   ************************/
  useEffect(() => {
    props.dispatch(auth.actions.hitCouriers(selected.join()));
  }, [selected]);

  if (loading) {
    return 'Please Wait.';
  }

  if (perms.read) {
    return (
      <STable
        data={data}
        meta={meta}
        setMeta={setMeta}
        totalItem={totalItem}
        totalPage={totalPage}
        selected={selected}
        setSelected={setSelected}
      />
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
