import HTTP from '../../../../crud/HTTP';

export function index(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/post${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getCategories() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/getCategories`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function insert(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post('/post', data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function update(id, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.put(`/post/${id}`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function detail(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/post/${data}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function destroy(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/post/${data}`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function deactivate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/post/${data}/deactivate`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function activate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/post/${data}/activate`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function setFeatured(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/post/set_featured`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function getFiles() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/utility/getFiles`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}
