/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import Select from 'react-select';
import { Box, Paper, Divider, Button, TextField, Grid } from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import { insert, getTitle, getDivision, getDepartement, getEmployee } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [pageData, setPageData] = useState({
    title_name: '',
    blocks: [],
  });
  const [pageDivision, setPageDivision] = useState({
    name: '',
    blocks: [],
  });
  const [pageDep, setPageDep] = useState({
    name: '',
    blocks: [],
  });
  const [title, setTitle] = useState([]);
  const [division, setDivision] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [departement, setDep] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-employee')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-employee')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-employee')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-employee')),
    };
    // Register the page metadata
    document.title = 'Create Employee';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Create Employee',
        hasMenu: false,
      })
    );

    await props
      .dispatch(getDivision())
      .then(data => setDivision(data))
      .catch(error => setError(error));

    await props
      .dispatch(getTitle())
      .then(data => setTitle(data))
      .catch(error => setError(error));
    setLoading(false);

    await props
      .dispatch(getEmployee())
      .then(data => setEmployee(data))
      .catch(error => setError(error));

    await props
      .dispatch(getDepartement())
      .then(data => setDep(data))
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);

    form.set('empSuper', pageData.empSuper || []);
    form.set('empSub', pageData.empSub || []);
    await setSubmitLoading(true);
    await props
      .dispatch(insert(form))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  };

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'employee');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.create) {
    // Final Display
    return (
      <>
        <form autoComplete="off" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New Employee</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Employee number"
                  defaultValue={data.employee_number}
                  name="employee_number"
                  type="number"
                  required
                  onChange={e => setData({ ...data, employee_number: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Fullname"
                  defaultValue={data.title}
                  name="fullname"
                  required
                  onChange={e => setData({ ...data, fullname: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Display name"
                  defaultValue={data.title}
                  name="display_name"
                  required
                  onChange={e => setData({ ...data, display_name: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="email"
                  defaultValue={data.title}
                  name="email"
                  required
                  onChange={e => setData({ ...data, email: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="phone number"
                  defaultValue={data.title}
                  name="phone_number"
                  // required
                  onChange={e => setData({ ...data, phone_number: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Password"
                  type="password"
                  defaultValue=""
                  name="password"
                  required
                  onChange={e => setData({ ...data, password: e.target.value })}
                />
                <div className="mb-3">
                  <label for="titile">Select title</label>
                  <Select
                    options={title}
                    name="title"
                    defaultValue={pageData.title}
                    onChange={e => setPageData({ ...pageData, title: e.value })}
                  />
                </div>
                <div className="mb-3">
                  <label for="division">Select Division</label>
                  <Select
                    options={division}
                    name="division"
                    defaultValue={pageData.division}
                    onChange={e => setPageData({ ...pageData, division: e.value })}
                  />
                </div>
                <div className="mb-3">
                  <label for="departement">Select Departement</label>
                  <Select
                    options={departement}
                    name="departement"
                    defaultValue={pageData.departement}
                    onChange={e => setPageData({ ...pageData, departement: e.value })}
                  />
                </div>
                <div className="mb-3">
                  <label for="titile">Select Superordinate</label>
                  <Select
                    options={employee}
                    name="empSuper"
                    isMulti
                    onChange={e => {
                      setPageData({
                        ...pageData,
                        empSuper: _.pluck(e, 'value'),
                      });
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="titile">Select Subordinate</label>
                  <Select
                    options={employee}
                    name="empSub"
                    isMulti
                    onChange={e => {
                      setPageData({
                        ...pageData,
                        empSub: _.pluck(e, 'value'),
                      });
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Linkedln"
                  defaultValue={data.linkedln}
                  name="linkedln"
                  onChange={e => setData({ ...data, linkedln: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Facebook"
                  defaultValue={data.facebook}
                  name="facebook"
                  onChange={e => setData({ ...data, facebook: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Instagram"
                  defaultValue={data.instagram}
                  name="instagram"
                  onChange={e => setData({ ...data, instagram: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  required
                  type="number"
                  label="Point"
                  defaultValue={data.point}
                  name="point"
                  onInvalid={e => e.target.setCustomValidity('Please Input Employee Point.')}
                  onInput={e => e.target.setCustomValidity('')}
                  onChange={e => setData({ ...data, point: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Office address"
                  defaultValue={data.office_address}
                  name="office_address"
                  onChange={e => setData({ ...data, office_address: e.target.value })}
                />
                {/* <TextField
                variant="outlined"
                className="my-3"
                fullWidth
                label="Office phone number"
                defaultValue={data.title}
                name="office_phone"
                onChantge={e => setData({ ...data, office_phone: e.target.value })}
              /> */}
                <Grid container spacing={1} className="px-auto w-100" justify="center">
                  <Grid item xs={4}>
                    <label for="titile">Profile Picture</label>
                    {thumbnail ? (
                      <img src={thumbnail} className="w-100 img-thumbnail m-1 d-block mx-auto" />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      type="file"
                      name="thumbnail"
                      className="w-25 text-center h-100 d-block mx-auto btn btn-info"
                      onChange={x => {
                        if (x.target.files) {
                          setThumbnail(URL.createObjectURL(x.target.files[0]));
                        } else {
                          setThumbnail(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  className="ml-auto d-block my-3"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
                <small>
                  {' '}
                  <i className="text-muted">* required</i>{' '}
                </small>
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
