/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';
import _ from 'underscore';
import Select from 'react-select';
import 'react-quill/dist/quill.snow.css'; // ES6

// NOTE: Views
import ReactQuill from 'react-quill'; // ES6
import { Paper, Button, Box, Divider, TextField, Grid, Switch } from '@material-ui/core';

import GJSE from './component/richtext';
import Alert from '../../../../shared/Alert';
import Loading from '../../../../shared/Loading';
import {
  insert,
  getCategories,
  detail,
  update,
  deactivate,
  destroy,
  activate,
  getFiles,
} from '../service';

export default function TagIndex(props) {
  const [pageData, setPageData] = useState({
    name: '',
    blocks: [],
  });
  const [category, setCategory] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  const [files, setFiles] = useState([]);

  async function submit(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    form.append('content', pageData.content);
    form.append('css', pageData.css);
    form.set('featured', pageData.featured);
    await props
      .dispatch(update(pageData.id, form))
      .then(data => setNotification({ type: 'success', redirect: true, ...data }))
      .catch(error => setNotification({ type: 'error', redirect: false, ...error.response.data }));
  }

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  /************************
   * SECTION Initialize the Entire Page
   * set The Page Title
   * set available Button after the Data has been fetched
   * RETURN @NULL
   ************************/
  async function initializePage(id) {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-post')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-post')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-post')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-post')),
    };
    document.title = 'Edit Post';
    await setLoading(true);
    await props
      .dispatch(getCategories())
      .then(data => {
        setCategory(data);
      })
      .catch(error => setError(error));
    await props
      .dispatch(detail(props.match.params.slug))
      .then(async data => {
        await setPageData(data);
        await props.dispatch(
          auth.actions.registerPage({
            title: 'Edit Post',
            deleteLink: permData.delete ? () => destroyDispatch(data.id) : null,
            activateLink: permData.update ? () => activateDispatch(data.id) : null,
            deactivateLink: permData.update ? () => deactivateDispatch(data.id) : null,
            hasMenu: permData.update || permData.delete ? true : false,
          })
        );
      })
      .catch(error => setError(error));

    await props
      .dispatch(getFiles())
      .then(data => {
        setFiles(data);
      })
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  /************************
   * SECTION Call the Page initialization upon page load
   * RETURN @NULL
   ************************/
  useEffect(() => {
    initializePage();
  }, []);

  /************************
   * SECTION Call the SweetAlert notification sets
   * RETURN @NULL
   ************************/
  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'post', notification.redirect);
    }
  }, [notification, props]);

  /************************
   * SECTION LOADING
   * TODO make a loader
   * RETURN @NULL
   ************************/
  if (loading) {
    return <p>Please Wait</p>;
  }

  /************************
   * SECTION Main View
   * RETURN @NULL
   ************************/
  if (perms.read) {
    return (
      <>
        <form autoComplete="off" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper className="tests">
              <Box p={5}>
                <h4>PAGE INFORMATION</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Post title"
                  defaultValue={pageData.name}
                  name="name"
                  required
                  onChange={e => setPageData({ ...pageData, name: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Excerpt"
                  defaultValue={pageData.excerpt}
                  name="excerpt"
                  required
                  onChange={e => setPageData({ ...pageData, excerpt: e.target.value })}
                />
                <div className="mb-3">
                  <label for="category">Select Category</label>
                  <Select
                    options={category}
                    name="category"
                    defaultValue={{
                      value: pageData.category ? pageData.category.id : null,
                      label: pageData.category
                        ? _.findWhere(category, { id: pageData.category.id }).name
                        : null,
                    }}
                    onChange={e =>
                      setPageData({
                        ...pageData,
                        category: { name: e.label, id: e.value },
                      })
                    }
                  />
                </div>

                <div className="mb-3">
                  <label for="category">Featured</label>
                  <Switch
                    defaultChecked={pageData.featured}
                    onChange={e =>
                      setPageData({
                        ...pageData,
                        featured: e.target.checked.toString(),
                      })
                    }
                    name="featured"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <div>
                    <small>featured post will be shown on the landing page.</small>
                  </div>
                </div>
                <Grid container spacing={1} className="px-auto w-100" justify="center">
                  <Grid item xs={12}>
                    {thumbnail || pageData.thumbnail_url ? (
                      <img
                        src={thumbnail || pageData.thumbnail_url}
                        className="w-25 img-thumbnail m-1 d-block mx-auto"
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      type="file"
                      name="thumbnail"
                      className="w-100 h-100 btn btn-info"
                      onChange={x => {
                        if (x.target.files.length) {
                          setThumbnail(URL.createObjectURL(x.target.files[0]));
                        } else {
                          setThumbnail(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <br />
            <Paper>
              <Box p={1}>
                <GJSE pageData={pageData} files={files} setPageData={setPageData} perms={perms} />
              </Box>
            </Paper>
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
