import React, { useState } from 'react';
import DropdownMenu, { NestedDropdownMenu } from 'react-dd-menu';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';

export default function DDChild({ item, align }) {
  const nestedProps = {
    toggle: (
      <>
        {item.url.includes('http') ? (
          <a href={item.url} target={item.outbound ? '_blank' : '_self'}>
            {item.children.length ? (
              align == 'right' ? (
                <ArrowLeft />
              ) : null
            ) : align == 'right' ? (
              <ArrowLeft className="noopacity" />
            ) : null}
            {item.name}
            {item.children.length ? align != 'right' ? <ArrowRight /> : null : null}
          </a>
        ) : (
          <Link to={item.url} target={item.outbound ? '_blank' : '_self'}>
            {item.children.length ? (
              align == 'right' ? (
                <ArrowLeft />
              ) : null
            ) : align == 'right' ? (
              <ArrowLeft className="noopacity" />
            ) : null}
            {item.name}
            {item.children.length ? align != 'right' ? <ArrowRight /> : null : null}
          </Link>
        )}
      </>
    ),
    animate: true,
    align: align,
  };
  return (
    <NestedDropdownMenu {...nestedProps}>
      {item.children.length
        ? item.children.map(menu => <DDChild item={menu} align={align} />)
        : null}
    </NestedDropdownMenu>
  );
}
