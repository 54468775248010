/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../store/ducks/auth.duck';

import { Box, Paper, TextField as TFO, Divider, Button, Grid } from '@material-ui/core';
import TextField from '../../../shared/input/text';
import Alert from '../../../shared/Alert';

import { config, configUpdate } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnaill, setThumbnaill] = useState(null);

  async function initializePage() {
    // Register the page metadata
    document.title = 'Configuration';
    await setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Configuration',
        hasMenu: false,
      })
    );

    // Fetch Profile data
    await props
      .dispatch(config())
      .then(data => {
        setData(data.data);
        setThumbnail(data.data.favicon_url);
        setThumbnaill(data.data.logo_url);
        setBlocks(data.data.menuBlocks);
      })
      .catch(error => setError(error));

    await setLoading(false);
  }

  async function updateProfile(e) {
    e.preventDefault();
    var data = new FormData(e.target);
    data.append('blocks', blocks);
    await setSubmitLoading(true);
    await props
      .dispatch(configUpdate(data))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  // Final Display
  return (
    <>
      <form onSubmit={e => updateProfile(e)}>
        <fieldset disabled={submitLoading}>
          <Paper elevation={0}>
            <Box p={5}>
              <h4>Homepage Management</h4>
              <Divider />
              <br />
              <TextField req name="site_title" label="Site Title" value={data.siteTitle} />
              <TextField req name="footer" label="Footer Title" value={data.footer} />
              <TextField
                req
                name="popup_timer"
                label="Popup timer (Seconds)"
                value={data.popup_timer}
              />
              <Grid container justify="space-evenly">
                <Grid item container xs={3} direction="column" justify="center">
                  <label>Favicon</label>
                  <Grid item className="mb-3">
                    {thumbnail ? (
                      <img src={thumbnail} className="thumbnailGallery d-block img-thumbnail" />
                    ) : (
                      <img
                        src={
                          'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-stock-vector-no-image-available-icon-flat-vector.jpg?ver=6'
                        }
                        className="thumbnailGallery d-block img-thumbnail"
                      />
                    )}
                  </Grid>
                  <Grid item className="mb-3">
                    <input
                      type="file"
                      name="favicon"
                      className=" w-100 btn btn-info"
                      onChange={x => {
                        if (x.target.files) {
                          setThumbnail(URL.createObjectURL(x.target.files[0]));
                        } else {
                          setThumbnail(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={3} direction="column" justify="center">
                  <label>Logo</label>
                  <Grid item className="mb-3">
                    {thumbnaill ? (
                      <img src={thumbnaill} className="thumbnailGallery d-block img-thumbnail" />
                    ) : (
                      <img
                        src={
                          'https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-stock-vector-no-image-available-icon-flat-vector.jpg?ver=6'
                        }
                        className="thumbnailGallery d-block img-thumbnail"
                      />
                    )}
                  </Grid>
                  <Grid item className="mb-3">
                    <input
                      type="file"
                      name="logo"
                      className=" w-100 btn btn-info"
                      onChange={x => {
                        if (x.target.files) {
                          setThumbnaill(URL.createObjectURL(x.target.files[0]));
                        } else {
                          setThumbnaill(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <h4>Receiver Email settings</h4>
              <Divider />
              <br />
              <TextField
                req
                name="email_job"
                label="Job Application Email"
                value={data.email_job}
              />
              <TextField
                req
                name="email_stationary"
                label="Stationary RequestEmail"
                value={data.email_stationary}
              />
              <TextField req name="email_iso" label="ISO Center Email" value={data.email_iso} />
              <br />
              <h4>SMPT Email Setup</h4>
              <Divider />
              <br />
              <TextField req name="email_name" label="Sender Email" value={data.email_name} />
              <TextField
                req
                pass
                name="email_pass"
                label="Sender Email Password"
                value={data.email_password}
              />
              <TextField req name="email_host" label="Sender Email Host" value={data.email_host} />
              <TextField
                req
                name="email_port_smtp"
                label="SMTP Email Port"
                value={data.email_port_smtp}
              />
              <TextField
                req
                name="email_port_pop"
                label="POP Email Port"
                value={data.email_port_pop}
              />

              <Button
                type="submit"
                className="ml-auto d-block my-3"
                color="primary"
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            </Box>
          </Paper>
          <br />
        </fieldset>
      </form>
    </>
  );
}
