import HTTP from '../../../../crud/HTTP';

export function index(query) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/popup${query}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function insert(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post('/popup', data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function update(id, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.put(`/popup/${id}`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function detail(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/popup/${data}`)
        .then(data => {
          return resolve(data.data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function destroy(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/popup/${data}`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function deactivate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/popup/${data}/deactivate`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function activate(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/popup/${data}/activate`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}
