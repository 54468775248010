import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import { Helmet } from 'react-helmet';
import { toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import LoginEmployee from './LoginEmployee';
import { saveUrlRedirect } from '../../router/RouterHelpers';
import { useLastLocation } from 'react-router-last-location';


export default function AuthPage() {
  const lastLocation = useLastLocation();
  saveUrlRedirect(lastLocation)

  document.cookie = 'popup_read' + '=' + 'false' + ';' + ';path=/';
  window.sessionStorage.removeItem('user_type');
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver"></div>

              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title"></h3>
                  <h4 className="kt-login__subtitle"></h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright text-dark"></div>
                </div>
              </div>
            </div>

            <div
              className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bg/kanan.jpg')})`,
                backgroundSize: 'contain',
              }}
            >
              <Switch>
                <Redirect from="/auth" exact={true} to="/auth/login" />

                <Route path="/auth/employee" component={LoginEmployee} />
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
