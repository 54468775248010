import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({message, title, action, close, hitList}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <MenuItem className={title == "Delete" ? "text-danger" : null} onClick={()=>{
          handleClickOpen()
          close()
        }}>{ title }</MenuItem>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
        >
        <DialogTitle className={title == "Delete" ? "text-danger" : null} id="alert-dialog-title">{ title }</DialogTitle>
        <DialogContent>
          <DialogContentText className={title == "Delete" ? "text-danger" : null} id="alert-dialog-description">
              { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button  onClick={()=>{
              handleClose()
              action(hitList)
            }} className="text-danger" autoFocus>
            { title }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}