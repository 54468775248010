import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import _ from "underscore"
export default class MenuSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig, perms } = this.props;
    
    
    if(item.perm){
      var permsCheck = _.find(perms,e=>{
        // return e==item.perm
        return e.includes(item.perm)
      })
      if(!permsCheck){
        return null
      }
    }

    return (
      <ul className="kt-menu__subnav">
        {item.submenu.map((child, index) => (
          <React.Fragment key={index}>
            {child.section && (
              <MenuSection
                perms={perms}
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
              />
            )}

            {child.separator && (
              <MenuItemSeparator
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
              />
            )}

            {child.title && (
              <MenuItem
                perms={perms}
                item={child}
                parentItem={item}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
              />
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  }
}
