import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { TableCell, TableRow, Checkbox, Chip, IconButton } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import _ from 'underscore';

export default function SelfRow({ data, dispatchSetFeatured, selected, setSelected }) {
  var statusProc = active => {
    if (!active) {
      return <Chip label="Active" className="bg-info text-white" />;
    } else {
      return <Chip label="Inactive" className="bg-warning" />;
    }
  };
  var featuredProc = featured => {
    if (featured) {
      return <Chip label="Featured" className="bg-info text-white" />;
    } else {
      return (
        <Chip
          onClick={() => dispatchSetFeatured(data.id)}
          label="Set As Featured"
          className="bg-secondary"
        />
      );
    }
  };

  function copy() {
    var copyText = document.getElementById(`url_${data.id}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    toast.success(`Link copied to clipboard : ${window.location.origin}/post/${data.slug}`);
  }

  return (
    <>
      <input
        type="text"
        className="no-vision"
        id={`url_${data.id}`}
        value={`${window.location.origin}/post/${data.slug}`}
      />
      <TableRow>
        <TableCell component="th" scope="row">
          <Checkbox
            value={data.id}
            checked={_.contains(selected, data.id)}
            onChange={async e => {
              if (!e.target.checked) {
                var finder = await _.without(selected, data.id);
                await setSelected(finder);
              } else {
                await setSelected([...selected, data.id]);
              }
            }}
          />
        </TableCell>
        <TableCell>{data.id}</TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/post/${data.slug}`}>{data.name}</Link>
        </TableCell>
        <TableCell align="center">
          {data.category ? (
            <Link to={`/dashboard/category/${data.category.slug}`}>{data.category.name}</Link>
          ) : null}
        </TableCell>
        <TableCell align="center">{featuredProc(data.featured)}</TableCell>
        <TableCell align="center">{statusProc(data.deletedAt)}</TableCell>

        <TableCell align="center">
          <IconButton>
            <FileCopy onClick={copy} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
