import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as auth from '../../store/ducks/auth.duck';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormControl, Input, InputLabel, FormHelperText, Button } from '@material-ui/core';
import { ArrowBack, Send } from '@material-ui/icons';
import { resetCheckEmployee as resetCheck, resetEmployee as reset } from './services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toAbsoluteUrl } from '../../../_metronic';

function ForgotPassword(props) {
  const [password, setPassword] = useState(null);
  const [data, setData] = useState({});
  const [uData, setUdata] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bigLoading, setBigLoading] = useState(true);
  async function init() {
    await setBigLoading(true);
    await props
      .dispatch(resetCheck(props.match.params.token))
      .then(result => {
        setData(result);
        console.log(result);
      })
      .catch(err => {
        setError(err.response.data);
      });
    await setBigLoading(false);
  }

  async function sendReset(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setLoading(true);
    await setData(null);
    await setUdata({ ...uData, id: null });
    await setError(null);
    await props
      .dispatch(reset(props.match.params.token, form))
      .then(data => setData(data))
      .catch(error => setError(error.response.data));
    await setLoading(false);
  }

  useEffect(() => {
    init();
  }, []);

  if (bigLoading) {
    return null;
  }

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver"></div>

            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title"></h3>
                <h4 className="kt-login__subtitle"></h4>
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info"></div>
            </div>
          </div>

          <div
            className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/kanan.jpg')})`,
              backgroundSize: 'contain',
            }}
          >
            {/* <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"> */}
            <div className="kt-login__body loginform">
              <div className="kt-login__form">
                <Box mt={5}>
                  {loading ? (
                    <CircularProgress
                      className="position-absolute"
                      style={{
                        top: '50%',
                        right: '50%',
                      }}
                      color="primary"
                    />
                  ) : (
                    <>
                      {/* {!loading ? <h3>Password Reset for {data.data.mail || 'Nobody'}</h3> : null} */}
                      {loading ? (
                        <CircularProgress
                          className="position-absolute"
                          style={{
                            top: '50%',
                            right: '50%',
                          }}
                          color="secondary"
                        />
                      ) : null}
                      {data ? (
                        <div class="alert alert-success" role="alert">
                          {data.message}
                        </div>
                      ) : null}
                      {error ? (
                        <div class="alert alert-danger" role="alert">
                          This account does not exist.
                        </div>
                      ) : null}
                      {data.data ? (
                        data.data.id ? (
                          <form onSubmit={e => sendReset(e)}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="my-input">New Password</InputLabel>
                              <Input
                                type="password"
                                name="password"
                                aria-describedby="my-helper-text"
                                value={password}
                                disabled={loading}
                                onChange={e => setPassword(e.target.value)}
                              />
                              <Input
                                type="hidden"
                                name="id"
                                aria-describedby="my-helper-text"
                                value={data.data.id || null}
                              />
                              <FormHelperText id="my-helper-text">
                                Must be at least 8 Character, Alphabetical and Numerical accepted.
                              </FormHelperText>
                            </FormControl>

                            <div className="mt-3">
                              <Button
                                type="submit"
                                size="large"
                                className="mr-2"
                                variant="outlined"
                                disabled={loading}
                                startIcon={<Send />}
                              >
                                Change Password
                              </Button>
                            </div>
                          </form>
                        ) : (
                          <Button
                            type="button"
                            component={Link}
                            to="/auth/employee"
                            size="large"
                            className="mr-2"
                            variant="outlined"
                            disabled={loading}
                            startIcon={<ArrowBack />}
                          >
                            Back to Login
                          </Button>
                        )
                      ) : (
                        <Button
                          type="button"
                          component={Link}
                          to="/auth/employee"
                          size="large"
                          className="mr-2"
                          variant="outlined"
                          disabled={loading}
                          startIcon={<ArrowBack />}
                        >
                          Back to Login
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null)(ForgotPassword);
