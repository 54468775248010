import React, { useState } from 'react';
import { CircularProgress, TextField, Grid, Box, Button } from '@material-ui/core';
import GalleryItem from './galleryItem';
import _ from 'underscore';
export default function Image({ selfContent, submit, removedGallery, setRemovedGallery, perms }) {
  const [total, setTotal] = useState(selfContent);

  function repeater() {
    var all = [];
    total.map(e => {
      all.push(
        <GalleryItem
          total={total}
          setTotal={setTotal}
          removedGallery={removedGallery}
          setRemovedGallery={setRemovedGallery}
          data={e}
        />
      );
    });
    return all;
  }

  return (
    <Grid container spacing={3} justify="space-around">
      {repeater()}
      <Grid item xs={3} className="addGallery">
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setTotal([
              ...total,
              {
                altText: '',
                ordering:
                  _.max(total, item => {
                    return item.ordering;
                  }).ordering + 1 || 1,
                id: `new_${Math.random()
                  .toString(36)
                  .substring(2, 15)}`,
              },
            ]);
          }}
        >
          Add
        </a>
      </Grid>
      {submit ? (
        <Grid item container xs={12} justify="center">
          <Box m={2}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box py={2}>
            {perms.update ? (
              <Button
                disabled={submit}
                type="submit"
                variant="contained"
                color="primary"
                id="submitButton"
                className="ml-auto d-block"
              >
                Submit
              </Button>
            ) : null}
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
