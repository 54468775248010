import React, { useState, useEffect } from 'react';
import * as auth from '../../../../store/ducks/auth.duck';

import { Box, Paper, Divider, Button } from '@material-ui/core';
import TextField from '../../../shared/input/text';
import Alert from '../../../shared/Alert';

import { profile, profileUpdate } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function initializePage() {
    // Register the page metadata
    document.title = 'Profile';
    await setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Profile',
        hasMenu: false,
      })
    );

    // Fetch Profile data
    await props
      .dispatch(profile())
      .then(data => setData(data.data))
      .catch(error => setError(error));

    await setLoading(false);
  }

  async function updateProfile(e) {
    e.preventDefault();
    var data = new FormData(e.target);
    await setSubmitLoading(true);
    await props
      .dispatch(profileUpdate(data))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  // Final Display
  return (
    <>
      <form onSubmit={e => updateProfile(e)}>
        <fieldset disabled={submitLoading}>
          <Paper elevation={0}>
            <Box p={5}>
              <h4>Profile</h4>
              <Divider />
              <TextField req name="userName" label="Username" value={data.userName} />
              <TextField req name="firstName" label="First name" value={data.firstName} />
              <TextField req name="lastName" label="Last name" value={data.lastName} />
              <TextField req name="phone" label="Phone" value={data.phone} />
              <TextField req name="email" label="e-Mail" value={data.email} />
              <TextField name="website" label="Website" value={data.website} />
              <TextField name="facebook" label="Facebook" value={data.facebook} />
              <TextField name="twitter" label="Twitter" value={data.twitter} />
              <TextField name="bio" label="Short biography" value={data.bio} />

              <Button
                type="submit"
                className="ml-auto d-block my-3"
                color="primary"
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            </Box>
          </Paper>
          <br />

          <Paper elevation={0}>
            <Box p={5}>
              <h4>Password</h4>
              <Divider />
              <TextField pass name="oldPassword" label="Old Password" />
              <TextField pass name="newPassword" label="new Password" />
              <TextField pass name="newPasswordConfirm" label="Password Confirm" />
              <Button
                type="submit"
                className="ml-auto d-block my-3"
                color="primary"
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </fieldset>
      </form>
    </>
  );
}
