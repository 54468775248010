import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Act from './ActivityTrail';
import Debug from './DebugLog';
import { LayoutSplashScreen } from '../../../_metronic';

import { connect } from 'react-redux';

//NOTE: ADMIN PAGES
import Profile from './admin/profile';
import Menubar from './admin/menubar';
import Configuration from './admin/Configuration';
import Homepage from './admin/homepage';

import PageIndex from './admin/page/list';
import PageCreate from './admin/page/create';
import PageEdit from './admin/page/edit';

import CategoryIndex from './admin/category/list';
import CategoryCreate from './admin/category/create';
import CategoryEdit from './admin/category/edit';

import PostIndex from './admin/post/list';
import PostCreate from './admin/post/create';
import PostEdit from './admin/post/edit';

import EmployeeIndex from './admin/employee/list';
import EmployeeCreate from './admin/employee/create';
import EmployeeEdit from './admin/employee/edit';

import TitleIndex from './admin/TitleManagement/list';
import TitleCreate from './admin/TitleManagement/create';
import TitleEdit from './admin/TitleManagement/edit';

import DivisionIndex from './admin/Division/list';
import DivisionCreate from './admin/Division/create';
import DivisionEdit from './admin/Division/edit';

import DepartementIndex from './admin/departement/list';
import DepartementCreate from './admin/departement/create';
import DepartementEdit from './admin/departement/edit';

import BannerIndex from './admin/banner/list';
import BannerCreate from './admin/banner/create';
import BannerEdit from './admin/banner/edit';

import FlashIndex from './admin/flash/list';
import FlashCreate from './admin/flash/create';
import FlashEdit from './admin/flash/edit';

import FormIndex from './admin/form/list';
import FormCreate from './admin/form/create';
import FormEdit from './admin/form/edit';

import PopupIndex from './admin/popup/list';
import PopupCreate from './admin/popup/create';
import PopupEdit from './admin/popup/edit';

import UserIndex from './admin/user/list';
import UserCreate from './admin/user/create';
import UserEdit from './admin/user/edit';

import RequestsIndex from './admin/requests/list';
import JAIndex from './admin/jobApplies/list';

import RoleIndex from './admin/role/list';
import RoleCreate from './admin/role/create';
import RoleEdit from './admin/role/edit';

import FileIndex from './admin/file/list';
import FileCreate from './admin/file/create';
import FileEdit from './admin/file/edit';

var mapStateToProps = store => {
  return {
    data: store.auth.user,
  };
};

function HomePage(props) {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* {<Redirect exact from="/" to="/dashboard" />} */}

        <Route exact={true} path="/dashboard" component={Dashboard} />
        <Route exact={true} path="/dashboard/activity_trail" component={Act} />
        <Route exact={true} path="/dashboard/debug_log" component={Debug} />
        <Route exact={true} path="/dashboard/profile" component={Profile} />
        <Route exact={true} path="/dashboard/menubar" component={Menubar} />
        <Route exact={true} path="/dashboard/configuration" component={Configuration} />
        <Route exact={true} path="/dashboard/homepage" component={Homepage} />

        {/* PAGES */}
        <Route exact={true} path="/dashboard/page" component={PageIndex} />
        <Route exact={true} path="/dashboard/page/create" component={PageCreate} />
        <Route exact={true} path="/dashboard/page/:slug" component={PageEdit} />

        {/* CATS */}
        <Route exact={true} path="/dashboard/category" component={CategoryIndex} />
        <Route exact={true} path="/dashboard/category/create" component={CategoryCreate} />
        <Route exact={true} path="/dashboard/category/:slug" component={CategoryEdit} />

        {/* POST */}
        <Route exact={true} path="/dashboard/post" component={PostIndex} />
        <Route exact={true} path="/dashboard/post/create" component={PostCreate} />
        <Route exact={true} path="/dashboard/post/:slug" component={PostEdit} />

        {/* EMPLOYEE */}
        <Route exact={true} path="/dashboard/employee" component={EmployeeIndex} />
        <Route exact={true} path="/dashboard/employee/create" component={EmployeeCreate} />
        <Route exact={true} path="/dashboard/employee/:id" component={EmployeeEdit} />

        {/* Title Management */}
        <Route exact={true} path="/dashboard/title" component={TitleIndex} />
        <Route exact={true} path="/dashboard/title/create" component={TitleCreate} />
        <Route exact={true} path="/dashboard/title/:id" component={TitleEdit} />

        {/* Division management */}
        <Route exact={true} path="/dashboard/division" component={DivisionIndex} />
        <Route exact={true} path="/dashboard/division/create" component={DivisionCreate} />
        <Route exact={true} path="/dashboard/division/:id" component={DivisionEdit} />

        {/* Departement managment */}
        <Route exact={true} path="/dashboard/departement" component={DepartementIndex} />
        <Route exact={true} path="/dashboard/departement/create" component={DepartementCreate} />
        <Route exact={true} path="/dashboard/departement/:id" component={DepartementEdit} />

        {/* Banner management */}
        <Route exact={true} path="/dashboard/banner" component={BannerIndex} />
        <Route exact={true} path="/dashboard/banner/create" component={BannerCreate} />
        <Route exact={true} path="/dashboard/banner/:id" component={BannerEdit} />

        {/* Flash  News management */}
        <Route exact={true} path="/dashboard/flash" component={FlashIndex} />
        <Route exact={true} path="/dashboard/flash/create" component={FlashCreate} />
        <Route exact={true} path="/dashboard/flash/:id" component={FlashEdit} />
        {/* Flash  News management */}
        <Route exact={true} path="/dashboard/form" component={FormIndex} />
        <Route exact={true} path="/dashboard/form/create" component={FormCreate} />
        <Route exact={true} path="/dashboard/form/:id" component={FormEdit} />

        {/* Popup management */}
        <Route exact={true} path="/dashboard/popup" component={PopupIndex} />
        <Route exact={true} path="/dashboard/popup/create" component={PopupCreate} />
        <Route exact={true} path="/dashboard/popup/:id" component={PopupEdit} />

        {/* User Management */}
        <Route exact={true} path="/dashboard/user" component={UserIndex} />
        <Route exact={true} path="/dashboard/user/create" component={UserCreate} />
        <Route exact={true} path="/dashboard/user/:id" component={UserEdit} />

        <Route exact={true} path="/dashboard/requests" component={RequestsIndex} />
        <Route exact={true} path="/dashboard/job_application" component={JAIndex} />

        <Route exact={true} path="/dashboard/role" component={RoleIndex} />
        <Route exact={true} path="/dashboard/role/create" component={RoleCreate} />
        <Route exact={true} path="/dashboard/role/:id" component={RoleEdit} />

        <Route exact={true} path="/dashboard/file" component={FileIndex} />
        <Route exact={true} path="/dashboard/file/create" component={FileCreate} />
        <Route exact={true} path="/dashboard/file/:id" component={FileEdit} />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}

export default connect(mapStateToProps)(HomePage);
