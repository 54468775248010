import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import { Box, Paper, Divider, Button } from '@material-ui/core';
import TextField from '../../../../shared/input/text';
import Alert from '../../../../shared/Alert';

import { update, detail, deactivate, activate, destroy } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-category')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-category')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-category')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-category')),
    };
    document.title = 'Create Category';
    await setLoading(true);

    await props
      .dispatch(detail(props.match.params.slug))
      .then(async data => {
        await setData(data);
        await props.dispatch(
          auth.actions.registerPage({
            title: 'Edit Category',
            deleteLink: permData.delete ? () => destroyDispatch(data.id) : null,
            activateLink: permData.update ? () => activateDispatch(data.id) : null,
            deactivateLink: permData.update ? () => deactivateDispatch(data.id) : null,
            hasMenu: permData.update || permData.delete ? true : false,
          })
        );
      })
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  async function updateCategory(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    await props
      .dispatch(update(data.id, form))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'category');
    }
  }, [notification, props]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.read) {
    // Final Display
    return (
      <>
        <form onSubmit={e => updateCategory(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New Category</h4>
                <Divider />
                <TextField name="name" label="name" value={data.name} />

                {perms.update ? (
                  <Button
                    type="submit"
                    className="ml-auto d-block my-3"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Submit
                  </Button>
                ) : null}
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
