/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import { Box, Paper, Divider, Button, TextField } from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import { insert } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-banner')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-banner')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-banner')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-banner')),
    };
    // Register the page metadata
    document.title = 'Create Category';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Create Category',
        hasMenu: false,
      })
    );

    await setPerms(permData);
    await setLoading(false);
  }

  async function updateProfile(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    await props
      .dispatch(insert(form))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'category');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  if (perms.create) {
    // Final Display
    return (
      <>
        <form autoComplete="off" enctype="multipart/form-data" onSubmit={e => updateProfile(e)}>
          <fieldset disabled={submitLoading}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New Category</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Name"
                  defaultValue={data.title}
                  name="name"
                  required
                  onChange={e => setData({ ...data, name: e.target.value })}
                />
                <Button
                  type="submit"
                  className="ml-auto d-block my-3"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
