/**
 * PART: Imports
 *
 */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';
import _ from 'underscore';

import Alert from '../../../../shared/Alert';
import STable from './Component/SelfTable';
import { index, deactivate, activate, destroy } from '../service';

export default function CategoryIndex(props) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 20,
    keyword: '',
    type: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [error, setError] = useState({});
  const [selected, setSelected] = useState([]);
  const [notification, setNotification] = useState({});

  async function deactivateDispatch(id) {
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  async function activateDispatch(id) {
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  async function destroyDispatch(id) {
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  var initializeData = async () => {
    document.title = 'Division';
    var query = `?page=${meta.page}&per_page=${meta.per_page}&type=${meta.type}&keyword=${meta.keyword}`;
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Division',
        newLink: '/dashboard/division/create',
        deleteLink: hl => destroyDispatch(hl),
        activateLink: hl => activateDispatch(hl),
        deactivateLink: hl => deactivateDispatch(hl),
        hasMenu: true,
      })
    );

    await props
      .dispatch(index(query))
      .then(data => {
        setData(data.data);
        setTotalPage(data.meta.total_page);
        setTotalItem(data.meta.total_item);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    async function callInitiator() {
      await initializeData();
    }
    callInitiator();
  }, [meta, notification]);

  /************************
   * SECTION Call the SweetAlert everytime the notification appears
   * RETURN @Object
   ************************/
  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
    setSelected([]);
  }, [notification]);

  /************************
   * SECTION HitList
   * RETURN @Object
   ************************/
  useEffect(() => {
    props.dispatch(auth.actions.hitCouriers(selected.join()));
  }, [selected]);

  return (
    <STable
      data={data}
      meta={meta}
      setMeta={setMeta}
      totalItem={totalItem}
      totalPage={totalPage}
      selected={selected}
      setSelected={setSelected}
    />
  );
}
