/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import { Box, Paper, Divider, Button, TextField } from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import { update, detail, destroy, deactivate, activate } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function deactivateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function activateDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function destroyDispatch(id) {
    await setLoading(true);
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setLoading(false);
  }

  async function initializePage() {
    // Register the page metadata
    document.title = 'Edit Division';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Edit Division',
        hasMenu: false,
      })
    );

    await props
      .dispatch(detail(props.match.params.id))
      .then(async data => {
        await setData(data);
        await props.dispatch(
          auth.actions.registerPage({
            title: 'Edit Division',
            deleteLink: () => destroyDispatch(data.id),
            activateLink: () => activateDispatch(data.id),
            deactivateLink: () => deactivateDispatch(data.id),
            hasMenu: true,
          })
        );
      })
      .catch(error => setError(error));

    setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    await setSubmitLoading(true);
    await props
      .dispatch(update(data.id, form))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'division');
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  // Final Display
  return (
    <>
      <form autoComplete="off" onSubmit={e => submit(e)}>
        <fieldset disabled={submitLoading}>
          <Paper elevation={0}>
            <Box p={5}>
              <h4>New Division</h4>
              <Divider />
              <TextField
                variant="outlined"
                className="my-3"
                fullWidth
                label="Division name"
                defaultValue={data.name}
                name="name"
                required
                onChange={e => setData({ ...data, name: e.target.value })}
              />
              <TextField
                variant="outlined"
                className="my-3"
                fullWidth
                label="Description"
                defaultValue={data.description}
                name="description"
                required
                onChange={e => setData({ ...data, description: e.target.value })}
              />
              <TextField
                variant="outlined"
                className="my-3"
                fullWidth
                label="Order"
                defaultValue={data.order_number}
                name="order_number"
                required
                onChange={e => setData({ ...data, order_number: e.target.value })}
              />
              <Button
                type="submit"
                className="ml-auto d-block my-3"
                color="primary"
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            </Box>
          </Paper>
          <br />
        </fieldset>
      </form>
    </>
  );
}
