import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  TextField,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  Modal,
  Button
} from 'react-bootstrap'

import clsx from 'clsx';
import * as auth from '../../store/ducks/auth.duck';
import { login, login_employee } from '../../crud/auth.crud';
import { toAbsoluteUrl } from '../../../_metronic';
import { resendOtpAction, verifyOtp } from './services';
import Swal from 'sweetalert2'

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });
  const dispatch = useDispatch()

  const [verificationModal, setVerificationModal] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [loginData, setLoginData] = useState({})
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };
  const handleClose = () => setVerificationModal(false);


  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  const verify = async () => {

    await dispatch(verifyOtp(loginData, verificationCode))
      .then(data => {
        console.log(data, 'di login page')
        props.loginEmp(data.data)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Login verification failed',
          text: err.response.data,
          // footer: '<a href="">Why do I have this issue?</a>'
        })
        console.log(err.response, 'di login page errornya')
      })

  }

  const resendOtp = async () => {
    // console.log('disin ya?')

    await dispatch(resendOtpAction(loginData))
      .then(data => {
        setLoginData(data.data)
      })
      .catch(err => {
        console.log(err, ' ketika resend otp')
      })
  }

  return (
    <>
      <Modal backdrop="static" onHide={handleClose} show={verificationModal} aria-labelledby="contained-modal-title-vcenter"
      centered >
        <Modal.Header closeButton>
          <Modal.Title>Login Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Enter Verification Code
          </h4>
          <p>
            {`Verification code has been sent via email to ${loginData.email}` } 
          </p>
          <input type="text" onChange={(e) => setVerificationCode(e.target.value) } />
          <button className=" ml-2 btn btn-primary" onClick={() => verify()}>Verify</button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => resendOtp()}>
            Resend Verification Code
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="kt-login__head">
        {/* <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link> */}
      </div>

      <div className="kt-login__body loginform">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>Login Employeee</h3>
          </div>

          <Formik
            initialValues={{
              userName: '',
              password: '',
            }}
            validate={values => {
              const errors = {};

              if (!values.userName) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.userName = 'Enter an email.';
              }

              if (!values.password) {
                errors.password = 'Enter a password.';
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                login_employee(values.userName, values.password)
                  .then(data => {
                    disableLoading();
                    setLoginData(data.data.data)
                    setVerificationModal(true)

                    // props.loginEmp(data.data.data);
                  })
                  .catch((err) => {
                    console.log(err, 'isi errnya')
                    disableLoading();
                    setSubmitting(false);
                    setStatus('Incorrect Email or Password');
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : null
                // (
                //   // <div role="alert" className="alert alert-info">
                //   //   <div className="alert-text">
                //   //     Use account <strong>admin@example.com</strong> and password{" "}
                //   //     <strong>password</strong> to continue.
                //   //   </div>
                //   // </div>
                // )
                }

                <div className="form-group">
                  <TextField
                    type="text"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="userName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userName}
                    helperText={touched.userName && errors.userName}
                    error={Boolean(touched.userName && errors.userName)}
                  />
                </div>

                <div className="form-group">
                  <FormControl className="w-100">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      type={!seePass ? 'password' : 'text'}
                      InputAdornment
                      fullWidth
                      margin="normal"
                      label="Password"
                      className="kt-width-full w-100"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setSeePass(!seePass)}
                          >
                            {!seePass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>

                <div className="kt-login__actions">
                  <Link
                    to="/auth/forgot-password/employee"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    })}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>

          {/* <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div> */}

          {/* <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
