/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../store/ducks/auth.duck';

import {
  Box,
  Paper,
  Divider,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  IconButton,
  FormControlLabel,
  Modal,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Alert from '../../../shared/Alert';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import { getMenubar, postMenubar, deleteMenuBar } from '../service';

import _ from 'underscore';

/************************
 * SECTION Function for modal stylyng
 * RETURN @Object
 ************************/
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function Profile(props) {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [alteredData, setAlteredData] = useState({});
  const [postQuery, setPostQuery] = useState('');
  const [pageQuery, setPageQuery] = useState('');
  const [error, setError] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedNode, setSelectedNode] = useState([]);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  /************************
   * SECTION Initialize the Page
   * Modal States,
   * RETURN
   ************************/
  const [open, setOpen] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [selectedTree, setSelectedTree] = useState({});

  /************************
   * SECTION Initialize the Page
   * this set the title, available buttons,
   * and call the index endpoint for this page
   * RETURN @Object
   ************************/
  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-menubar')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-menubar')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-menubar')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-menubar')),
    };
    // Register the page metadata
    document.title = 'Menubar';
    await setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Menubar',
        hasMenu: false,
      })
    );

    // Fetch the Menubar Data, set The TreeData
    await props
      .dispatch(getMenubar())
      .then(data => {
        setData(data.data);
        setAlteredData(data.data.available);
        setTreeData(data.data.menubar);
      })
      .catch(error => setError(error));

    await setPerms(permData);
    await setLoading(false);
  }

  // Save the Entire TreeData
  async function submit(e) {
    await setSubmitLoading(true);
    await props
      .dispatch(postMenubar(treeData))
      .then(data => {
        setNotification({ type: 'success', ...data });
        setTimeout(() => window.location.reload(), 500);
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  // Call on Page Load Initiation
  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, [notification]);

  // Call the SweetAlert Everytime a notifictation appear.
  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  // Add Selected Item from Left List to the TreeData, and Empty currently selected
  async function addToTree() {
    await setTreeData([...treeData, ...selected]);
    await setSelected([]);
  }

  async function removeTree(e) {
    console.log(`remove tree id ${e}`);
    await setSubmitLoading(true);
    await props
      .dispatch(deleteMenuBar(e))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  function recursiveEditChild(par, target) {
    return _.map(par, e => {
      var newData = e;
      if (target.id == e.id) {
        newData = target;
      }
      return {
        ...newData,
        children: e.children ? recursiveEditChild(e.children, target) : [],
      };
    });
  }

  function recursiveEdits(target) {
    var data = _.map(treeData, e => {
      var newData = e;
      if (target.id == e.id) {
        newData = target;
      }
      return {
        ...newData,
        children: e.children ? recursiveEditChild(e.children, target) : [],
      };
    });
    return data;
  }

  function recursiveRemoveChild(par, target) {
    var data = [];
    par.forEach(e => {
      if (target.id != e.id) {
        data.push({
          ...e,
          children: e.children ? recursiveRemoveChild(e.children, target) : [],
        });
      }
    });
    return data;
  }

  function recursiveRemove(target) {
    var data = [];
    treeData.forEach(e => {
      if (target.id != e.id) {
        data.push({
          ...e,
          children: e.children ? recursiveRemoveChild(e.children, target) : [],
        });
      }
    });
    return data;
  }

  useEffect(() => {
    if (data.available) {
      async function search() {
        var postData = await _.filter(data.available[0], function(d) {
          return d.name.toLowerCase().includes(postQuery.toLowerCase());
        });
        var pageData = await _.filter(data.available[1], function(d) {
          return d.name.toLowerCase().includes(pageQuery.toLowerCase());
        });

        await setAlteredData([postData, pageData]);
        await setSelected([]);
      }
      search();
    }
  }, [postQuery, pageQuery]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  // Final Display
  return (
    <>
      <form>
        <fieldset disabled={submitLoading}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <List dense>
                  {alteredData.map((e, i) => (
                    <Box mb={1}>
                      <Paper>
                        <Box p={1}>
                          <Grid container>
                            <Grid item xs={3}>
                              <h4 style={{ margin: '10px' }}>{i == 0 ? 'Post' : 'Page'}</h4>
                            </Grid>
                            <Grid item xs={9}>
                              <h4>
                                {i == 0 ? (
                                  <TextField
                                    fullWidth
                                    className="menubar-search"
                                    variant="outlined"
                                    placeholder="Search Post"
                                    name="post_search"
                                    value={postQuery || ''}
                                    onChange={e => setPostQuery(e.target.value)}
                                  />
                                ) : (
                                  <TextField
                                    fullWidth
                                    className="menubar-search"
                                    variant="outlined"
                                    placeholder="Search Page"
                                    name="page_search"
                                    value={pageQuery || ''}
                                    onChange={e => setPageQuery(e.target.value)}
                                  />
                                )}
                              </h4>
                            </Grid>
                          </Grid>

                          <Divider />
                          <Box
                            style={{
                              height: 200,
                              overflowY: 'auto',
                            }}
                          >
                            {i == 0 ? (
                              <>
                                {'list of post'.includes(postQuery.toLowerCase()) ? (
                                  <ListItem>
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={Boolean(
                                          _.findWhere(selected, {
                                            content_id: 'list',
                                            type: i == 0 ? 'post' : 'page',
                                          })
                                        )}
                                        onChange={e => {
                                          var type = i == 0 ? 'post' : 'page';
                                          if (e.target.checked) {
                                            setSelected([
                                              ...selected,
                                              {
                                                id: Math.random()
                                                  .toString(36)
                                                  .substring(2, 15),
                                                content_id: 'list',
                                                title: `List of ${type}`,
                                                type: type,
                                                url: null,
                                                new_tab: false,
                                              },
                                            ]);
                                          } else {
                                            setSelected(
                                              _.filter(selected, nd => {
                                                return nd.content_id != 'list' && nd.type == type;
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={`List of ${i == 0 ? 'post' : 'page'}`} />
                                  </ListItem>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {'list of employee'.includes(pageQuery.toLowerCase()) ? (
                                  <ListItem>
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={Boolean(
                                          _.findWhere(selected, {
                                            content_id: 'listemp',
                                            type: 'listemp',
                                          })
                                        )}
                                        onChange={e => {
                                          var type = 'page';
                                          if (e.target.checked) {
                                            setSelected([
                                              ...selected,
                                              {
                                                id: Math.random()
                                                  .toString(36)
                                                  .substring(2, 15),
                                                content_id: 'listemp',
                                                title: `List of Employee`,
                                                type: 'listemp',
                                                url: null,
                                                new_tab: false,
                                              },
                                            ]);
                                          } else {
                                            setSelected(
                                              _.filter(selected, nd => {
                                                return (
                                                  nd.content_id != 'listemp' && nd.type == 'listemp'
                                                );
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={`List of Employee`} />
                                  </ListItem>
                                ) : null}
                                {'list of gallery'.includes(pageQuery.toLowerCase()) ? (
                                  <ListItem>
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={Boolean(
                                          _.findWhere(selected, {
                                            content_id: 'listgal',
                                            type: 'listgal',
                                          })
                                        )}
                                        onChange={e => {
                                          var type = 'page';
                                          if (e.target.checked) {
                                            setSelected([
                                              ...selected,
                                              {
                                                id: Math.random()
                                                  .toString(36)
                                                  .substring(2, 15),
                                                content_id: 'listgal',
                                                title: `List of Gallery`,
                                                type: 'listgal',
                                                url: null,
                                                new_tab: false,
                                              },
                                            ]);
                                          } else {
                                            setSelected(
                                              _.filter(selected, nd => {
                                                return (
                                                  nd.content_id != 'listgal' && nd.type == 'listgal'
                                                );
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={`List of Gallery`} />
                                  </ListItem>
                                ) : null}
                                {'list of job'.includes(pageQuery.toLowerCase()) ? (
                                  <ListItem>
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={Boolean(
                                          _.findWhere(selected, {
                                            content_id: 'listjob',
                                            type: 'listjob',
                                          })
                                        )}
                                        onChange={e => {
                                          var type = 'page';
                                          if (e.target.checked) {
                                            setSelected([
                                              ...selected,
                                              {
                                                id: Math.random()
                                                  .toString(36)
                                                  .substring(2, 15),
                                                content_id: 'listjob',
                                                title: `List of Job`,
                                                type: 'listjob',
                                                url: null,
                                                new_tab: false,
                                              },
                                            ]);
                                          } else {
                                            setSelected(
                                              _.filter(selected, nd => {
                                                return (
                                                  nd.content_id != 'listjob' && nd.type == 'listjob'
                                                );
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={`List of Job`} />
                                  </ListItem>
                                ) : null}
                                {'iso form'.includes(pageQuery.toLowerCase()) ? (
                                  <ListItem>
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={Boolean(
                                          _.findWhere(selected, {
                                            content_id: 'isoform',
                                            type: 'isoform',
                                          })
                                        )}
                                        onChange={e => {
                                          var type = 'isoform';
                                          if (e.target.checked) {
                                            setSelected([
                                              ...selected,
                                              {
                                                id: Math.random()
                                                  .toString(36)
                                                  .substring(2, 15),
                                                content_id: 'isoform',
                                                title: `ISO Center`,
                                                type: 'isoform',
                                                url: 'iso_center',
                                                new_tab: false,
                                              },
                                            ]);
                                          } else {
                                            setSelected(
                                              _.filter(selected, nd => {
                                                return (
                                                  nd.content_id != 'isoform' && nd.type == 'isoform'
                                                );
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={`ISO Center`} />
                                  </ListItem>
                                ) : null}
                              </>
                            )}

                            {e.map((x, y) => (
                              <ListItem>
                                <ListItemIcon>
                                  <Checkbox
                                    checked={Boolean(
                                      _.findWhere(selected, {
                                        content_id: x.id,
                                        type: i == 0 ? 'post' : 'page',
                                      })
                                    )}
                                    onChange={e => {
                                      var type = i == 0 ? 'post' : 'page';
                                      if (e.target.checked) {
                                        setSelected([
                                          ...selected,
                                          {
                                            id: Math.random()
                                              .toString(36)
                                              .substring(2, 15),
                                            content_id: x.id,
                                            title: x.name,
                                            type: type,
                                            url: null,
                                            new_tab: false,
                                          },
                                        ]);
                                      } else {
                                        setSelected(
                                          _.filter(selected, e => {
                                            return e.content_id != x.id && x.type == type;
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={x.name} />
                              </ListItem>
                            ))}
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  ))}
                </List>
              </Grid>
              <Grid item xs={8}>
                <Paper>
                  <div style={{ height: 400 }}>
                    <SortableTree
                      treeData={treeData}
                      onChange={treeData => setTreeData(treeData)}
                      onMoveNode={e => {
                        console.log(e.treeData, treeData);
                      }}
                      canDrag={perms.update}
                      generateNodeProps={extendedNode => ({
                        // onClick: event => {
                        //   console.log(event, extendedNode);
                        //   setSelectedTree(extendedNode.node);
                        // },
                        buttons: [
                          <>
                            {perms.delete ? (
                              <IconButton
                                aria-label="delete"
                                onClick={() => setTreeData(recursiveRemove(extendedNode.node))}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </>,
                          <>
                            {perms.update ? (
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  console.log(extendedNode);
                                  setSelectedTree(extendedNode.node);
                                  setOpen(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            ) : null}
                          </>,
                        ],
                      })}
                    />
                  </div>
                  <Box p={1} className="d-block pl-auto">
                    {perms.create ? (
                      <Button
                        disabled={selected.length == 0}
                        variant="outlined"
                        onClick={() => addToTree()}
                        className=""
                      >
                        Add to Tree
                      </Button>
                    ) : null}
                    {perms.update || perms.create ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => submit()}
                        className=""
                      >
                        Save
                      </Button>
                    ) : null}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </fieldset>
      </form>

      {/* Modal for editing menu values */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {selectedTree ? (
            <Box mb={1}>
              <Paper>
                <Box p={1}>
                  <TextField
                    variant="outlined"
                    className="my-3"
                    fullWidth
                    label="Title"
                    value={selectedTree.title || ''}
                    name="title"
                    required
                    onChange={e => setSelectedTree({ ...selectedTree, title: e.target.value })}
                  />
                  <TextField
                    variant="outlined"
                    className="my-3"
                    fullWidth
                    label="URL"
                    value={selectedTree.url || ''}
                    name="url"
                    placeholder="Default"
                    onChange={e => setSelectedTree({ ...selectedTree, url: e.target.value })}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(selectedTree.new_tab)}
                        onChange={e =>
                          setSelectedTree({
                            ...selectedTree,
                            new_tab: Boolean(e.target.checked),
                          })
                        }
                      />
                    }
                    label="Open in New Tab"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                      setTreeData(recursiveEdits(selectedTree));
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Paper>
            </Box>
          ) : null}
        </div>
      </Modal>
    </>
  );
}
