/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import HomePage from '../pages/home/HomePage';
import AuthPage from '../pages/auth/AuthPage';
import LoginEmployee from '../pages/auth/AuthPageEmployee';
import Forgot from '../pages/auth/ForgotPassword';
import ForgotEmployee from '../pages/auth/ForgotPasswordEmployee';
import Reset from '../pages/auth/ResetPassword';
import ResetEmployee from '../pages/auth/ResetPasswordEmployee';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import * as routerHelpers from '../router/RouterHelpers';

// NECHOME PAGES
import Landing from '../pages/home/NEC/landing';
import Homepage from '../pages/home/NEC/homepage';
import Page from '../pages/home/NEC/Page';
import Post from '../pages/home/NEC/Post';
import PostList from '../pages/home/NEC/post-list';

import Gallery from '../pages/home/NEC/Gallery';
import Job from '../pages/home/NEC/Job';
import Emp from '../pages/home/NEC/Emp';
import EmpDetail from '../pages/home/NEC/EmpDetail';
import Reqs from '../pages/home/NEC/Stationery';
import Profile from '../pages/home/NEC/Profile';
import Document from '../pages/home/NEC/Document';

export const Routes = withRouter(({ history }) => {
  var data;

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const redirectLogin = routerHelpers.getUrlRedirect();

  if (history.location.pathname == redirectLogin) {
    routerHelpers.forgotUrlRedirect();
  }

  const { isAuthorized, menuConfig, userLastLocation, userData } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userData: auth.user,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/auth/forgot-password" exact={true} component={Forgot} />
        <Route path="/auth/forgot-password/employee" exact={true} component={ForgotEmployee} />
        <Route path="/change/:token" exact={true} component={Reset} />
        <Route path="/change/:token/employee" exact={true} component={ResetEmployee} />
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Switch>
            <Route path="/auth/login" component={AuthPage} />
            <Route path="/auth/employee" component={LoginEmployee} />
            <Redirect from="/dashboard" to="/auth/login" />
            {window.sessionStorage.getItem('user_type') == 'employee' ? (
              <Redirect to="/auth/employee" />
            ) : null}
            {window.sessionStorage.getItem('user_type') == 'user' ? (
              <Redirect to="/auth/login" />
            ) : null}
            <Redirect from="/" to="/auth/employee" />
          </Switch>
        ) : redirectLogin ? (
          <Redirect from="/auth" to={redirectLogin} />
        ) : userData.type == 'employee' ? (
          <Redirect from="/auth" to="/" />
        ) : (
          <Redirect from="/auth" to="/dashboard" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {/* Prevent Employee from accessing dashbord */}
        {isAuthorized ? (
          history.location.pathname.includes('dashboard') && userData.type == 'user' ? (
            <Layout>
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          ) : (
            <Landing>
              <Switch>
                <Redirect from="/dashboard" to="/" />
                <Route exact={true} path="/" component={Homepage} />
                <Route exact={true} path="/post" component={PostList} />
                <Route exact={true} path="/post/:slug" component={Post} />
                <Route exact={true} path="/gallery" component={Gallery} />
                <Route exact={true} path="/jobs" component={Job} />
                <Route exact={true} path="/employee" component={Emp} />
                <Route exact={true} path="/employee/:id" component={EmpDetail} />
                <Route exact={true} path="/request" component={Reqs} />
                <Route exact={true} path="/profile" component={Profile} />
                <Route exact={true} path="/document" component={Document} />
                <Route exact={true} path="/:slug" component={Page} />
                <Redirect to="/error" />
              </Switch>
            </Landing>
          )
        ) : (
          <Redirect to="/auth/login" />
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
