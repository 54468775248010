import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { TableCell, TableRow, Checkbox, Chip, IconButton } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import _ from 'underscore';

export default function SelfRow({ data, selected, setSelected }) {
  var statusProc = active => {
    if (!active) {
      return <Chip label="Active" className="bg-info text-white" />;
    } else {
      return <Chip label="Inactive" className="bg-warning" />;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Checkbox
            value={data.id}
            checked={_.contains(selected, data.id)}
            onChange={async e => {
              if (!e.target.checked) {
                var finder = await _.without(selected, data.id);
                await setSelected(finder);
              } else {
                await setSelected([...selected, data.id]);
              }
            }}
          />
        </TableCell>
        <TableCell>{data.id}</TableCell>
        <TableCell align="center">
          <Link to={`/dashboard/banner/${data.id}`}>{data.name}</Link>
        </TableCell>
        <TableCell align="center">{data.number}</TableCell>
        <TableCell align="center">{data.link}</TableCell>
        <TableCell align="center">{statusProc(data.deletedAt)}</TableCell>
      </TableRow>
    </>
  );
}
