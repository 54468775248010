import React, { useState } from 'react';
import _ from 'underscore';
import 'react-dd-menu/dist/react-dd-menu.css';

import DDM from './ddMain';

export default function DD({ item }) {
  var data = _.chunk(item, Math.ceil(item.length / 2));
  console.log(data);
  return (
    <>
      {data[0]
        ? data[0].map(menu => {
            return <DDM item={menu} align="left" />;
          })
        : null}
      {data[1]
        ? data[1].map(menu => {
            return <DDM item={menu} align="right" />;
          })
        : null}
    </>
  );
}
