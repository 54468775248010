import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { activityIndex } from './admin/service';
import { index } from './admin/post/service';
import * as auth from '../../store/ducks/auth.duck';

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import _ from 'underscore';

function Dashboard(props) {
  const [login, setLogin] = useState([]);
  const [loginMeta, setLoginMeta] = useState({
    page: 1,
    per_page: 1000,
    type: '',
  });
  const [totalLoginPage, setTotalLoginPage] = useState(0);
  const [totalLoginItem, setTotalLoginItem] = useState(0);
  const [postTotal, setPostTotal] = useState(0);

  async function intializeData() {
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Activity Trail',
        // newLink: '/dashboard/file/create',
        // deleteLink: hl => destroyDispatch(hl),
        // activateLink: hl => activateDispatch(hl),
        // deactivateLink: hl => deactivateDispatch(hl),
        hasMenu: false,
      })
    );
    await props
      .dispatch(
        activityIndex(
          `?page=${loginMeta.page}&per_page=${loginMeta.per_page}&type=${loginMeta.type}`
        )
      )
      .then(data => {
        setLogin(data.data.data);
        setTotalLoginPage(data.meta.total_page);
        setTotalLoginItem(data.meta.total_item);
      })
      .catch(error => console.log(error));
    await props
      .dispatch(index(''))
      .then(data => {
        setPostTotal(data.meta.total_item);
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    async function deploy() {
      await intializeData();
    }
    deploy();
  }, []);

  return (
    <>
      <Paper>
        <Box mb={2} p={2}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">Commited By</TableCell>
                <TableCell align="center">Affected Model/ID</TableCell>
                <TableCell align="center">Action Type</TableCell>
                <TableCell align="center">Action Log</TableCell>
                <TableCell align="center">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {login.map((e, i) => {
                return (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">
                      {e.userType} : {e.userName}
                    </TableCell>
                    <TableCell align="center">
                      {e.modelAffected}/{e.modelID}
                    </TableCell>
                    <TableCell align="center">{e.actionType}</TableCell>
                    <TableCell align="center">{e.actionDesc}</TableCell>
                    <TableCell align="center">{e.createdAt}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </>
  );
}

export default connect(null)(Dashboard);
