/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { getPosts, getCategories } from '../service';
import Select from 'react-select';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [cats, setCats] = useState(null);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 4,
    keyword: '',
    category: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [featureLoading, setFeaturedLoading] = useState(false);

  /************************
   * SECTION Data get
   * RETURN @Object
   ************************/
  async function initializeData() {
    var query = `?page=${meta.page}&per_page=${meta.per_page}&category=${meta.category}&keyword=${meta.keyword}`;
    await props
      .dispatch(getPosts(query))
      .then(data => {
        setData(data.data);
        setTotalPage(data.meta.total_page);
      })
      .catch(error => console.log(error));
    await props
      .dispatch(getCategories())
      .then(data => {
        setCats(data);
      })
      .catch(error => console.log(error));
  }

  /************************
   * SECTION Initial Loading
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, []);

  /************************
   * SECTION Filter Loading
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await initializeData();
      await window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    deploy();
  }, [meta]);

  function pagination(limit) {
    var items = [];
    for (let index = 1; index <= limit; index++) {
      items.push(
        <span
          onClick={() => setMeta({ ...meta, page: index })}
          className={`pagination-item ${index == meta.page ? 'active' : ''}`}
        >
          {index}
        </span>
      );
    }
    return items;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="list-page">
      <Select
        placeholder="Select a category"
        className="react-select-container"
        classNamePrefix="react-select"
        isClearable={true}
        options={cats}
        onChange={x => setMeta({ ...meta, category: x ? x.value : '' })}
      />

      {data.length ? (
        data.map(e => {
          return (
            <div className="item">
              <Link className="main" to={`/post/${e.slug}`}>
                <div className="text">
                  <div className="main_text">{e.name}</div>
                  <p className="secondary_text">{e.excerpt}</p>

                  <Link className="link" to={`/post/${e.slug}`}>
                    READ FULL STORY
                  </Link>
                </div>
              </Link>
              <div className="thumb">
                <img src={e.thumbnail_url} />
              </div>
            </div>
          );
        })
      ) : (
        <div className="no_post">No Post Found</div>
      )}

      <div className="post-pagination">
        {meta.page > 1 ? (
          <span
            onClick={() => setMeta({ ...meta, page: meta.page - 1 })}
            className={`pagination-item`}
          >
            {'<'}
          </span>
        ) : null}
        {pagination(totalPage)}
        {totalPage > meta.page ? (
          <span
            onClick={() => setMeta({ ...meta, page: meta.page + 1 })}
            className={`pagination-item`}
          >
            {'>'}
          </span>
        ) : null}
      </div>
    </div>
  );
}
