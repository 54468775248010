import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPage } from '../../service';
import _ from 'underscore';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'; // Import Simple React Lightbox

export default function Page(props) {
  const [data, setData] = useState(null);
  const [activeImage, setActiveImage] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    setData(props.data);
    setActiveImage({
      image: props.data.content.images.length ? props.data.content.images[0].thumbnail_url : null,
      caption: props.data.content.images.length ? props.data.content.images[0].altText : null,
    });
    setImages({ active: 0, data: _.chunk(props.data.content.images, 10) });
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.data]);

  /************************
   * SECTION Logic for Change active image
   * RETURN @Object
   ************************/
  function changeImage(item) {
    setActiveImage({
      image: item.thumbnail_url,
      caption: item.altText,
    });
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  /************************
   * SECTION Render the bullets
   * RETURN @Object
   ************************/
  function imagesPages() {
    var bullet = [];
    for (let index = 0; index < images.data.length; index++) {
      bullet.push(
        <div
          onClick={() => setImages({ ...images, active: index })}
          className={`bullet ${index == images.active ? 'active' : ''}`}
        />
      );
    }
    return bullet;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="gallery_detail">
      <SimpleReactLightbox>
        <div className="gallery_content">
          <p className="title">{data.name}</p>
          <div className="active_image">
            <SRLWrapper>
              <img className="image" alt={activeImage.caption} src={activeImage.image} />
              <div className="d-none">
                {_.map(images.data, mitem => {
                  return _.map(mitem, item => {
                    if (activeImage.image != item.thumbnail_url) {
                      return <img src={item.thumbnail_url} alt={item.caption} />;
                    }
                  });
                })}
              </div>
            </SRLWrapper>
            <p className="caption">{activeImage.caption}</p>
          </div>
          <div className="available_images">
            <div className="image_block">
              {_.map(images.data[images.active], item => (
                <div className="image_item" onClick={() => changeImage(item)}>
                  <img src={item.thumbnail_url} alt={item.caption} />
                </div>
              ))}
            </div>
            <div className="navigation">{imagesPages()}</div>
          </div>
        </div>
        <div className="gallery_navigation">
          {data.previous_page ? (
            <Link to={`${data.previous_page.slug}`} className="left">
              Previous Gallery
            </Link>
          ) : null}
          {data.next_page ? (
            <Link to={`${data.next_page.slug}`} className="right">
              {' '}
              Next Gallery
            </Link>
          ) : null}
        </div>
      </SimpleReactLightbox>
    </div>
  );
}
