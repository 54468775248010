/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';

import {
  Box,
  Paper,
  Divider,
  Button,
  TextField,
  Checkbox,
  Grid,
  FormControlLabel,
} from '@material-ui/core';
import Alert from '../../../../shared/Alert';

import { insert, getMenuPerms } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [selected, setSelected] = useState([]);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [perms, setPerms] = useState({});

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-role')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-role')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-role')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-role')),
    };
    // Register the page metadata
    document.title = 'Create Role';
    setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Create Role',
        hasMenu: false,
      })
    );
    await props.dispatch(getMenuPerms()).then(i => setData(i));

    await setPerms(permData);
    await setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();
    var form = new FormData(e.target);
    form.append('perms', selected.toString());
    await setSubmitLoading(true);
    await props
      .dispatch(insert(form))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification, props, 'role');
    }
  }, [notification]);

  function handleChange(e) {
    console.log(e);
  }

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }
  if (perms.create) {
    // Final Display
    return (
      <>
        <form autoComplete="off" encType="multipart/form-data" onSubmit={e => submit(e)}>
          <fieldset disabled={submitLoading}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>New Role</h4>
                <Divider />
                <TextField
                  variant="outlined"
                  className="my-3"
                  fullWidth
                  label="Role name"
                  name="name"
                  required
                />
                {data.map(page => (
                  <Grid container>
                    <Grid xs={4}>{page.title}</Grid>
                    {page.perms.map(perm => (
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={_.find(selected, perm.name)}
                              onChange={e => {
                                if (e.target.checked) {
                                  setSelected([...selected, perm.name]);
                                } else {
                                  setSelected(
                                    _.filter(selected, e => {
                                      return e != perm.name;
                                    })
                                  );
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={perm.label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ))}
                <Button
                  type="submit"
                  className="ml-auto d-block my-3"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
