import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Input,
  InputAdornment,
  Grid,
} from '@material-ui/core';

import { ChevronLeft, ChevronRight, Sort, Search } from '@material-ui/icons';

import Row from './SelfRow';
import _ from 'underscore';

export default function SelfTable({
  data,
  meta,
  setMeta,
  totalPage,
  totalItem,
  selected,
  setSelected,
}) {
  const [pageMenu, setPageMenu] = useState(null);
  const [filterMenu, setFilterMenu] = useState(null);

  return (
    <div>
      <Paper>
        <Box p={3}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item xs={3}>
              <Input
                id="standard-adornment-password"
                className="float-right"
                defaultValue={meta.keyword}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
                onChange={event => {
                  setMeta({ ...meta, keyword: event.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '50px' }}>
                  <Checkbox
                    checked={selected.length == data.length}
                    onChange={e => {
                      if (selected.length == data.length) {
                        setSelected([]);
                      } else {
                        setSelected(_.pluck(data, 'id'));
                      }
                    }}
                  />
                </TableCell>
                <TableCell style={{ width: '100px', cursor: 'pointer' }}>ID</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((e, i) => {
                return <Row data={e} key={i} selected={selected} setSelected={setSelected} />;
              })}
            </TableBody>
          </Table>
        </Box>
        <Box p={2} className="align-content-end">
          Rows per Page :
          <Button className="mx-3" size="small" onClick={e => setPageMenu(e.currentTarget)}>
            {meta.per_page}
          </Button>
          <Menu
            anchorEl={pageMenu}
            keepMounted
            open={Boolean(pageMenu)}
            onClose={() => setPageMenu(null)}
          >
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 10 });
              }}
            >
              10
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 20 });
              }}
            >
              20
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 50 });
              }}
            >
              50
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 100 });
              }}
            >
              100
            </MenuItem>
          </Menu>
          <span className="mx-3">
            {' '}
            Page {meta.page} of {totalPage}{' '}
          </span>
          <IconButton
            className="mx-3"
            size="small"
            disabled={meta.page < 2}
            onClick={() =>
              setMeta({
                ...meta,
                page: meta.page - 1,
              })
            }
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            className="mx-3"
            size="small"
            disabled={meta.page == totalPage}
            onClick={() =>
              setMeta({
                ...meta,
                page: meta.page + 1,
              })
            }
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Paper>
    </div>
  );
}
