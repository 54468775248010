import React, { useState, useEffect } from 'react';
import { requestStat } from '../service';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import Select from 'react-select';
import { Add, Remove } from '@material-ui/icons';
import moment from 'moment';
import { TextField, IconButton, Button } from '@material-ui/core';

import Alert from '../../../shared/Alert';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(null);
  const [content, setContent] = useState([
    {
      uid: Math.round(new Date().getTime() / 1000),
      name: '',
      value: '',
      quantity: '',
      unit: '',
      remarks: '',
    },
  ]);

  const options = [
    { label: `Automatic Pencil`, value: `Automatic Pencil`, unit: 'pcs' },
    { label: `Amplop A4/Brown`, value: `Amplop A4/Brown`, unit: 'pcs' },
    { label: `Amplop Jaya Blank`, value: `Amplop Jaya Blank`, unit: 'box' },
    { label: `Battery Alakalin Size AA`, value: `Battery Alakalin Size AA`, unit: 'set' },
    { label: `Battery Alkalin Size AAA`, value: `Battery Alkalin Size AAA`, unit: 'set' },
    { label: `Battery Alkalin 9volt`, value: `Battery Alkalin 9volt`, unit: 'pcs' },
    { label: `Bolp. BPTP/Black "Pilot"`, value: `Bolp. BPTP/Black "Pilot"`, unit: 'pcs' },
    { label: `Bolp. BPTP/Blue "Pilot"`, value: `Bolp. BPTP/Blue "Pilot"`, unit: 'pcs' },
    { label: `Bolp. BPTP/Red "Pilot"`, value: `Bolp. BPTP/Red "Pilot"`, unit: 'pcs' },
    { label: `Boxy/Black "Mitsubishi"`, value: `Boxy/Black "Mitsubishi"`, unit: 'pcs' },
    { label: `Boxy/Blue "Mitsubishi"`, value: `Boxy/Blue "Mitsubishi"`, unit: 'pcs' },
    { label: `Boxy/Red "Mitsubishi"`, value: `Boxy/Red "Mitsubishi"`, unit: 'pcs' },
    {
      label: `Binder Clips No.105 "Swallow / Kenko"`,
      value: `Binder Clips No.105 "Swallow / Kenko"`,
      unit: 'Box/12dos',
    },
    {
      label: `Binder Clips No.107 "Swallow / Kenko"`,
      value: `Binder Clips No.107 "Swallow / Kenko"`,
      unit: 'Box/12dos',
    },
    {
      label: `Binder Clips No.111 "swallow / Kenko"`,
      value: `Binder Clips No.111 "swallow / Kenko"`,
      unit: 'Box/12dos',
    },
    {
      label: `Binder Clips No.155 "Swallow / Kenko"`,
      value: `Binder Clips No.155 "Swallow / Kenko"`,
      unit: 'Box/12dos',
    },
    {
      label: `Binder Clips No.200 "Swallow/ Kenko"`,
      value: `Binder Clips No.200 "Swallow/ Kenko"`,
      unit: 'dos',
    },
    {
      label: `Binder Clips N0.260 "Swallow / Kenko"`,
      value: `Binder Clips N0.260 "Swallow / Kenko"`,
      unit: 'dos',
    },
    { label: `Book DVD Room`, value: `Book DVD Room`, unit: 'pcs' },
    { label: `Book CD Room`, value: `Book CD Room`, unit: 'pcs' },
    { label: `Cover Buffalo Cream`, value: `Cover Buffalo Cream`, unit: 'lbr' },
    { label: `Cover Buffalo Pink`, value: `Cover Buffalo Pink`, unit: 'lbr' },
    { label: `Cover Buffalo Blue`, value: `Cover Buffalo Blue`, unit: 'lbr' },
    { label: `Ink Jet Paper 1440 MM2NA`, value: `Ink Jet Paper 1440 MM2NA`, unit: 'pak' },
    { label: `Ink Jet Paper 1440 MM2A`, value: `Ink Jet Paper 1440 MM2A`, unit: 'pak' },
    { label: `Corecting Tape (lit off)`, value: `Corecting Tape (lit off)`, unit: 'pcs' },
    { label: `Carbon Hologram`, value: `Carbon Hologram`, unit: 'pcs' },
    {
      label: `Carbon C "Fulmark" Pita mesin tik`,
      value: `Carbon C "Fulmark" Pita mesin tik`,
      unit: 'pcs',
    },
    { label: `Catter A 300 "Kenko"`, value: `Catter A 300 "Kenko"`, unit: 'pcs' },
    { label: `Cutter L 500 "Kenko"`, value: `Cutter L 500 "Kenko"`, unit: 'pcs' },
    { label: `Calculator SDC868L`, value: `Calculator SDC868L`, unit: 'pcs' },
    { label: `Ear Map 6001`, value: `Ear Map 6001`, unit: 'pcs' },
    { label: `Eraser Pencil "Steadlear"`, value: `Eraser Pencil "Steadlear"`, unit: 'pcs' },
    { label: `Eraser f/White Board`, value: `Eraser f/White Board`, unit: 'pcs' },
    { label: `Expedition Book`, value: `Expedition Book`, unit: 'pcs' },
    { label: `Folder File 5002/Blue`, value: `Folder File 5002/Blue`, unit: 'pcs' },
    { label: `Folder File 5001/Red`, value: `Folder File 5001/Red`, unit: 'pcs' },
    { label: `Folder File 5002/Red`, value: `Folder File 5002/Red`, unit: 'pcs' },
    { label: `Folder File 5002/Green`, value: `Folder File 5002/Green`, unit: 'pcs' },
    { label: `Folder File 5001/Grey`, value: `Folder File 5001/Grey`, unit: 'pcs' },
    { label: `Folio Book`, value: `Folio Book`, unit: 'pcs' },
    { label: `Faxcmile Paper 210x30`, value: `Faxcmile Paper 210x30`, unit: 'roll' },
    { label: `Faxcmile Paper 210x50`, value: `Faxcmile Paper 210x50`, unit: 'roll' },
    { label: `Glue Pritt Medium 10 gr`, value: `Glue Pritt Medium 10 gr`, unit: 'psc' },
    { label: `Glue Pritt Medium 20 gr`, value: `Glue Pritt Medium 20 gr`, unit: 'pcs' },
    { label: `Glue Pritt Jumbo 40gr`, value: `Glue Pritt Jumbo 40gr`, unit: 'pcs' },
    { label: `Lem Cair`, value: `Lem Cair`, unit: 'pcs' },
    { label: `Hanging Map Lihit`, value: `Hanging Map Lihit`, unit: 'pcs' },
    { label: `Hermaring Tixo`, value: `Hermaring Tixo`, unit: 'pcs' },
    { label: `Isolasi Doubke Tape 1/2''`, value: `Isolasi Doubke Tape 1/2''`, unit: 'roll' },
    { label: `Isolasi 1/2 x 10 `, value: `Isolasi 1/2 x 10 `, unit: 'roll' },
    { label: `Isolasi 1/2 x 3G`, value: `Isolasi 1/2 x 3G`, unit: 'roll' },
    { label: `Kwitansi Form`, value: `Kwitansi Form`, unit: 'pcs' },
    { label: `Label Tom&Jerry 101`, value: `Label Tom&Jerry 101`, unit: 'set' },
    { label: `Label Tom&Jerry 121`, value: `Label Tom&Jerry 121`, unit: 'set' },
    { label: `Label Tom&Jerry 109`, value: `Label Tom&Jerry 109`, unit: 'set' },
    { label: `Label Tom&Jerry 113`, value: `Label Tom&Jerry 113`, unit: 'set' },
    { label: `Letter Tray 2 Deck`, value: `Letter Tray 2 Deck`, unit: 'pcs' },
    {
      label: `Lackband Kain Hitam Daimaru 2'' `,
      value: `Lackband Kain Hitam Daimaru 2'' `,
      unit: 'roll',
    },
    {
      label: `Lackband Kain Hitam Daimaru 1'' `,
      value: `Lackband Kain Hitam Daimaru 1'' `,
      unit: 'roll',
    },
    {
      label: `Lackband Palsik Bening Daimaru 2''     `,
      value: `Lackband Palsik Bening Daimaru 2''     `,
      unit: 'roll',
    },
    {
      label: `Lackband  Coklat  Daimaru 2'' `,
      value: `Lackband  Coklat  Daimaru 2'' `,
      unit: 'roll',
    },
    { label: `Magnetic White Board`, value: `Magnetic White Board`, unit: 'pcs' },
    { label: `Name Card Case`, value: `Name Card Case`, unit: 'pcs' },
    { label: `Name Card book`, value: `Name Card book`, unit: 'pcs' },
    { label: `Pentel Tip Ex Pentel`, value: `Pentel Tip Ex Pentel`, unit: 'tub' },
    { label: `Pencil Case "Nakoya"`, value: `Pencil Case "Nakoya "`, unit: 'pcs' },
    { label: `Pencil Lumograp 2B`, value: `Pencil Lumograp 2B`, unit: 'pcs' },
    { label: `Pencil Lumograp HB`, value: `Pencil Lumograp HB`, unit: 'pcs' },
    { label: `Punch No. 30`, value: `Punch No. 30`, unit: 'pcs' },
    { label: `Punch No. 85`, value: `Punch No. 85`, unit: 'pcs' },
    { label: `Partisi Paper Yellow`, value: `Partisi Paper Yellow`, unit: 'pak' },
    { label: `Partisi Paper Green`, value: `Partisi Paper Green`, unit: 'pak' },
    { label: `Partisi Paper Pink`, value: `Partisi Paper Pink`, unit: 'pak' },
    { label: `Partisi Paper Blue`, value: `Partisi Paper Blue`, unit: 'pak' },
    { label: `Plastic Folder Eagle`, value: `Plastic Folder Eagle`, unit: 'pcs' },
    { label: `Plastic Durable IMCO`, value: `Plastic Durable IMCO`, unit: 'pcs' },
    { label: `Plastik Busines File A4 `, value: `Plastik Busines File A4 `, unit: 'pcs' },
    { label: `Plastik Busines File F4`, value: `Plastik Busines File F4`, unit: 'pcs' },
    { label: `Pocket Trans.Folio`, value: `Pocket Trans.Folio`, unit: 'pcs' },
    { label: `Pocket Trans. A4`, value: `Pocket Trans. A4`, unit: 'pcs' },
    { label: `Push Pin Colour`, value: `Push Pin Colour`, unit: 'set' },
    { label: `Plastic Ruller 30 cm`, value: `Plastic Ruller 30 cm`, unit: 'pcs' },
    { label: `Plastic Ruller 50 cm`, value: `Plastic Ruller 50 cm`, unit: 'pcs' },
    { label: `Parker Ink Black`, value: `Parker Ink Black`, unit: 'pcs' },
    { label: `Post It 680G (SH)`, value: `Post It 680G (SH)`, unit: 'pcs' },
    { label: `Post It 653`, value: `Post It 653`, unit: 'pcs' },
    { label: `Post It 654`, value: `Post It 654`, unit: 'pcs' },
    { label: `Post It 655 `, value: `Post It 655 `, unit: 'pcs' },
    { label: `Post It Color`, value: `Post It Color`, unit: 'set' },
    {
      label: `Photo Paper 269 UM "Mitsubishi Chemical A4"`,
      value: `Photo Paper 269 UM "Mitsubishi Chemical A4"`,
      unit: 'pcs',
    },
    { label: `Plastic Mika`, value: `Plastic Mika`, unit: 'pcs' },
    { label: `Ribbon Calc. Casio`, value: `Ribbon Calc. Casio`, unit: 'pcs' },
    {
      label: `Roll Paper Telstruk (58mm x 48m)`,
      value: `Roll Paper Telstruk (58mm x 48m)`,
      unit: 'roll',
    },
    {
      label: `Roll Paper Telstruk( 68 mm X 48 )`,
      value: `Roll Paper Telstruk( 68 mm X 48 )`,
      unit: 'roll',
    },
    { label: `Refil Cutter A 300`, value: `Refil Cutter A 300`, unit: 'tub' },
    { label: `Refil Cutter L 500`, value: `Refil Cutter L 500`, unit: 'tub' },
    { label: `Remover`, value: `Remover`, unit: 'pcs' },
    { label: `Sign Book Bantex 5597`, value: `Sign Book Bantex 5597`, unit: 'book' },
    { label: `Spidol Art Line 70 Black`, value: `Spidol Art Line 70 Black`, unit: 'pcs' },
    { label: `Spidol Art Line 70 Blue`, value: `Spidol Art Line 70 Blue`, unit: 'pcs' },
    { label: `Spidol Art Line 70 Red`, value: `Spidol Art Line 70 Red`, unit: 'pcs' },
    { label: `Spidol Art Line 500 Black`, value: `Spidol Art Line 500 Black`, unit: 'pcs' },
    { label: `Spidol Art Line 500 Blue`, value: `Spidol Art Line 500 Blue`, unit: 'pcs' },
    { label: `Spidol Art Line 500 Red`, value: `Spidol Art Line 500 Red`, unit: 'pcs' },
    { label: `Spidol Snowman `, value: `Spidol Snowman `, unit: 'pcs' },
    { label: `Spiral Binder`, value: `Spiral Binder`, unit: 'pcs' },
    { label: `Stabillo Boss Blue`, value: `Stabillo Boss Blue`, unit: 'pcs' },
    { label: `Stabillo Boss Green`, value: `Stabillo Boss Green`, unit: 'pcs' },
    { label: `Stabillo Boss Yellow`, value: `Stabillo Boss Yellow`, unit: 'pcs' },
    { label: `Stabillo Boss Pink`, value: `Stabillo Boss Pink`, unit: 'pcs' },
    { label: `Stabillo Boss Orange`, value: `Stabillo Boss Orange`, unit: 'pcs' },
    { label: `Standing Book`, value: `Standing Book`, unit: 'set' },
    { label: `Stainless Ruller 30 cm`, value: `Stainless Ruller 30 cm`, unit: 'pcs' },
    { label: `Stamp Pad Medium no 1`, value: `Stamp Pad Medium no 1`, unit: 'pcs' },
    { label: `Stamp Ink Red`, value: `Stamp Ink Red`, unit: 'btl' },
    { label: `Stamp Ink Black`, value: `Stamp Ink Black`, unit: 'btl' },
    { label: `Scotch Magic Tape 3/4"`, value: `Scotch Magic Tape 3/4"`, unit: 'roll' },
    { label: `Scissor Medium`, value: `Scissor Medium`, unit: 'pcs' },
    { label: `Scissor Big`, value: `Scissor Big`, unit: 'pcs' },
    { label: `Stapler Max No. 10`, value: `Stapler Max No. 10`, unit: 'pcs' },
    { label: `Stapler Max No. 30`, value: `Stapler Max No. 30`, unit: 'pcs' },
    {
      label: `Stapler HD12 L/17 (di ruang Foto Copy)`,
      value: `Stapler HD12 L/17 (di ruang Foto Copy)`,
      unit: 'pcs',
    },
    { label: `Stapless No.10`, value: `Stapless No.10`, unit: 'dos' },
    { label: `Stapless No.3`, value: `Stapless No.3`, unit: 'dos' },
    { label: `Stapless 1210`, value: `Stapless 1210`, unit: 'dos' },
    { label: `Stapless 1213`, value: `Stapless 1213`, unit: 'dos' },
    { label: `Stapless 1215`, value: `Stapless 1215`, unit: 'dos' },
    { label: `Stapless 1217`, value: `Stapless 1217`, unit: 'dos' },
    { label: `Sharpener Engel 5`, value: `Sharpener Engel 5`, unit: 'pcs' },
    { label: `Telp Address Book`, value: `Telp Address Book`, unit: 'book' },
    { label: `Tip Ex Set`, value: `Tip Ex Set`, unit: 'set' },
    { label: `Tinner Tip Ex`, value: `Tinner Tip Ex`, unit: 'btl' },
    { label: `Trigonal Clips No.1`, value: `Trigonal Clips No.1`, unit: 'dos' },
    { label: `Trigonal Clips No.3`, value: `Trigonal Clips No.3`, unit: 'dos' },
    { label: `Thermal Pencil`, value: `Thermal Pencil`, unit: 'tub' },
    { label: `Transparancy Yasica A4`, value: `Transparancy Yasica A4`, unit: 'pcs' },
    { label: `Transparan HP51636G`, value: `Transparan HP51636G`, unit: 'pcs' },
    { label: `Writting Pad`, value: `Writting Pad`, unit: 'book' },
    { label: `Thoner CE 85A`, value: `Thoner CE 85A`, unit: 'pcs' },
    { label: `Flipchart Board 60x90`, value: `Flipchart Board 60x90`, unit: 'pcs' },
    { label: `Kertas Flipchart HVS`, value: `Kertas Flipchart HVS`, unit: 'pcs' },
    { label: `Paper Concord 160gr A4 80324`, value: `Paper Concord 160gr A4 80324`, unit: 'pcs' },
    { label: `Whiteboard`, value: `Whiteboard`, unit: 'pcs' },
    {
      label: `Battery ABC Sedang R-14 (Box/24 Pcs)`,
      value: `Battery ABC Sedang R-14 (Box/24 Pcs)`,
      unit: 'pcs',
    },
    { label: `Punch Kenko No.30`, value: `Punch Kenko No.30`, unit: 'pcs' },
    { label: `Signature Book ELBA E41403`, value: `Signature Book ELBA E41403`, unit: 'pcs' },
    {
      label: `Glossy Photo A4 220 GR Data Print`,
      value: `Glossy Photo A4 220 GR Data Print`,
      unit: 'pcs',
    },
    { label: `Amplop A4 NEC Cetak`, value: `Amplop A4 NEC Cetak`, unit: 'pcs' },
    { label: `Tack All Bantex 8205`, value: `Tack All Bantex 8205`, unit: 'box' },
    { label: `Karton Duplex`, value: `Karton Duplex`, unit: 'Lbr' },
    { label: `Tom & Jerry 112`, value: `Tom & Jerry 112`, unit: 'set' },
    { label: `Lakban Kuning`, value: `Lakban Kuning`, unit: 'pcs' },
    { label: `Rautan Pensil`, value: `Rautan Pensil`, unit: 'pcs' },
    { label: `Battery ABC Sedang R-14 `, value: `Battery ABC Sedang R-14 `, unit: 'pcs' },
    { label: `Amplop A4 NEC Cetak`, value: `Amplop A4 NEC Cetak`, unit: 'pcs' },
  ];

  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {}
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
      setContent([]);
    }
  }, [notification]);

  if (loading) {
    return null;
  }

  function setItems(value, key, uid, special = false) {
    var allExcept = _.filter(content, function(item) {
      return item.uid != uid;
    });
    var self = _.findWhere(content, { uid: uid });
    console.log(self);
    if (special) {
      setContent(
        _.sortBy(
          [...allExcept, { ...self, [key]: value.label, value: value.value, unit: value.unit }],
          'uid'
        )
      );
    } else {
      setContent(_.sortBy([...allExcept, { ...self, [key]: value }], 'uid'));
    }
  }

  async function submit(e) {
    e.preventDefault();
    var data = new FormData(e.target);
    data.append('content', JSON.stringify(content));
    await props
      .dispatch(requestStat(data))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  function addItem() {
    if (_.findWhere(content, { uid: Math.round(new Date().getTime() / 1000) })) {
      setTimeout(() => {
        setContent([
          ...content,
          {
            uid: Math.round(new Date().getTime() / 1000),
            name: '',
            value: '',
            quantity: '',
            unit: '',
            remarks: '',
          },
        ]);
      }, 500);
    } else {
      setContent([
        ...content,
        {
          uid: Math.round(new Date().getTime() / 1000),
          name: '',
          value: '',
          quantity: '',
          unit: '',
          remarks: '',
        },
      ]);
    }
  }

  function removeItem(id) {
    var allExcept = _.filter(content, function(item) {
      return item.uid != id;
    });
    setContent(_.sortBy([...allExcept], 'uid'));
  }

  if (props.data.type != 'employee') {
    return null;
  }

  return (
    <div className="request_page">
      <div className="header_top">STATIONARY REQUISITION FORM</div>
      <form onSubmit={e => submit(e)}>
        <div className="request_form">
          <div className="form_req_title"> Name </div>
          <TextField className="form_req_input" disabled value={props.data.fullname} />

          <div className="form_req_title"> Department </div>
          <TextField
            className="form_req_input"
            disabled
            value={props.data.departement ? props.data.departement.name : ''}
          />

          <div className="form_req_title"> Division </div>
          <TextField
            className="form_req_input"
            disabled
            value={props.data.division ? props.data.division.name : ''}
          />
          <div className="form_req_title"> Requested of Date </div>
          <TextField
            className="form_req_input"
            disabled
            value={moment(new Date()).format('llll')}
          />
        </div>
        <TextField
          className="form_req_input"
          name="division_id"
          type="hidden"
          value={props.data.division ? props.data.division.id : ''}
        />
        <TextField
          className="form_req_input"
          name="departement_id"
          type="hidden"
          value={props.data.departement ? props.data.departement.id : ''}
        />{' '}
        <TextField
          className="form_req_input"
          name="employee_id"
          type="hidden"
          value={props.data.id}
        />
        <br />
        <div className="header_stationeries">
          <div className="rows_stat">
            <div>No.</div>
            <div>Name of Item</div>
            <div>Quantity</div>
            <div>Unit</div>
            <div>Remarks</div>
            <div>Remove</div>
            <div>Add</div>
          </div>

          <div className="rows_stat_select">
            {content.length ? (
              content.map((e, i) => (
                <>
                  <div className="index">{i + 1}</div>
                  <Select
                    required
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={_.findWhere(options, { label: e.name })}
                    options={options}
                    onChange={x => setItems(x, 'name', e.uid, true)}
                  />
                  <TextField
                    required
                    type="number"
                    value={e.quantity}
                    onChange={x => setItems(x.target.value, 'quantity', e.uid)}
                  />
                  <TextField required value={e.unit} disabled />

                  <TextField
                    // required
                    type="text"
                    value={e.remarks}
                    onChange={x => setItems(x.target.value, 'remarks', e.uid)}
                  />
                  <div>
                    <IconButton onClick={() => removeItem(e.uid)}>
                      <Remove />
                    </IconButton>
                    <TextField
                      className="position novision no-vision"
                      type="hidden"
                      required
                      value={e.unit}
                      readOnly
                    />
                  </div>
                  {i + 1 == content.length ? (
                    <IconButton onClick={addItem}>
                      <Add />
                    </IconButton>
                  ) : (
                    <div />
                  )}
                </>
              ))
            ) : (
              <>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <IconButton onClick={addItem}>
                  <Add />
                </IconButton>
              </>
            )}
          </div>
        </div>
        <Button type="submit" className="saveButton">
          Submit
        </Button>
      </form>
    </div>
  );
}
