/**
 * PART: Imports
 *
 */
import React, { useState, useEffect } from 'react';
import * as auth from '../../../../../store/ducks/auth.duck';
import _ from 'underscore';

import { getStationeryRequests } from '../../service';
import STable from './Component/SelfTable';

export default function CategoryIndex(props) {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 20,
    keyword: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [error, setError] = useState({});

  /************************
   * SECTION Initialize the entire page
   * set The title, available buttons,
   * and call this Page Index
   *
   * RETURN @Object
   ************************/
  var initializeData = async () => {
    document.title = 'Stationery Requests';
    var query = `?page=${meta.page}&per_page=${meta.per_page}&keyword=${meta.keyword}`;
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Stationery Requests',
        // newLink: '/dashboard/post/create',
        // deleteLink: hl => destroyDispatch(hl),
        // activateLink: hl => activateDispatch(hl),
        // deactivateLink: hl => deactivateDispatch(hl),
        hasMenu: false,
      })
    );

    await props
      .dispatch(getStationeryRequests(query))
      .then(data => {
        setData(data.data);
        setTotalItem(data.meta.total_item);
        setTotalPage(data.meta.total_page);
      })
      .catch(error => console.log(error));
  };

  /************************
   * SECTION Call the Page initialization upon page load
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function callInitiator() {
      await initializeData();
    }
    callInitiator();
  }, [meta]);

  // The Main View, see ./Component/Index/SelfTable.js
  return (
    <STable data={data} meta={meta} setMeta={setMeta} totalItem={totalItem} totalPage={totalPage} />
  );
}
