import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core';

import { ChevronLeft, ChevronRight, Sort, Search } from '@material-ui/icons';

import Row from './SelfRow';
import _ from 'underscore';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function SelfTable({
  data,
  dispatchSetFeatured,
  meta,
  setMeta,
  totalPage,
  totalItem,
}) {
  const classes = useStyles();

  const [pageMenu, setPageMenu] = useState(null);
  const [filterMenu, setFilterMenu] = useState(null);

  return (
    <div>
      <Paper>
        <Box p={3}>
          {/* <Input
            id="standard-adornment-password"
            className="float-right"
            defaultValue={meta.keyword}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            onChange={event => {
              setMeta({ ...meta, keyword: event.target.value });
            }}
          /> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '100px', cursor: 'pointer' }}>ID</TableCell>
                <TableCell align="center">Applicant</TableCell>
                <TableCell align="center">Job</TableCell>
                <TableCell align="center">Title</TableCell>
                <TableCell align="center">Department</TableCell>
                <TableCell align="center">Division</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((e, i) => {
                return <Row data={e} key={i} dispatchSetFeatured={dispatchSetFeatured} />;
              })}
            </TableBody>
          </Table>
        </Box>
        <Box p={2} className="align-content-end">
          Rows per Page :
          <Button className="mx-3" size="small" onClick={e => setPageMenu(e.currentTarget)}>
            {meta.per_page}
          </Button>
          <Menu
            anchorEl={pageMenu}
            keepMounted
            open={Boolean(pageMenu)}
            onClose={() => setPageMenu(null)}
          >
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 10 });
              }}
            >
              10
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 20 });
              }}
            >
              20
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 50 });
              }}
            >
              50
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPageMenu(null);
                setMeta({ ...meta, page: 1, per_page: 100 });
              }}
            >
              100
            </MenuItem>
          </Menu>
          <span className="mx-3">
            {' '}
            Page {meta.page} of {totalPage}{' '}
          </span>
          <IconButton
            className="mx-3"
            size="small"
            disabled={meta.page < 2}
            onClick={() =>
              setMeta({
                ...meta,
                page: meta.page - 1,
              })
            }
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            className="mx-3"
            size="small"
            disabled={meta.page == totalPage}
            onClick={() =>
              setMeta({
                ...meta,
                page: meta.page + 1,
              })
            }
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Paper>
    </div>
  );
}
