import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { Helmet } from 'react-helmet';
import { getFeatured, getconfig, getBanner } from '../service';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import MeetingRoom from '../../../../../assets/images/MeetingRoomRequest.png'
import HRIS from '../../../../../assets/images/HRIS.png'
import JobVacancy from '../../../../../assets/images/JobVacancy.png'
import MarcommStore from '../../../../../assets/images/MarcommStore.png'
import Organizations from '../../../../../assets/images/Organizations.png'
import SharedDocuments from '../../../../../assets/images/SharedDocuments.png'
import StationaryRequest from '../../../../../assets/images/StationeryRequest.png'

export default function Landing(props) {
  const [featured, setFeatured] = useState(null);
  const [config, setConfig] = useState(null);
  const [featuredExpand, setFeaturedExpand] = useState(false);
  const [loading, setLoading] = useState(true);
  const [featureLoading, setFeaturedLoading] = useState(false);
  const [banner, setBanner] = useState(null);
  const filteredData = useSelector((state) => state.search.files)
  const filteredKeyword = useSelector((state) => state.search.keyword)

  async function initializeData() {
    await setFeaturedLoading(true);
    await props
      .dispatch(getFeatured())
      .then(data => setFeatured(data))
      .catch(error => console.log(error));
    await props
      .dispatch(getconfig())
      .then(data => setConfig({ ...data, blocks: JSON.parse(data.menuBlocks) }))
      .catch(error => console.log(error));

    await props
      .dispatch(getBanner())
      .then(data => setBanner(data))
      .catch(error => console.log(error));

    await setFeaturedLoading(false);
  }
  useEffect(() => {
    async function deploy() {
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, []);
  

  // This is the important part!

  function collapseSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function() {
        element.style.height = 300 + 'px';
      });
    });

    // mark the section as "currently collapsed"
    element.setAttribute('data-collapsed', 'true');
  }

  function expandSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 100 + 'px';

    // mark the section as "currently not collapsed"
    element.setAttribute('data-collapsed', 'false');
  }
  function expand() {
    if (!featuredExpand) {
      expandSection(document.getElementById('splash-message'));
      setFeaturedExpand(true);
    } else {
      setFeaturedExpand(false);
      collapseSection(document.getElementById('splash-message'));
    }
  }
  if (loading) {
    return null;
  }
  return (
    <div className="homepage">
      {config ? (
        <Helmet>
          <title>{config.siteTitle || 'NECI - NEC Indonesia'}</title>
          <link rel="icon" type="image/png" href={config.favicon_url} sizes="16x16" />
        </Helmet>
      ) : null}
      <Carousel
        showArrows={true}
        showIndicators={true}
        showThumbs={false}
        swipeable={true}
        autoPlay={true}
        interval={3000}
        infiniteLoop
        showStatus={false}
        onClickItem={a => window.open(banner[a].link, '_blank')}
      >
        {banner
          ? banner.map(item => (
              <a href={item.link} target="_blank">
                <img className="splash-image" src={item.thumbnail_url} />
              </a>
            ))
          : null}
      </Carousel>

      {
        <div
          id="splash-message"
          className={`splash-message ${featuredExpand ? 'expanded' : ''}`}
          data-collapsed={featuredExpand ? 'true' : 'false'}
        >
          {featured ? (
            <>
              <div className="title">
                <h1>{featured.name}</h1>
                <img
                  src={featured.thumbnail_url}
                  className={`image-featured ${featuredExpand ? null : 'hidden'} `}
                />
              </div>
              <div className="content" dangerouslySetInnerHTML={{ __html: featured.content }} />
              <Link
                id="toggle-button"
                onClick={e => {
                  e.preventDefault();
                  expand();
                }}
                className={`read-more hideme`}
              >
                <h3 className="text">{featuredExpand ? 'Hide Message' : 'Read Full Message'}</h3>
              </Link>
            </>
          ) : null}
        </div>
      }

      {config ? (
        <div className="blocks">
          <a href={config.blocks.menu1.link} className="block-item bgblue">
            <h2 className="title">{config.blocks.menu1.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="menuImage"
              src={MeetingRoom}
              alt=""
            />
          </a>
          <a href={config.blocks.menu2.link} className="block-item span-2 bg-dark">
            <h2 className="title">{config.blocks.menu2.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="largeMenuImage"
              src={SharedDocuments}
              alt=""
            />
          </a>

          <a href={config.blocks.menu3.link} className="block-item bggray">
            <h2 className="title">{config.blocks.menu3.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="menuImage"
              src={StationaryRequest}
              alt=""
            />
          </a>

          <a href={config.blocks.menu4.link} className="block-item bgorange">
            <h2 className="title">{config.blocks.menu4.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="menuImage"
              src={HRIS}
              alt=""
            />
          </a>
          <a href={config.blocks.menu5.link} className="block-item bgred">
            <h2 className="title">{config.blocks.menu5.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="menuImage"
              src={MarcommStore}
              alt=""
            />
          </a>

          <a href={config.blocks.menu6.link} className="block-item span-2 bglblue">
            <h2 className="title">{config.blocks.menu6.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="largeMenuImage"
              src={Organizations}
              alt=""
            />
          </a>

          <a href={config.blocks.menu7.link} className="block-item bgpink">
            <h2 className="title">{config.blocks.menu7.title}</h2>
            <img
              className="icon"
              src={toAbsoluteUrl('/media/nec/icons/arrow_right.png')}
              alt="arrow down"
            />
            <img 
              className="menuImage"
              src={JobVacancy}
              alt=""
            />
          </a>
        </div>
      ) : null}
    </div>
  );
}
