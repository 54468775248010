import React, { useState, useEffect } from 'react';
import * as auth from '../../../../store/ducks/auth.duck';

import { toAbsoluteUrl } from '../../../../../_metronic';

import { Box, Paper, Divider, Button, Grid, TextField } from '@material-ui/core';
// import TextField from "../../../shared/input/text";
import Alert from '../../../shared/Alert';

import { config, configUpdate } from '../service';

import _ from 'underscore';

export default function Profile(props) {
  const [data, setData] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [error, setError] = useState({});
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [perms, setPerms] = useState({});

  async function initializePage() {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-homepage')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-homepage')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-homepage')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-homepage')),
    };
    // Register the page metadata
    document.title = 'Homepage Configuration';
    await setLoading(true);
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Homepage Configuration',
        hasMenu: false,
      })
    );

    // Fetch Profile data
    await props
      .dispatch(config())
      .then(data => {
        setData(data.data);
        setThumbnail(data.data.favicon_url);
        setBlocks(JSON.parse(data.data.menuBlocks));
      })
      .catch(error => setError(error));
    await setPerms(permData);
    await setLoading(false);
  }

  async function updateProfile(e) {
    e.preventDefault();
    var data = new FormData(e.target);
    data.append('blocks', JSON.stringify(blocks));
    await setSubmitLoading(true);
    await props
      .dispatch(configUpdate(data))
      .then(data => {
        setNotification({ type: 'success', ...data });
      })
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await setSubmitLoading(false);
  }

  useEffect(() => {
    async function deployInit() {
      await setLoading(true);
      await initializePage();
      await setLoading(false);
    }
    deployInit();
  }, []);

  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
  }, [notification]);

  // Display when loading
  if (loading) {
    return <p>Please Wait</p>;
  }

  // Final Display
  if (perms.read) {
    return (
      <>
        <form autoComplete="off" onSubmit={e => updateProfile(e)}>
          <fieldset disabled={submitLoading || !perms.update}>
            <Paper elevation={0}>
              <Box p={5}>
                <h4>Homepage Management</h4>
                <Divider />
                <TextField name="site_title" value={data.siteTitle} type="hidden" />
                <div className="blocks">
                  <div to="/example" className="block-item">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu1.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu1: {
                            title: e.target.value,
                            link: blocks.menu1.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu1.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu1: {
                            link: e.target.value,
                            title: blocks.menu1.title,
                          },
                        })
                      }
                    />
                  </div>
                  <div to="/example" className="block-item span-2">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu2.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu2: {
                            title: e.target.value,
                            link: blocks.menu2.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu2.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu2: {
                            link: e.target.value,
                            title: blocks.menu2.title,
                          },
                        })
                      }
                    />
                  </div>

                  <div to="/example" className="block-item">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu3.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu3: {
                            title: e.target.value,
                            link: blocks.menu3.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu3.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu3: {
                            link: e.target.value,
                            title: blocks.menu3.title,
                          },
                        })
                      }
                    />
                  </div>
                  <div to="/example" className="block-item">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu4.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu4: {
                            title: e.target.value,
                            link: blocks.menu4.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu4.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu4: {
                            link: e.target.value,
                            title: blocks.menu4.title,
                          },
                        })
                      }
                    />
                  </div>
                  <div to="/example" className="block-item">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu5.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu5: {
                            title: e.target.value,
                            link: blocks.menu5.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu5.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu5: {
                            link: e.target.value,
                            title: blocks.menu5.title,
                          },
                        })
                      }
                    />
                  </div>

                  <div to="/example" className="block-item span-2">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu6.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu6: {
                            title: e.target.value,
                            link: blocks.menu6.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu6.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu6: {
                            link: e.target.value,
                            title: blocks.menu6.title,
                          },
                        })
                      }
                    />
                  </div>

                  <div to="/example" className="block-item">
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Title"
                      value={blocks.menu7.title}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu7: {
                            title: e.target.value,
                            link: blocks.menu7.link,
                          },
                        })
                      }
                    />
                    <TextField
                      className="mb-3"
                      required
                      variant="outlined"
                      fullWidth
                      name="title1"
                      label="Link"
                      value={blocks.menu7.link}
                      onChange={e =>
                        setBlocks({
                          ...blocks,
                          menu7: {
                            link: e.target.value,
                            title: blocks.menu7.title,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                {perms.update ? (
                  <Button
                    type="submit"
                    className="ml-auto d-block my-3"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Submit
                  </Button>
                ) : null}
              </Box>
            </Paper>
            <br />
          </fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
