import * as utils from '../../_metronic/utils/utils';

const localStorageLastLocationKey = 'demo1-lastLocation';
const localStorageRedirect = 'redirect';

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== '/' &&
    lastLocation.pathname.indexOf('auth') === -1 &&
    lastLocation.pathname !== '/logout'
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  let _useLocations = localStorateLocations ? JSON.parse(localStorateLocations) : [];

  if (acceptLocation(lastLocation)) {
    _useLocations = lastLocation.pathname;
    utils.setStorage(localStorageLastLocationKey, JSON.stringify(_useLocations), 120);
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return '/dashboard';
  }

  const _userLocations = JSON.parse(localStorateLocations);
  const result = _userLocations ? _userLocations : '/';
  return result;
}

export function saveUrlRedirect(url) {
  if (acceptLocation(url)) {
    utils.setStorage(localStorageRedirect, url.pathname, 120);
  }
}

export function getUrlRedirect() {
  return utils.getStorage(localStorageRedirect);
}

export function forgotUrlRedirect() {
  utils.removeStorage(localStorageRedirect);
}
