import HTTP from 'axios';
require('dotenv').config();

export const LOGIN_URL = 'auth/login';
export const LOGIN_EMPLOYEE_URL = 'auth/login_employee';
export const REGISTER_URL = 'register';
export const REQUEST_PASSWORD_URL = 'forgot-password';

export const ME_URL = 'me';
export const EMPLOYEE_URL = 'me/employee';

const API_URL = process.env.REACT_APP_API_URL;

export function login(userName, password) {
  return HTTP.post(`${API_URL}${LOGIN_URL}`, { userName, password });
}

export function login_employee(email, password) {
  return HTTP.post(`${API_URL}${LOGIN_EMPLOYEE_URL}`, { email, password });
}

export function register(email, fullname, username, password) {
  return HTTP.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return HTTP.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return HTTP.get(ME_URL);
}

export function getEmployeeByToken() {
  // Authorization head should be fulfilled in interceptor.
  return HTTP.get(EMPLOYEE_URL);
}
