import React, { useState, useEffect } from 'react';

import { Paper, Button, Box, Divider, TextField, Grid, Switch } from '@material-ui/core';
import 'grapesjs/dist/css/grapes.min.css';
import GrapesJS from 'grapesjs';
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import gjsBasicBlocks from 'grapesjs-blocks-basic';

export default function RichText({ pageData, setPageData, files }) {
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    const editorCanvas = GrapesJS.init({
      container: `#editor`,
      fromElement: true,
      plugins: [gjsBasicBlocks, gjsPresetWebpage],
      storageManager: { type: 'onChange' },
      styleManager: {},
      assetManager: {
        assets: files,
        upload: `${process.env.REACT_APP_API_URL}utility/uploadFiles`,
        headers: {
          'Access-Control-Allow-Credentials': true,
          Authorization: window.localStorage.getItem('auth_token'),
        },
        multiUpload: false,
        autoAdd: 1,
      },
    });
    editorCanvas.setStyle(
      '#wrapper{overflow: auto !important;overflow-x: auto !important;} #wrapper::-webkit-scrollbar-thumb {background: gray !important; }'
    );

    var pfx = editorCanvas.getConfig().stylePrefix;
    var modal = editorCanvas.Modal;
    var cmdm = editorCanvas.Commands;
    var codeViewer = editorCanvas.CodeManager.getViewer('CodeMirror').clone();
    var pnm = editorCanvas.Panels;
    var container = document.createElement('div');
    var btnEdit = document.createElement('button');

    codeViewer.set({
      codeName: 'htmlmixed',
      readOnly: 0,
      theme: 'hopscotch',
      autoBeautify: true,
      autoCloseTags: true,
      autoCloseBrackets: true,
      lineWrapping: true,
      styleActiveLine: true,
      smartIndent: true,
      indentWithTabs: true,
    });

    btnEdit.innerHTML = 'Edit';
    btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import';
    btnEdit.onclick = function(e) {
      e.preventDefault();
      var code = codeViewer.editor.getValue();
      editorCanvas.DomComponents.getWrapper().set('content', '');
      editorCanvas.setComponents(code.trim());
      modal.close();
    };

    cmdm.add('html-edit', {
      run: function(editor, sender) {
        sender && sender.set('active', 0);
        var viewer = codeViewer.editor;
        modal.setTitle('Edit code');
        if (!viewer) {
          var txtarea = document.createElement('textarea');
          container.appendChild(txtarea);
          container.appendChild(btnEdit);
          codeViewer.init(txtarea);
          viewer = codeViewer.editor;
        }
        var InnerHtml = editor.getHtml();
        var Css = editor.getCss();
        modal.setContent('');
        modal.setContent(container);
        codeViewer.setContent(InnerHtml + '<style>' + Css + '</style>');
        modal.open();
        viewer.refresh();
      },
    });

    pnm.addButton('options', [
      {
        id: 'edit',
        className: 'fa fa-edit',
        command: 'html-edit',
        attributes: {
          title: 'Edit',
        },
      },
    ]);
    setEditor(editorCanvas);
  }, []);
  return (
    <>
      <div id="editor" onkeydown="return event.key != 'Enter';" />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="d-block ml-auto mt-3"
        onClick={() => {
          setPageData({
            ...pageData,
            content: editor.getHtml(),
            css: `${editor.getCss()}`,
          });
        }}
      >
        Submit
      </Button>
    </>
  );
}
