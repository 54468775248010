/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { setupAxios } from './_metronic';
import store, { persistor } from './app/store/store';
import App from './App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './app/master.css';
import 'react-sortable-tree/style.css';

import * as auth from './app/store/ducks/auth.duck';
import { login, getEmployeeByToken, getUserByToken } from './app/crud/auth.crud';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

// store.dispatch(auth.actions.login()).then(e => console.log('datatest - - ', e));

ReactDOM.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
  document.getElementById('root')
);
