/**
 * PART: Imports
 *
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as auth from '../../../../../store/ducks/auth.duck';
import _ from 'underscore';

import Alert from '../../../../shared/Alert';
import STable from './Component/SelfTable';
import { Modal, Button, Grid } from '@material-ui/core';
import { index, deactivate, activate, destroy, insertBulk, getExport } from '../service';

import { Paper, Box } from '@material-ui/core';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 700,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function CategoryIndex(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    per_page: 20,
    keyword: '',
    type: '',
  });
  const [totalPage, setTotalPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [error, setError] = useState({});
  const [selected, setSelected] = useState([]);
  const [notification, setNotification] = useState({});
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [perms, setPerms] = useState({});
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function deactivateDispatch(id) {
    await props
      .dispatch(deactivate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  async function activateDispatch(id) {
    await props
      .dispatch(activate(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  async function destroyDispatch(id) {
    await props
      .dispatch(destroy(id))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
  }

  var initializeData = async () => {
    var permData = await {
      create: Boolean(_.find(props.data.perms, e => e == 'c-employee')),
      read: Boolean(_.find(props.data.perms, e => e == 'r-employee')),
      update: Boolean(_.find(props.data.perms, e => e == 'u-employee')),
      delete: Boolean(_.find(props.data.perms, e => e == 'd-employee')),
    };
    document.title = 'Employee';
    var query = `?page=${meta.page}&per_page=${meta.per_page}&type=${meta.type}&keyword=${meta.keyword}`;
    await props.dispatch(
      auth.actions.registerPage({
        title: 'Employee',
        newLink: permData.create ? '/dashboard/employee/create' : false,
        deleteLink: permData.delete ? hl => destroyDispatch(hl) : null,
        activateLink: permData.update ? hl => activateDispatch(hl) : null,
        deactivateLink: permData.update ? hl => deactivateDispatch(hl) : null,
        export: permData.update || permData.read ? handleOpen : false,
        hasMenu: permData.update || permData.delete ? true : false,
      })
    );
    await props
      .dispatch(index(query))
      .then(data => {
        setData(data.data);
        setTotalPage(data.meta.total_page);
        setTotalItem(data.meta.total_item);
      })
      .catch(error => console.log(error));

    await setPerms(permData);
    await setLoading(false);
  };

  var submit = async e => {
    e.preventDefault();
    var form = new FormData(e.target);
    await props
      .dispatch(insertBulk(form))
      .then(data => setNotification({ type: 'success', ...data }))
      .catch(error => setNotification({ type: 'error', ...error.response.data }));
    await handleClose();
  };

  async function startExport() {
    await props.dispatch(getExport()).then(succ => {
      const downloadUrl = window.URL.createObjectURL(new Blob([succ.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'file.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  useEffect(() => {
    async function callInitiator() {
      await initializeData();
    }
    callInitiator();
  }, [meta, notification]);

  /************************
   * SECTION Call the SweetAlert everytime the notification appears
   * RETURN @Object
   ************************/
  useEffect(() => {
    if (notification.name) {
      Alert(notification, setNotification);
    }
    setSelected([]);
  }, [notification]);

  /************************
   * SECTION HitList
   * RETURN @Object
   ************************/
  useEffect(() => {
    props.dispatch(auth.actions.hitCouriers(selected.join()));
  }, [selected]);

  if (loading) {
    return 'Please Wait.';
  }

  if (perms.read) {
    return (
      <>
        <STable
          data={data}
          meta={meta}
          setMeta={setMeta}
          totalItem={totalItem}
          totalPage={totalPage}
          selected={selected}
          setSelected={setSelected}
          handleOpen={handleOpen}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <p id="simple-modal-description">
              <form onSubmit={submit}>
                <Grid
                  container
                  justify="space-between"
                  className="pb-3 border-bottom border-dark text-center"
                >
                  <Grid item xs={12}>
                    <h2 id="simple-modal-title" className="mt-3 text-left">
                      Export
                    </h2>
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={startExport} variant="outlined" className="d-block-inline">
                      Export all data
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      href={`${process.env.REACT_APP_ORIGIN}file/sample_for_import.xlsx`}
                      target="_blank"
                      variant="outlined"
                      className="d-block-inline"
                    >
                      Download sample <br /> for inserting data
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      href={`${process.env.REACT_APP_ORIGIN}file/sample_for_update.xlsx`}
                      target="_blank"
                      variant="outlined"
                      className="d-block-inline"
                    >
                      Download sample <br /> for update employee point
                    </Button>
                  </Grid>
                </Grid>

                <Grid container justify="space-evenly">
                  <Grid item xs={12}>
                    <h2 id="simple-modal-title" className="mt-3">
                      Insert New Data
                    </h2>
                  </Grid>
                  <Grid item xs={9}>
                    <input type="file" className="my-3" required name="xlsx" />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="d-block-inline mt-2"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>

                <Grid container justify="space-evenly">
                  <Grid item xs={12}>
                    <h2 id="simple-modal-title" className="mt-3">
                      Update Employee Point
                    </h2>
                  </Grid>
                  <Grid item xs={9}>
                    <input type="file" className="my-3" required name="xlsx" />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="d-block-inline mt-2"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </p>
          </div>
        </Modal>
      </>
    );
  } else {
    return (
      <Paper>
        <Box p={3} className="text-danger">
          Access is denied. You may not have the appropriate permissions to access this page.{' '}
        </Box>
      </Paper>
    );
  }
}
