/**
 * Import Lib
 */

import axios from 'axios';

import * as auth from '../store/ducks/auth.duck';
import store from '../store/store';

const API_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API_URL;

// axios.defaults.headers.common.Accept = "application/json";
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch(auth.actions.logout());
    }
    return Promise.reject(error);
  }
);

export default axios;
