import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import ReactHtmlParser from 'react-html-parser';

import { Helmet } from 'react-helmet';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    setData(props.data);
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.data]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Helmet>
        <style type="text/css">{data.content.css}</style>
      </Helmet>
      <div className="content_detail">
        <div className="header">{data.name}</div>
        <div className="wrapper_content" dangerouslySetInnerHTML={{ __html: data.content.content }} />
      </div>
    </>
  );
}
