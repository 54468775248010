import React, { useState } from 'react';
import { Box, Button, Grid, CircularProgress } from '@material-ui/core';
import GalleryItem from './galleryItem';
export default function Image({ selfContent, pageData, setPageData, submit }) {
  const [total, setTotal] = useState(4);
  function repeater() {
    var all = [];
    for (let index = 0; index < total; index++) {
      all.push(<GalleryItem />);
    }
    return all;
  }
  return (
    <Grid container spacing={3} justify="space-around">
      {repeater()}
      <Grid item xs={3} className="addGallery">
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setTotal(total + 1);
          }}
        >
          Add
        </a>
      </Grid>

      {submit ? (
        <Grid item container xs={12} justify="center">
          <Box m={2}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box py={2}>
            <Button
              disabled={submit}
              type="submit"
              variant="contained"
              color="primary"
              id="submitButton"
              className="ml-auto d-block"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
