const initialState = {
    keyword: '',
    files: "",
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SETKEYWORD":
            return {...state, keyword: action.payload}
        case "SETFILES" :
            return {...state, files: action.payload}
        default:
            return state
    }
}

export default searchReducer