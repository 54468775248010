import React, { useState, useEffect } from 'react';
import { getEmployeeWithCo } from '../service';
import { Link } from 'react-router-dom';
import _ from 'underscore';

export default function Page(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    await props
      .dispatch(getEmployeeWithCo(props.match.params.id))
      .then(data => setData(data))
      .catch(error => console.log(error));
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.match.params]);

  if (loading) {
    return null;
  }

  return (
    <div className="employee_detail">
      <div className="self_data">
        <div className="left">
          <div className="employee_profile">
            <img src={data.thumbnail} />
          </div>
          <div className={`employee_display_name coworker`}>{data.display_name}</div>
          <p className="employee_name">{data.fullname}</p>
          <p className="attribute">{data.title ? data.title.title_name : ''}</p>
          <p className="attribute">{data.division ? data.division.name : ''}</p>
          <p className="attribute">{data.departement ? data.departement.name : ''}</p>
        </div>
        <div className="right">
          <div className="right_title">Email</div>
          <div className="right_value">{data.email}</div>
          <div className="right_title">Phone Number</div>
          <div className="right_value">{data.phone_number}</div>
          <div className="right_title">Office Address</div>
          <div className="right_value">{data.office_address}</div>
          {/* <div className="right_title">Office Phone Number</div> */}
          {/* <div className="right_value">{data.office_phone}</div> */}
          <div className="right_title">LinkedIn</div>
          <div className="right_value">{data.linkedln}</div>
          <div className="right_title">Facebook</div>
          <div className="right_value">{data.facebook}</div>
          <div className="right_title">Instagram</div>
          <div className="right_value">{data.instagram}</div>
        </div>
      </div>
      <div className="sections">
        <div className={`section_title upper`}>
          {' '}
          <span>
            {data.fullname} <br /> IS REPORTING TO
          </span>
        </div>
        <div className="employee_blocks">
          {data.superOrdinateDetails
            ? data.superOrdinateDetails.map(emp =>
                emp ? (
                  <Link to={`/employee/${emp.id}`} className="employee_item">
                    <div className="employee_profile">
                      <img src={emp.thumbnail_url} />
                    </div>
                    <div className={`employee_name coworker`}>{emp.fullname}</div>
                    <p className="attribute bold">{emp.title ? emp.title.title_name : ''}</p>
                    <p className="attribute">{emp.division ? emp.division.name : ''}</p>
                    <p className="attribute gray">{emp.departement ? emp.departement.name : ''}</p>
                  </Link>
                ) : null
              )
            : null}
        </div>
        <div className={`section_title coworker`}>
          {' '}
          <span>
            REPORTING TO <br /> {data.fullname}
          </span>
        </div>
        <div className="employee_blocks">
          {data.subOrdinateDetails
            ? data.subOrdinateDetails.map(emp =>
                emp ? (
                  <Link to={`/employee/${emp.id}`} className="employee_item">
                    <div className="employee_profile">
                      <img src={emp.thumbnail_url} />
                    </div>
                    <div className={`employee_name coworker`}>{emp.fullname}</div>
                    <p className="attribute bold">{emp.title ? emp.title.title_name : ''}</p>
                    <p className="attribute">{emp.division ? emp.division.name : ''}</p>
                    <p className="attribute gray">{emp.departement ? emp.departement.name : ''}</p>
                  </Link>
                ) : null
              )
            : null}
        </div>
      </div>
    </div>
  );
}
