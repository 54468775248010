import HTTP from '../../crud/HTTP';

export function forgotPassword(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/auth/reset`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function forgotPasswordEmployee(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/auth/reset/employee`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function resetCheck(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/auth/change/${data}`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function reset(token, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/auth/change/${token}`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function resetCheckEmployee(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.get(`/auth/change/${data}/employee`)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function resetEmployee(token, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/auth/change/${token}/employee`, data)
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function verifyOtp(data, otp) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/auth/verification_login`, {
        data, otp
      })
        .then(data => {
          console.log(data, 'isi datanya di action')
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}

export function resendOtpAction(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      HTTP.post(`/auth/resend_otp`, {
        data
      })
        .then(data => {
          return resolve(data.data);
        })
        .catch(data => {
          return reject(data);
        });
    });
  };
}