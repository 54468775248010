import React, { useState, useEffect } from 'react';
import { getPage, checkPage } from '../service';
import _ from 'underscore';
import Gallery from './gallery';
import Content from './content';
import Job from './job';
import ISOForm from './iso';

export default function Page(props) {
  // console.log('disini pasti ya?')
  const [data, setData] = useState(null);
  const [isForm, setIsForm] = useState(false);
  const [loading, setLoading] = useState(true);

  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  async function initializeData() {
    await props
      .dispatch(getPage(props.match.params.slug))
      .then(data => {
        setData(data);
      })
      .catch(error => console.log(error));
    await props
      .dispatch(checkPage({ type: 'isoform', url: props.match.params.slug }))
      .then(() => setIsForm(true))
      .catch(() => setIsForm(false));
  }
  /************************
   * SECTION Init
   * RETURN @Object
   ************************/
  useEffect(() => {
    async function deploy() {
      await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      await setLoading(true);
      await initializeData();
      await setLoading(false);
    }
    deploy();
  }, [props.match.params]);

  if (loading) {
    return null;
  }

  /************************
   * SECTION Find
   * RETURN @Object
   ************************/
  function type(type) {
    switch (type) {
      case 'gallery':
        return <Gallery data={data} />;
      case 'content':
        return <Content data={data} />;
      case 'job':
        return <Job data={data} />;
      case 'form':
        return <ISOForm />;

      default:
        return null;
    }
  }
  return <>{type(data ? data.type : 'form') || <div className="no_post">No Page Found</div>}</>;
}
